export default {
    iron: { 'outputName': '', 'abbrName': 'Iron', 'percent': '', 'amount': '', 'fullName': 'Iron', 'uom': 'mg' },
    potassium: { 'outputName': '', 'abbrName': 'Potass.', 'percent': '', 'amount': '', 'fullName': 'Potassium', 'uom': 'mg' },
    calcium: { 'outputName': '', 'abbrName': 'Calcium', 'percent': '', 'amount': '', 'fullName': 'Calcium', 'uom': 'mg' },
    vitaminA: { 'outputName': '', 'abbrName': 'Vit. A', 'percent': '', 'amount': '', 'fullName': 'Vitamin A', 'uom': 'mcg' },
    vitaminB2: { 'outputName': '', 'abbrName': 'Vit. B2', 'percent': '', 'amount': '', 'fullName': 'Vitamin B2', 'uom': 'mg' },
    vitaminB3: { 'outputName': '', 'abbrName': 'Vit. B3', 'percent': '', 'amount': '', 'fullName': 'Vitamin B3', 'uom': 'mg' },
    vitaminB6: { 'outputName': '', 'abbrName': 'Vit. B6', 'percent': '', 'amount': '', 'fullName': 'Vitamin B6', 'uom': 'mg' },
    vitaminB12: { 'outputName': '', 'abbrName': 'Vit. B12', 'percent': '', 'amount': '', 'fullName': 'Vitamin B12', 'uom': 'mcg' },
    biotin: { 'outputName': '', 'abbrName': 'Biotin', 'percent': '', 'amount': '', 'fullName': 'Biotin', 'uom': 'mcg' },
    vitaminC: { 'outputName': '', 'abbrName': 'Vit. C', 'percent': '', 'amount': '', 'fullName': 'Vitamin C', 'uom': 'mg' },
    vitaminDMcg: { 'outputName': '', 'abbrName': 'Vit. D', 'percent': '', 'amount': '', 'fullName': 'Vitamin D', 'uom': 'mcg' },
    vitaminEMg: { 'outputName': '', 'abbrName': 'Vit. E', 'percent': '', 'amount': '', 'fullName': 'Vitamin E', 'uom': 'mcg' },
    magnesium: { 'outputName': '', 'abbrName': 'Magnesium', 'percent': '', 'amount': '', 'fullName': 'Magnesium', 'uom': 'mg' },
    zinc: { 'outputName': '', 'abbrName': 'Zinc', 'percent': '', 'amount': '', 'fullName': 'Zinc', 'uom': 'mg' },
    selenium: { 'outputName': '', 'abbrName': 'Selenium', 'percent': '', 'amount': '', 'fullName': 'Selenium', 'uom': 'mcg' },
    vitaminK: { 'outputName': '', 'abbrName': 'Vitamin K', 'percent': '', 'amount': '', 'fullName': 'Vitamin K', 'uom': 'mcg' },
    thiamin: { 'outputName': '', 'abbrName': 'Thiamin', 'percent': '', 'amount': '', 'fullName': 'Thiamin', 'uom': 'mg' },
    folate: { 'outputName': '', 'abbrName': 'Folate', 'percent': '', 'amount': '', 'fullName': 'Folate', 'uom': 'mcg' },
    pantothenicAcid: { 'outputName': '', 'abbrName': 'Pantothenic Acid', 'percent': '', 'amount': '', 'fullName': 'Pantothenic Acid', 'uom': 'mg' },
    phosphorus: { 'outputName': '', 'abbrName': 'Phosphorus', 'percent': '', 'amount': '', 'fullName': 'Phosphorus', 'uom': 'mg' },
  }