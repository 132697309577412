import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

// Custom SVG path for the Dagger Mark icon
function DaggerIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M12 2C12.55 2 13 2.45 13 3V6H16C16.55 6 17 6.45 17 7V8C17 8.55 16.55 9 16 9H13V20C13 20.55 12.55 21 12 21H11C10.45 21 10 20.55 10 20V9H7C6.45 9 6 8.55 6 8V7C6 6.45 6.45 6 7 6H10V3C10 2.45 10.45 2 11 2H12Z" />
    </SvgIcon>
  );
}

export default DaggerIcon;