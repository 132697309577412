import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100px;
`;

const StyledSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  font-size: 14px;
  line-height: 12px;
  color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.87)')};
  background-color: ${({ disabled }) => (disabled ? '#f5f5f5' : '#fff')};
  border: 1px solid ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.23)')};
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: border-color 0.3s, background-color 0.3s;

  &:hover {
    border-color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.87)')};
  }

  &.open {
    border-color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.38)' : '#1976d2')};
  }
`;

const Arrow = styled.span`
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 8px;
  vertical-align: middle;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: ${({ disabled }) => (disabled ? '5px solid rgba(0, 0, 0, 0.38)' : '5px solid rgba(0, 0, 0, 0.87)')};
  transition: transform 0s;

  &.open {
    transform: rotate(180deg);
  }
`;

const OptionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 2000;

  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const Option = styled.div`
  padding: 10px 14px;
  font-size: 14px;
  line-height: 1.5;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? 'inherit' : 'rgba(0, 0, 0, 0.04)')};
  }

  &.selected {
    background-color: rgba(25, 118, 210, 0.1);
  }
`;

const NativeSelect = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: -1;
`;

const Select = ({ id, size = 'small', value, onChange, defaultValue, placeholder = 'Select an option', disabled = false, children = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
//   const [selectedValue, setSelectedValue] = useState(value || defaultValue || '');
  const selectWrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectWrapperRef.current && !selectWrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelectClick = () => {
    if (!disabled) {
      setIsOpen((prevIsOpen) => !prevIsOpen);
    }
  };

  const handleOptionClick = ({ id, value }) => {
    // setSelectedValue(value);
    setIsOpen(false);
    if (onChange) {
      onChange({ target: { id, value } });
    }
  };

  const handleNativeChange = (event) => {
    console.log('handleNativeChange: ', event)
    // setSelectedValue(event.target.value);
    setIsOpen(false);
    if (onChange) {
      onChange(event);
    }
  };

  if(!Array.isArray(children)) {
    children = [children]
  }
  const selectedOption = children.find((option) => option.props.value === value);

  return (
    <SelectWrapper ref={selectWrapperRef}>
      <StyledSelect onClick={handleSelectClick} className={isOpen ? 'open' : ''} disabled={disabled}>
        {selectedOption ? selectedOption.props.children : placeholder}
        <Arrow className={isOpen ? 'open' : ''} disabled={disabled}/>
      </StyledSelect>

      <OptionsContainer isOpen={isOpen}>
        {children.map((option) => (
          <Option
            key={option.props.value}
            className={option.props.value === value ? 'selected' : ''}
            onClick={() => handleOptionClick({ id, value: option.props.value })}
            disabled={disabled}
          >
            {option.props.children}
          </Option>
        ))}
      </OptionsContainer>

      <NativeSelect
        size={size}
        id={id}
        disabled={disabled}
        value={value}
        onChange={handleNativeChange}
      >
        {children}
      </NativeSelect>
    </SelectWrapper>
  );
};

export default Select;