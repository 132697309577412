import { useEffect } from 'react'
import Page from '../layout/Page'
import { useRole } from './role'
import { getHostUrl } from './auth'

export default function LogoutPage (props={}) {
  const { cleanup } = useRole()

  useEffect(() => {
    cleanup()
    setTimeout(() => {
      window.location = getHostUrl()
    }, 100);
  })

  return (
    <Page { ...props} onLoad>
        Goodbye!
    </Page>
  )

}