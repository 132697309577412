import React from 'react';

const AmericanFlag = () => {
  return (
    <svg width="23" height="12" viewBox="0 0 46 24" xmlns="http://www.w3.org/2000/svg">
      <rect width="46" height="24" fill="#B22234" />
      <rect y="2" width="46" height="3" fill="#FFFFFF" />
      <rect y="7" width="46" height="3" fill="#FFFFFF" />
      <rect y="13" width="46" height="3" fill="#FFFFFF" />
      <rect y="19" width="46" height="3" fill="#FFFFFF" />
      
      <rect width="21" height="14" fill="#3C3B6E" />
      <g fill="#FFFFFF">
        <g id="row1-stars" transform="translate(2, 2)">
          <polygon points="1,1 0.6,0.6 0,0.6 0.6,0 1,-0.6 1.4,0 1,1" />
          <polygon points="5,1 4.6,0.6 4,0.6 4.6,0 5,-0.6 5.4,0 5,1" />
          <polygon points="9,1 8.6,0.6 8,0.6 8.6,0 9,-0.6 9.4,0 9,1" />
          <polygon points="13,1 12.6,0.6 12,0.6 12.6,0 13,-0.6 13.4,0 13,1" />
        </g>
        <g id="row2-stars" transform="translate(2, 4)">
          <polygon points="3,3 2.6,2.6 2,2.6 2.6,2 3,1.4 3.4,2 3,3" />
          <polygon points="7,3 6.6,2.6 6,2.6 6.6,2 7,1.4 7.4,2 7,3" />
          <polygon points="11,3 10.6,2.6 10,2.6 10.6,2 11,1.4 11.4,2 11,3" />
        </g>
        <g id="row3-stars" transform="translate(2, 6)">
          <polygon points="1,5 0.6,4.6 0,4.6 0.6,4 1,3.4 1.4,4 1,5" />
          <polygon points="5,5 4.6,4.6 4,4.6 4.6,4 5,3.4 5.4,4 5,5" />
          <polygon points="9,5 8.6,4.6 8,4.6 8.6,4 9,3.4 9.4,4 9,5" />
          <polygon points="13,5 12.6,4.6 12,4.6 12.6,4 13,3.4 13.4,4 13,5" />
        </g>
        <g id="row4-stars" transform="translate(2, 8)">
          <polygon points="3,7 2.6,6.6 2,6.6 2.6,6 3,5.4 3.4,6 3,7" />
          <polygon points="7,7 6.6,6.6 6,6.6 6.6,6 7,5.4 7.4,6 7,7" />
          <polygon points="11,7 10.6,6.6 10,6.6 10.6,6 11,5.4 11.4,6 11,7" />
        </g>
        <g id="row5-stars" transform="translate(2, 10)">
          <polygon points="1,9 0.6,8.6 0,8.6 0.6,8 1,7.4 1.4,8 1,9" />
          <polygon points="5,9 4.6,8.6 4,8.6 4.6,8 5,7.4 5.4,8 5,9" />
        </g>
      </g>
    </svg>
  );
};

export default AmericanFlag;