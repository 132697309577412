import { People } from '@mui/icons-material'
import { Button, Grid, Paper, TextField } from '@mui/material'
import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import RoleContext from '../auth/RoleContext'
import { getRequestConfig, getToken } from '../auth/auth'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import { ApiGateway } from '../config/config'
import ConfirmDialog from '../form/ConfirmDialog'
import Page from '../layout/Page'
import UploadFileControl from './UploadFileControl'
import UserCard from './UserCard'
import eventBus from './eventBus';
import appBus from '../utils/EventBus.js';
import Event from '../utils/EventNames.js';

export default function UserFormPage(props = {}) {
  const params = useParams()
  const navigate = useNavigate()
  // const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [saveClicked, setSaveClicked] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [tryAgainDialog, setTryAgainDialog] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [isNewUser, _setIsNewUser] = useState(params.koId === 'new')
  const [_directoryUserFound, setDirectoryUserFound] = useState(false)
  const [directoryUserNotFound, setDirectoryUserNotFound] = useState(false)
  const [_appUserFound, setAppUserFound] = useState(false)
  const [users, setUsers] = useState([])
  const [email, setEmail] = useState('');
  const [errorsText, setErrorsText] = useState('');
  const [errorsDialogOpen, setErrorsDialogOpen] = useState(false);
  const [usersNotFoundDialogOpen,setUsersNotFoundDialogOpen] = useState(false)
  const [missingUsers, setMissingUsers] = useState([])
  const [noUsersFoundDialogOpen, setNoUsersFoundDialogOpen] = useState(false)
  const getUserByKoId = async (koId, requestConfig) => {
    if (!requestConfig) {
      requestConfig = await getRequestConfig()
    }
    const basePath = `${ApiGateway.profile}/${koId}`
    startLoading()
    try {
      const response = await axios.get(basePath, requestConfig)
      if (response.data) {
        const u = response.data
        const _u = { ...u, koId, role: u.roles[0], mfrProfileIds: u.mfrProfileIds ?? [], ownershipIds: u.ownershipIds ?? [] }
        const _users = [...users, _u]
        setUsers(_users)
        return _u
      } else {
        setAppUserFound(false)
      }
    } catch (error) {
      setAppUserFound(false)
    } finally {
      stopLoading()
    }
  }

  const saveUser = async () => {

    const promises = users.map(u => {
      return new Promise(async (f, r) => {
        const basePath = `${ApiGateway.profile}/${u.koId}`
        eventBus.emit(`${u.email}-loading`, true)

        try {
          const requestConfig = await getRequestConfig()
          const _user = { ...u, roles: [u.role] }
          await axios.post(basePath, _user, requestConfig)
          f(u.email)
        } catch (error) {
          r({ email: u.email, error: error.message })
        }
      })
    })

    const results = await Promise.allSettled(promises)
    const failed = results.filter(r => r.status === 'rejected').map(r => r.reason)
    const failedEmails = failed.map(f => f.email)
    const failedUsers = users.filter(u => failedEmails.includes(u.email))
      .map(u => {
        const error = failed.find(f => f.email === u.email)
        eventBus.emit(`${u.email}-loading`, false)
        return { ...u, errors: [{ prop: 'save', message: error.error }] }
      })
    const successfulUsers = users.filter(u => !failedEmails.includes(u.email))
    if(isNewUser) {
      setUsers(failedUsers)
    } else {
      successfulUsers.forEach(u => {
        eventBus.emit(`${u.email}-loading`, false)
      })
      setUsers([...successfulUsers, ...failedUsers])
    }
  }

  const onDelete = useMemo(() => {
    return (email) => {
      const _users = users.filter((u) => u.email !== email)
      setUsers(_users)
    }
  }, [users])

  let saveUnsuccessfulError
  const getSaveUnsuccessfulText = () => {
    return `Could not save user due to ${saveUnsuccessfulError}, do you want to try again?`
  }

  const hideTryAgainDialog = () => {
    setTryAgainDialog(false)
  }

  const showDialog = () => {
    setConfirmDialog(true)
  }
  const hideDialog = () => {
    setConfirmDialog(false)
  }

  const onSave = () => {
    setSaveClicked(true)
    setTryAgainDialog(false)
    saveUser().then(() => {
      setDirty(false)
      setSaveClicked(false)
    })
  }

  const confirmCancel = () => {
    if (isDirty) { showDialog() }
    else { handleClose() }
  }

  const handleClose = () => {
    // if (location.key === 'default') {
    //   navigate('/users/list')
    // } else {
      navigate(-1)
    // }
  }

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const setDirty = (dirty = true) => {
    setIsDirty(dirty)
  }

  const getDirectoryUser = async (search, filterBy) => {
    startLoading()

    try {
      const tokenResponse = await getToken()
      if (tokenResponse) {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenResponse.accessToken}`
        }
        const graphUrl = [
          'https://graph.microsoft.com/v1.0/users?',
          `$filter=${filterBy} eq '${search}'`,
          '&',
          '$select=',
          ['mail', 'givenName', 'surname', 'displayName', 'onPremisesSamAccountName'].join(',')
        ].join('')
        const searchResponse = await axios.get(graphUrl, { headers: headers })
        const _user = searchResponse.data?.value.length ? searchResponse.data?.value[0] : undefined
  
        if (_user && _user.onPremisesSamAccountName !== null) {
          setDirectoryUserFound(true)
          const u = await getUserByKoId(_user.onPremisesSamAccountName)
          if (u) {
            setUsers([...users, {...u, newUser: false, notify: false}])
            setEmail('')
          } else {
            setUsers([...users, {
              email: _user.mail,
              firstName: _user.givenName,
              lastName: _user.surname,
              koId: _user.onPremisesSamAccountName,
              mfrProfileIds: [],
              ownershipIds: [],
              valid: true,
              errors: [],
              newUser: true,
              notify: true
            }])
            setEmail('')
          }
        } else {
          setDirectoryUserNotFound(true)
        }
      }
    } catch(err) {
      appBus.emit(Event.ALERT, {
        "text": 'Failed to lookup user in the directory',
        "style": "error",
        "duration": 2000,
      })
    } finally {
      stopLoading()
    }
  }

  const handleLookupClick = () => {
    const existing = users.find((u) => u.email === email)
    if (!email) {
      alert('Please enter an email')
    } else if (existing) {
      setEmail('')
      alert('User already added')
    } else {
      getDirectoryUser(email, 'mail')
    }
  }

  // const handleRoleSelectChange = async (event) => {
  //   const rid = event.target.value
  //   const _role = getRoleById(rid)
  //   if (_role) {
  //     setUser({ ...user, role: { id: _role.id, name: _role.name } })
  //   } else {
  //     setUser({ ...user, role: { id: 0, name: '' } })
  //   }
  //   if (rid === ROLES.BOTTLER.id && !user.mfrProfileIds.length) {
  //     setDirty(false)
  //   } else if (rid === ROLES.BOTTLER_CORP.id && !user.ownershipIds.length) {
  //     setDirty(false)
  //   } else setDirty()
  // }

  const getBreadcrumb = () => {
    return (
      <span><People></People>
        {isNewUser && (<span>&nbsp;Add&nbsp;New&nbsp;</span>)}
        {!isNewUser && (<span>&nbsp;Edit&nbsp;</span>)}
        User</span>
    )
  }

  const [manufacturerProfileList, setManufacturerProfileList] = useState([])
  const [ownershipList, setOwnershipList] = useState([])

  const getManufacturerProfilesList = async (requestConfig) => {

    const basePath = `${ApiGateway.manufacturerProfile}/search`
    const search = {}
    const response = await axios.post(basePath, search, requestConfig)
    if (response.data) {
      const { results } = response.data
      setManufacturerProfileList(results.map(r => { return { label: [r.manufacturerId, r.Manufacturer.manufacturerName].join(' - '), value: r.id } }))
    }
  }

  const getOwnershipList = async (requestConfig) => {

    const basePath = `${ApiGateway.ownership}/search`
    const search = {}
    const response = await axios.post(basePath, search, requestConfig)
    if (response.data) {
      const { results } = response.data
      setOwnershipList(results.map(r => { return { label: [r.ownershipId, r.ownershipName].join(' - '), value: r.ownershipId } }))
    }
  }

  const initializePage = async () => {
    const requestConfig = await getRequestConfig()

    startLoading()
    const promises = [getManufacturerProfilesList(requestConfig), getOwnershipList(requestConfig)]
    if (params.koId && params.koId !== 'new' && !initialized) {
      promises.push(getUserByKoId(params.koId, requestConfig))
    }
    await Promise.all(promises)
    setInitialized(true)
    stopLoading()

  }

  const getOwnershipOptionLabel = (option) => {
    if (typeof option === 'string' || !option.label) {
      const ownership = ownershipList.find((p) => p.value === (option.value ?? option))
      option = !ownership ? undefined : { value: ownership.value, label: ownership.label }
    }
    return !option ? undefined : option.label
  }

  useEffect(() => {
    initializePage()
  }, [])

  
  const createAndDownloadCsvFile = async (_data, _name) => {
    let csv
    if(_data) {
      csv = _data
    } else {
      const newData = missingUsers.map(d => ({email: d.email, error: d.errors[0].message}))
      csv = 'Email,Error\n' + newData.map(row => Object.values(row).join(',')).join('\n')
    }
    const blob = new Blob([csv], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = _name || 'missing-users.csv'
    a.click()
    window.URL.revokeObjectURL(url)
  }

  const csvFileTemplate = '"Email","RoleId","Ownerships","Facilities","Notify"'

  return (
    <RoleContext.Consumer>
      {roleCtx => (
        <Page {...props} breadcrumb={getBreadcrumb()} isLoading={isLoading}
          commandBar={<FormViewCommandBar onSave={onSave} onCancel={confirmCancel} enableSave={isDirty && !saveClicked} showClose={false} />}>
          <ConfirmDialog title="Discard all changes?" text="You will not be able to recover your work." open={confirmDialog} hideDialog={hideDialog} handleConfirm={handleClose} yesLabel="Discard" noLabel="Stay on Page" />
          <ConfirmDialog title="Save was not successful" text={getSaveUnsuccessfulText()} open={tryAgainDialog} hideDialog={hideTryAgainDialog} handleConfirm={onSave} yesLabel="Try Again" noLabel="Cancel" />
          <ConfirmDialog title="Errors" text={errorsText} open={errorsDialogOpen} hideDialog={() => setErrorsDialogOpen(false)} handleConfirm={() => setErrorsDialogOpen(false)} yesLabel="OK" />
          <ConfirmDialog title="Some users were not found in the directory" text={"Click DOWNLOAD to download the list of missing users."} open={usersNotFoundDialogOpen} hideDialog={() => setUsersNotFoundDialogOpen(false)} handleConfirm={() => createAndDownloadCsvFile() && setUsersNotFoundDialogOpen(false)} yesLabel="Download" noLabel="Cancel"/>
          <ConfirmDialog title="File error" text={"No users were able to be interpreted from your file. You may have provided a corrupt file or a file of the wrong format. Click DOWNLOAD TEMPLATE to download the example CSV file format."} open={noUsersFoundDialogOpen} hideDialog={() => setNoUsersFoundDialogOpen(false)} handleConfirm={() => createAndDownloadCsvFile(csvFileTemplate, 'BulkUserTemplate.csv') && setNoUsersFoundDialogOpen(false)} noLabel="OK" yesLabel="Download Template"/>
          {isNewUser && <Paper sx={{ padding: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField label="Email" value={email} fullWidth id="email_input" size='small'
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={3}><Button onClick={handleLookupClick} variant="outlined" style={{ width: "100%" }}>Add User by Email</Button></Grid>
              {directoryUserNotFound &&
                <ConfirmDialog title="Error" text="An active account was not found for this email address" open={directoryUserNotFound} hideDialog={() => setDirectoryUserNotFound(false)} handleConfirm={() => setDirectoryUserNotFound(false)} yesLabel="OK" />
              }
              {/* style={{height: "40px", lineHeight: "40px"}} */}
              <Grid item xs={4} md={1} style={{ textAlign: "center", lineHeight: "30px" }}>
                <b>OR</b>
              </Grid>
              <Grid item xs={12} md={8}>
                <UploadFileControl
                  startLoading={startLoading}
                  stopLoading={stopLoading}
                  setResults={(results) => {
                    const _results = results.filter(r => !users.find(u => u.email === r.email))
                    const found = []
                    const notFound = []
                    _results.forEach(r => {
                      if (r.errors.filter(f => f.message === 'User not found in directory').length) {
                        notFound.push(r)
                      } else {
                        found.push(r)
                      }
                    })
                    if(notFound.length && found.length) {
                      setMissingUsers(notFound)
                      setUsersNotFoundDialogOpen(true)
                    }
                    if(found.length) {
                      setIsDirty(true)
                      const _users = [...users, ...found]
                      setUsers(_users)
                    }
                    if(!_results.length) {
                      setNoUsersFoundDialogOpen(true)
                    }
                  }}
                />
              </Grid>
            </Grid>

          </Paper>}
          <br />
          {users.map((u, i) => {
            return <UserCard
              key={u.email}
              user={u}
              setDirty={setDirty}
              manufacturerProfileList={manufacturerProfileList}
              ownershipList={ownershipList}
              getOwnershipOptionLabel={getOwnershipOptionLabel}
              onDelete={() => onDelete(u.email)}
              onChange={(user) => {
                const _users = [...users]
                _users[i] = user
                setUsers(_users)
              }}
              editMode={isNewUser}
              showErrors={(errors) => {
                setErrorsText(errors.join('\n'))
                setErrorsDialogOpen(true)
              }}
            />
          })}
        </Page>
      )}
    </RoleContext.Consumer>
  )
}