import React from 'react';

const CanadaFlag = () => {
  return (
<svg width="23" height="17" viewBox="0 0 23 17" xmlns="http://www.w3.org/2000/svg">

      <rect width="23" height="17" fill="white"/>
      <rect width="5.75" height="17" x="0" fill="#FF0000"/>
      <rect width="5.75" height="17" x="17.25" fill="#FF0000"/>
      
      <polygon fill="#FF0000" points="11.5,1 11,4.5 9,4 10,7 8.5,7.5 10,9 7.5,9.5 9,11 7.5,12 11,13 14.5,12 13,11 14.5,9.5 12,9 13.5,7.5 12,7 13,4 11,4.5" />
   </svg>
  );
};

export default CanadaFlag;