import React from "react"
import css from '../NfpStyles'
import { Box, Divider, Grid2 as Grid, Typography } from '@mui/material'
import { isEmpty } from '../../../utils/utils'

let css2 = {
  nutritionFactPanelVertical: {
    maxWidth: '342px'
  },
  av: {
    marginLeft: '1em',
    minHeight: '13px',
    verticalAlign: 'top'
  },
  bold: {
    fontWeight: 900
  },
  calories: {
    height: '3em'
  },
  caloriesH2: {
    position: 'absolute',
    bottom: 0,
    textAlign: 'right'
  },
  caloriesH3: {
    position: 'absolute', 
    bottom: 0,
    textAlign: 'left'
  },
  colXs6: {
    position: 'relative',
    minHeight: '1px',
    paddingRight: '15px',
    paddingLeft: '15px',
    float: 'left',
    width: '48%'
  },
  column1: {
    flexBasis: '100%',
    paddingLeft: 0
  },
  columnNutrientAv: {
    marginLeft: '0.5em',
    fontSize: '11px'
  },
  columnNutrientDv: {
    marginLeft: '0.5em',
    fontSize: '11px',
    float: 'none'
  },
  dv: {
    float: 'right',
    minHeight: '13px',
    verticalAlign: 'top'
  },
  h5: {
    fontSize: '12px',
    fontFamily: 'inherit',
    lineHeight: 1.1,
    verticalAlign: 'top',
    color: '#000000',
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    letterSpacing: 'normal',
    textAlign: 'left',
    width: '100%',
    display: 'inline',
    verticalAlign: 'top'
  },
  m003: {
    marginTop: '0px',
    marginRight: '0px',
    marginBottom: '3px',
    marginLeft: '0px'
  },  
  nutrientName: {
    whiteSpace: 'nowrap',
    fontSize: '11px'
  },
  servingSize: {
    lineHeight: 1.3
  },
  significantSourceText: {
    marginTop: '4px',
    display: 'block'
  }
}

export default function NfpVerticalView ({nfp = {}, simplified, serve, abbreviated, nutrientList, significantSourceText}) {
  let i = 1
  const showSaturatedFat = parseFloat(nfp.saturatedFatPercent) >= 2
  const showTransFat = parseFloat(nfp.transFatPercent) >= 2
  const showCholesterol = parseFloat(nfp.cholesterolPercent) >= 2
  const showDietaryFiber = parseFloat(nfp.dietaryFiberPercent) >= 2
  const showTotalSugars = parseFloat(nfp.totalSugarsPercent) >= 2
  const showAddedSugars = parseFloat(nfp.addedSugarsPercent) >= 2
  const showVitaminD = parseFloat(nfp.vitaminDMcgPercent) >= 2
  const showCalcium = parseFloat(nfp.calciumPercent) >= 2
  const showIron = parseFloat(nfp.ironPercent) >= 2
  const showPotassium = parseFloat(nfp.potassiumPercent) >= 2

  return (
    <Box id="nutrition-fact-panel-vertical" sx={{ ...css.nutritionFactPanel, ...css2.nutritionFactPanelVertical}}>
      <Typography variant="h1" sx={css.h1}>Nutrition Facts</Typography>
      <Divider sx={{...css.hr, ...css.hr1, ...css.mt0}} />
      {serve === 'multi' &&
        <Box component="span">{nfp.servingsPerContainer} {'' + nfp.servingsPerContainer === '1' ? 'serving' : 'servings'} per container</Box>
      }
      <Box sx={{ ...css.p0, ...css.m0}}>
      <Grid container>
        <Grid item size={6}>
          <Typography variant="h4" sx={{ ...css.h4, ...css2.servingSize}}>{abbreviated ? 'Serv.' : 'Serving'} Size</Typography>
        </Grid>
        <Grid item size={6}>
          <Typography variant="h4" sx={{ ...css.h4, ...css.dv }}>{nfp.servingSize}</Typography>
        </Grid>
      </Grid>
      </Box>
      <Divider sx={{ ...css.hr, ...css.hr6, ...css.mb0}} />
      <Grid container>
        <Grid item size={12}>
          <Box sx={Object.assign({}, css.calories, css2.calories)}>
            <Typography variant="h3" sx={{ ...css.h3, ...css2.caloriesH3 }}>Calories</Typography>
            <Box sx={Object.assign({}, css.fs6)}>Per Serving</Box>
            <Typography variant="h2" sx={{ ...css.h2, ...css2.caloriesH2 }}>{nfp.calories}</Typography>
          </Box>
          <Divider sx={{ ...css.hr, ...css.hr4 }}/>
          <Typography variant="h6" sx={{ ...css.h6, ...css.dv }}>% Daily Value</Typography>
          <Divider sx={{ ...css.hr, ...css.hr1 }}/>

          <Typography variant="h5" sx={{ ...css2.h5, ...css.bold}}>Total Fat</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.totalFat ? `${nfp.totalFat}g` : ''}</Box><Box component="span" sx={Object.assign({}, css.dv, css2.dv)}>{nfp.totalFatPercent ? `${nfp.totalFatPercent}%` : ''}</Box>
          <Divider sx={{ ...css.hr, ...css.hr1}} />
          {!simplified && 
          <>
            <Box style={css.indent1}>
              {showSaturatedFat && <>
                <Typography variant="h5" sx={{ ...css2.h5, ...css.indent1Child }}>Saturated Fat</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.saturatedFat ? `${nfp.saturatedFat}g` : ''}</Box><Box component="span" sx={Object.assign({}, css.dv, css2.dv)}>{nfp.saturatedFatPercent ? `${nfp.saturatedFatPercent}%` : ''}</Box>
                <Divider sx={{ ...css.hr, ...css.hr1}} />
              </>}
              {showTransFat && <>
                <Typography variant="h5" sx={{ ...css2.h5, ...css.indent1Child }}><i>Trans</i> Fat</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.transFat ? `${nfp.transFat}g` : ''}</Box><Box component="span" sx={Object.assign({}, css.dv, css2.dv)}>{nfp.transFatPercent ? `${nfp.transFatPercent}%` : ''}</Box>
                <Divider sx={{ ...css.hr, ...css.hr1}} />
              </>}
            </Box>
            {showCholesterol && 
              <>
                <Typography variant="h5" sx={{ ...css2.h5, ...css.bold}}>Cholesterol</Typography>
                <Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.cholesterol ? `${nfp.cholesterol}g` : ''}</Box>
                <Box component="span" sx={Object.assign({}, css.dv, css2.dv)}>{nfp.cholesterolPercent ? `${nfp.cholesterolPercent}%` : ''}</Box>
                <Divider sx={{ ...css.hr, ...css.hr1}} />
              </>}
            
          </>}
          <Typography variant="h5" sx={{ ...css2.h5, ...css.bold}}>Sodium</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.sodium ? `${nfp.sodium}mg` : ''}</Box><Box component="span" sx={Object.assign({}, css.dv, css2.dv)}>{nfp.sodiumPercent ? `${nfp.sodiumPercent}%` : ''}</Box>
          <Divider sx={{ ...css.hr, ...css.hr1}} />
          <Typography variant="h5" sx={{ ...css2.h5, ...css.bold}}>Total {abbreviated ? 'Carb.' : 'Carbohydrate'}</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.totalCarb ? `${nfp.totalCarb}g` : ''}</Box><Box component="span" sx={Object.assign({}, css.dv, css2.dv)}>{nfp.totalCarbPercent ? `${nfp.totalCarbPercent}%` : ''}</Box>
          <Divider sx={{ ...css.hr, ...css.hr1}} />
          <Box sx={css.indent1}>
            {showDietaryFiber && <>
              <Typography variant="h5" sx={{ ...css2.h5, ...css.indent1Child }}>Dietary Fiber</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.dietaryFiber ? `${nfp.dietaryFiber}g` : ''}</Box><Box component="span" sx={Object.assign({}, css.dv, css2.dv)}>{nfp.dietaryFiberPercent ? `${nfp.dietaryFiberPercent}%` : ''}</Box>
            </>}
            {showDietaryFiber && showTotalSugars && <Divider sx={{ ...css.hr, ...css.hr1}} />}
            {showTotalSugars && <><Typography variant="h5" sx={{ ...css2.h5, ...css.indent1Child}}>Total Sugars</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.totalSugars ? `${nfp.totalSugars}g` : ''}</Box></> }
            {((showDietaryFiber || showTotalSugars) && showAddedSugars) && <Divider sx={{ ...css.hr, ...css.hr1}} /> }
            {showAddedSugars && <><Typography variant="h5" sx={{ ...css2.h5, ...css.indent1Child }}>Added Sugars</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.addedSugars ? `${nfp.addedSugars}g` : ''}</Box><Box component="span" sx={Object.assign({}, css.dv, css2.dv)}>{nfp.addedSugarsPercent ? `${nfp.addedSugarsPercent}%` : ''}</Box></>}
          </Box>
          {(showDietaryFiber || showTotalSugars || showAddedSugars) && <Divider sx={{ ...css.hr, ...css.hr1}} /> }
          <Typography variant="h5" sx={{ ...css2.h5, ...css.bold}}>Protein</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.protein ? `${nfp.protein}g` : ''}</Box>
          <Divider sx={{ ...css.hr, ...css.hr6 }} />
          {nutrientList && nutrientList.length > 0 && <>
              <Grid container>
                {nutrientList.map((nutrient, idx, arr) => {
                  if(nutrient.outputName && ((nutrient.amount && nutrient.uom) || nutrient.percent)) {
                    const item = (
                      <React.Fragment key={`nutrient${i}`}>
                        <Grid item size={6} sx={{ textAlign: i % 2 === 0 ? 'right' : 'left', position: 'relative' }}>
                          { i % 2 === 0 && <Box sx={{position:'absolute', left:0}}>•</Box> }
                          <Box component="span" sx={css2.nutrientName}>{nutrient.outputName}</Box>
                          <Box component="span" sx={{ ...css.av, ...css2.av, ...css2.columnNutrientAv }}>{nutrient.amount ? nutrient.amount + nutrient.uom : ' '}</Box>
                          <Box component="span" sx={{ ...css.dv, ...css2.dv, ...css2.columnNutrientDv }}>{nutrient.percent ? nutrient.percent + '%' : ' '}</Box>
                        </Grid>
                        { i % 2 === 0 && (idx < arr.length - 1) ? <Grid item size={12}><Divider sx={{ ...css.hr, ...css.hr1}} /></Grid> : ''}
                      </React.Fragment>
                    )
                    i = i + 1
                    return item
                  } else {
                    return
                  }
                })} 
              </Grid>                                                          
            </>
          }          
        </Grid>
      </Grid>
      {simplified && significantSourceText && 
        <>
          <Divider sx={{ ...css.hr, ...css.hr4 }} />
          <Box component="span" sx={Object.assign({}, css.fs6, css2.significantSourceText)}>{significantSourceText}</Box>
        </>
      }
    </Box>
  )
}

