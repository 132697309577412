import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { getRequestConfig } from '../auth/auth'
import NfpStandardView from "./nfpViews/ca/NfpStandardView"
import NfpVerticalView from "./nfpViews/us/NfpVerticalView"
import NfpTabularView from './nfpViews/us/NfpTabularView'
import NfpLinearView from './nfpViews/us/NfpLinearView'
import NfpDualColumnView from './nfpViews/us/NfpDualColumnView'
import nutrientMap from './model/LdsNutrientMap';
import { default as bus } from '../utils/EventBus'
import Event from '../utils/EventNames';
import axios from 'axios'
import dayjs from 'dayjs';
import Page from '../layout/Page'
import he from 'he'

import { Tab, Tabs, TabPanel} from '../layout/Tabs'
import LDSInput from '../form/LDSInput'
import { TextField } from '../layout/TextField'
import { HorizontalRule } from '../layout/HorizontalRule'
import { handleFieldChange, handleSelectChange, handleTextChange } from '../form/Form.js'
import { Article as ArticleIcon, ContentCutOutlined, Download as DownloadIcon } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2 as Grid, MenuItem, Paper, Select, Typography } from '@mui/material'
import { ApiGateway } from '../config/config';
import Panel from "../layout/Panel.js";

export default function LdsEntryPage (props) {
    const theme = useTheme()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false)

    const nfpDefaults = {status: 'draft', layout: 'vertical', simplified: '0', abbreviated: '0', serve: 'single'}
    const [nfp, setNfp] = useState(nfpDefaults);
    const [lds, setLds] = useState({});
    const [activeTab, setActiveTab] = useState(0);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [significantSourceText, setSignificantSourceText] = useState();
    const [nutrientList, setNutrientList] = useState([]);


    const { id } = useParams()

    const nutrients = [
      {label: 'Total Fat', id: 'totalFat', showOnPanel: true}, {label: 'Total Fat %DV', id: 'totalFatPercent', showOnPanel: true},
      {label: 'Saturated Fat', id: 'saturatedFat', showOnPanel: true},  {label: 'Saturated Fat %DV', id: 'saturatedFatPercent', showOnPanel: true},
      {label: 'Trans Fat', id: 'transFat', showOnPanel: true}, {label: 'Trans Fat %DV', id: 'transFatPercent', showOnPanel: true},
      {label: 'Cholesterol', id: 'cholesterol', showOnPanel: true}, {label: 'Cholesterol %DV', id: 'cholesterolPercent', showOnPanel: true},
      {label: 'Sodium', id: 'sodium', showOnPanel: true}, {label: 'Sodium %DV', id: 'sodiumPercent', showOnPanel: true},
      {label: 'Total Carbohydrate', id: 'totalCarb', showOnPanel: true}, {label: 'Total Carbohydrate %DV', id: 'totalCarbPercent', showOnPanel: true},
      {label: 'Dietary Fiber', id: 'dietaryFiber', showOnPanel: true}, {label: 'Dietary Fiber %DV', id: 'dietaryFiberPercent', showOnPanel: true},
      {label: 'Total Sugars', id: 'totalSugars', showOnPanel: true}, {label: 'Total Sugars %DV', id: 'totalSugarsPercent'},
      {label: 'Added Sugar', id: 'addedSugars', showOnPanel: true}, {label: 'Added Sugar %DV', id: 'addedSugarsPercent', showOnPanel: true},
      {label: 'Protein', id: 'protein', showOnPanel: true}, {label: 'Protein %DV', id: 'proteinPercent'},
      {label: 'Mono Fat', id: 'monoFat'}, {label: 'Mono Fat %DV', id: 'monoFatPercent'},
      {label: 'Poly Fat', id: 'polyFat'}, {label: 'Poly Fat %DV', id: 'polyFatPercent'},
      {label: 'Calories from Fat', id: 'caloriesFromFat'}, {label: 'Calories from Fat %DV', id: 'caloriesFromFatPercent'},
      {label: 'Calories from Sat. Fat', id: 'caloriesFromSatFat'}, {label: 'Calories from Sat. Fat %DV', id: 'caloriesFromSatFatPercent'},
    ]
    
    const vitamins = [
      {label:"Iron",id:"iron"}, {label:"Iron %DV",id:"ironPercent", showCitation: true},
      {label:"Potassium",id:"potassium"}, {label:"Potassium %DV",id:"potassiumPercent", showCitation: true},
      {label:"Calcium",id:"calcium"}, {label:"Calcium %DV",id:"calciumPercent", showCitation: true},
      {label:"Vitamin A",id:"vitaminA"}, {label:"Vitamin A %DV",id:"vitaminAPercent", showCitation: true},
      {label:"Vitamin B2",id:"vitaminB2"}, {label:"Vitamin B2 %DV",id:"vitaminB2Percent", showCitation: true},
      {label:"Vitamin B3",id:"vitaminB3"}, {label:"Vitamin B3 %DV",id:"vitaminB3Percent", showCitation: true},
      {label:"Vitamin B6",id:"vitaminB6"}, {label:"Vitamin B6 %DV",id:"vitaminB6Percent", showCitation: true},
      {label:"Vitamin B12",id:"vitaminB12"}, {label:"Vitamin B12 %DV",id:"vitaminB12Percent", showCitation: true},
      {label:"Biotin",id:"biotin"}, {label:"Biotin %DV",id:"biotinPercent", showCitation: true},
      {label:"Vitamin C",id:"vitaminC"}, {label:"Vitamin C %DV",id:"vitaminCPercent", showCitation: true},
      {label:"Vitamin D MCG",id:"vitaminDMcg"}, {label:"Vitamin D MCG %DV",id:"vitaminDMcgPercent", showCitation: true},
      {label:"Vitamin E Mg",id:"vitaminEMg"}, {label:"Vitamin E Mg %DV",id:"vitaminEMgPercent", showCitation: true},
      {label:"Magnesium",id:"magnesium"}, {label:"Magnesium %DV",id:"magnesiumPercent", showCitation: true},
      {label:"Zinc",id:"zinc"}, {label:"Zinc %DV",id:"zincPercent", showCitation: true},
      {label:"Selenium",id:"selenium"}, {label:"Selenium %DV",id:"seleniumPercent", showCitation: true},
      {label:"Vitamin K",id:"vitaminK"}, {label:"Vitamin K %DV",id:"vitaminKPercent", showCitation: true},
      {label:"Thiamin",id:"thiamin"}, {label:"Thiamin %DV",id:"thiaminPercent", showCitation: true},
      {label:"Folate",id:"folate"}, {label:"Folate %DV",id:"folatePercent", showCitation: true},
      {label:"Pantothenic Acid",id:"pantothenicAcid"}, {label:"Pantothenic Acid %DV",id:"pantothenicAcidPercent", showCitation: true},
      {label:"Phosphorus",id:"phosphorus"}, {label:"Phosphorus %DV",id:"phosphorusPercent", showCitation: true}
    ]

    const setDirty = () => {
      setIsDirty(true)
    }

    const setNfp1 = (nfp1) => {
      setNfp(prevNfp => {
        const newNfp = { nfpDefaults, ...prevNfp }
        newNfp.nfp1 = nfp1
        return newNfp
      })
    }

    useEffect(() => {
        (async() => {
            setIsLoading(true)
            const requestConfig = await getRequestConfig()
            const basePath = ApiGateway.labelDeclarationSheets
            const nfpResponse = await axios.get(`${basePath}/products/${id}/nfp`, requestConfig)
            if(nfpResponse?.status === 200) {
                console.log('nfpResponse.data: ', nfpResponse.data)

                let newNfp = nfpResponse.data
                newNfp.status = newNfp.status !== undefined && ('' + newNfp.status).trim().length > 0 ? newNfp.status : 'draft'
                newNfp.layout = newNfp.layout !== undefined && ('' + newNfp.layout).trim().length > 0 ? newNfp.layout : 'vertical'
                newNfp.simplified = newNfp.simplified !== undefined && ('' + newNfp.simplified).trim().length > 0 ? newNfp.simplified : '0'
                newNfp.abbreviated = newNfp.abbreviated !== undefined && ('' + newNfp.abbreviated).trim().length > 0 ? newNfp.abbreviated : '0'
                newNfp.serve = newNfp.serve !== undefined && ('' + newNfp.serve).trim().length > 0 ? newNfp.serve : 'single'

                setNfp(newNfp)
                const ldsResponse = await axios.get(`${basePath}/${nfpResponse.data?.id}/lds-information`, requestConfig)
                if(ldsResponse.status === 200) {
                    // console.log('ldsResponse.data: ', ldsResponse.data)
                    setLds(ldsResponse.data)
                }
            }
            setIsLoading(false)
        })()
    }, [id])

    useEffect(() => {
      setNutrientList(getNutrientList(nfp.nfp1, nfp.nfp2))
      let text = (activeTab === 0) ? getSignificantSourceText(nfp.nfp1) : getSignificantSourceText(nfp.nfp2)
      // console.log('sig: ', text)
      setSignificantSourceText(text)
    }, [nfp])


  


    const getSignificantSourceText = (nfp = {}) => {
      // console.log('getSignificantSourceText, nfp: ', nfp)
      
      const sources = [
        'saturatedFatPercent', 
        'transFatPercent', 
        'cholesterolPercent', 
        'dietaryFiberPercent',
        'totalSugarsPercent',
        'addedSugarsPercent',
        'vitaminDMcgPercent',
        'calciumPercent',
        'ironPercent',
        'potassiumPercent'
      ]
  
      let text = []

      // console.log('nfp: ', JSON.stringify(nfp, null, 2))

      let insignificantSources = sources.filter(s => (parseFloat(nfp[s])) < 2)
      if(insignificantSources.length > 0) {
        let abbrev = '' + nfp.abbreviated === '1'
        const labelMap = {
          saturatedFatPercent: <span>{abbrev ? 'sat. fat' : 'saturated fat'}</span>,
          transFatPercent: <span><span style={{fontStyle: 'italic'}}>trans</span> fat</span>,
          cholesterolPercent: <span>{abbrev ? 'cholest.' : 'cholesterol'}</span>,
          dietaryFiberPercent: <span>{abbrev ? 'fiber' : 'dietary fiber'}</span>,
          totalSugarsPercent: <span>total sugars</span>,
          addedSugarsPercent: <span>added sugars</span>,
          vitaminDMcgPercent: <span>{abbrev ? 'vit. D' : 'vitamin D'}</span>,
          calciumPercent: <span>calcium</span>,
          ironPercent: <span>iron</span>,
          potassiumPercent: <span>{abbrev ? 'potas.' : 'potassium'}</span>
        }
        if(insignificantSources.length > 2) {
          let last = insignificantSources.pop()

          text = insignificantSources.map(s => <>{labelMap[s]}<span>, </span></>)
          text.push(<><span> and </span>{labelMap[last]}</>)
          
        } else if (insignificantSources.length > 1) {
          text = [labelMap[insignificantSources[0]], <span> and </span>, labelMap[insignificantSources[1]]]
        } else {
          text = [labelMap[insignificantSources[0]]]
        }
  
        text.unshift(<span>Not a significant source of </span>)
      }
      return text
    }


  const getBreadcrumb = () => {
    let breadcrumb = <span><ArticleIcon /> Label Declaration Sheet Entry</span>;
    return breadcrumb;
  }

  const getCommandBar = () => {
    let commandBar;
    commandBar =
      <div>
        {!isReadOnly && <Button size="small" variant="contained" sx={{marginRight:'.5em'}} disabled={(!isDirty || isSaving) && nfp?.id != null} onClick={async (event) => {
          await save(event)
        }}>{nfp?.id ? 'Save' : 'Create'}</Button>}
        <Button size="small" variant="contained" sx={{marginRight:'.5em'}} onClick={() => {
          if (isDirty) {
            setShowCancelModal(true)
          } else {
            navigate(-1);
          }
        }}>Cancel</Button>
        <Button size="small" variant="contained" sx={{marginRight:'.5em'}} onClick={(event) => {
        //   this.downloadWord(event);
        }}><DownloadIcon /> Download</Button>
        {/*<Button bsStyle="primary" disabled={true} onClick={(event) => {
          this.downloadWord(event, false, true);
        }}><i className="fa fa-file-pdf-o" /> PDF</Button>*/}
      </div>

    return commandBar;
  }


  const save = async(event) => {
    setIsSaving(true)
    
  }

  const handleTabChange = async (_event, activeTab) => {
    setActiveTab(activeTab)
  }

  const getNutrientList = (nfp1 = {}, nfp2 = {}) => {
    let abbreviated = nfp.abbreviated
    console.log('nfp1: ', JSON.stringify(nfp1, null, 2))
    return Object.keys(nutrientMap).reduce((nutrients, key) => {
      let nutrient =  { ...nutrientMap[key] }
      if (nutrient) {
        if(nfp1[key + 'Check'] === 'visible') {
          nutrient.outputName = abbreviated ? nutrient.abbrName : nutrient.fullName
          nutrient.amount = nfp1[key]
        } else if(nfp1[key + 'Check'] === 'citation') {
          nutrient.outputName = abbreviated ? nutrient.abbrName : nutrient.fullName
        }
        if(nfp1[key + 'PercentCheck'] === 'visible') {
          nutrient.outputName = abbreviated ? nutrient.abbrName : nutrient.fullName
          nutrient.percent = nfp1[key + 'Percent']
        } else if(nfp1[key + 'PercentCheck'] === 'citation') {
          nutrient.outputName = abbreviated ? nutrient.abbrName : nutrient.fullName
        }
        
        if(nfp2) {
          if(nfp2[key + 'Check'] === 'visible') {
            nutrient.amount2 = nfp2[key]
          }
          if(nfp2[key + 'PercentCheck'] === 'visible') {
            nutrient.percent2 = nfp2[key + 'Percent']
          }
        }
        if(nutrient.outputName) {
          nutrients.push(nutrient)
        }
      }
      return nutrients
    }, [])
  }

  return (
    <Page { ...props} breadcrumb = {getBreadcrumb()} commandBar={getCommandBar()} isLoading={isLoading}>
      <Button onClick={()=> {console.log('nfp: ', nfp)}}>Nfp State</Button>
      <Button onClick={()=> {console.log('lds: ', lds)}}>Lds State</Button>

      <Grid container>
          <Grid size={6}>
              <Paper sx={{padding: '.5em 1em'}}>
                  <Grid container>
                      <Grid size={3}>Product Name:</Grid>
                      <Grid size={9}>{nfp.productName}</Grid>
                      <Grid size={3}>Salesforce Product ID:</Grid>
                      <Grid size={9}>{id}</Grid>
                  </Grid>
                  <br/>
                  <Grid container>
                      <Grid size={3}>Formula Name:</Grid>
                      <Grid size={9}>{nfp.formulaName}</Grid>
                      <Grid size={3}>BBN:</Grid>
                      <Grid size={9}>{nfp.bbn}</Grid>
                  </Grid>
              </Paper>

              <Panel title="Layout Options" sx={{margin:'1em 0'}}>
                  <Grid container rowSpacing={1}>
                      <Grid container size={6}>
                          <Grid size={6}>Change Status:</Grid>
                          <Grid size={6}>
                            <Select id="status" size="small" value={nfp.status} onChange={(event) => {handleSelectChange('status', event, nfp, setNfp, setDirty)}}>
                              <MenuItem value="draft">Draft</MenuItem>
                              <MenuItem value="review">Under Review</MenuItem>
                              <MenuItem value="approved">Approved</MenuItem>
                            </Select>
                          </Grid>
                      </Grid>
                      <Grid container size={6}>
                          <Grid size={6}>Layout:</Grid>
                          <Grid size={6}>
                            <Select id="layout" size="small" value={nfp.layout} onChange={(event) => {handleSelectChange('layout', event, nfp, setNfp, setDirty)}}>
                              <MenuItem value="">Select One</MenuItem>
                              <MenuItem value="vertical">Vertical</MenuItem>
                              <MenuItem value="tabular">Tabular</MenuItem>
                              <MenuItem value="linear">Linear</MenuItem>
                              <MenuItem value="dual">Dual Column</MenuItem>
                              <MenuItem value="standard">Standard Nutrition Facts</MenuItem>
                              <MenuItem value="supplemented">Supplemented Nutrition Facts</MenuItem>
                            </Select>
                          </Grid>
                      </Grid>
                      <Grid container size={6}>
                          <Grid size={6}>Simplified/Standard:</Grid>
                          <Grid size={6}>
                            <Select id="simplified" size="small" value={nfp.simplified} onChange={(event) => {handleSelectChange('simplified', event, nfp, setNfp, setDirty)}}>
                              <MenuItem value="0">Standard</MenuItem>
                              <MenuItem value="1">Simplified</MenuItem>
                            </Select>
                          </Grid>
                      </Grid>
                      <Grid container size={6}>
                          <Grid size={6}>Abbreviated/Non-Abbreviated:</Grid>
                          <Grid size={6}>
                            <Select id="abbreviated" size="small" value={nfp.abbreviated} onChange={(event) => {handleSelectChange('abbreviated', event, nfp, setNfp, setDirty)}}>
                              <MenuItem value="0">Non-Abbreviated</MenuItem>
                              <MenuItem value="1">Abbreviated</MenuItem>
                            </Select>
                          </Grid>
                      </Grid>
                      <Grid container size={6}>
                          <Grid size={6}>Single-Serve/Multi-Serve:</Grid>
                          <Grid size={6}>
                            <Select id="serve" size="small" value={nfp.serve} onChange={(event) => {handleSelectChange('serve', event, nfp, setNfp, setDirty)}}>
                              <MenuItem value="single">Single-Serve</MenuItem>
                              <MenuItem value="multi">Multi-Serve</MenuItem>
                            </Select>
                          </Grid>
                      </Grid>
                  </Grid>
              </Panel>
              <Panel>
                <Tabs value={activeTab} onChange={handleTabChange} textColor="primary" indicatorColor="primary">
                  <Tab label="Nfp" variant="contained" />
                  <Tab label="LDS Info" />
                  {/* <Tab label="Collaborators" />
                  <Tab label="History" /> */}
                </Tabs>
                <TabPanel value={activeTab} index={0}>
                  {nfp.nfp1 && <Grid container spacing={2}>
                    <Grid container size={12} >
                      <Grid size={6}>
                        <LDSInput label="Servings per Container" showCheckbox={ false } id="servingsPerContainer" fullWidth value={nfp.nfp1 ?? ''} onChange={(event) => { const { target } = event; handleFieldChange(target.id, target.value, nfp.nfp1, setNfp1, setDirty)}} />
                      </Grid>
                      <Grid size={6}></Grid>
                      <Grid size={6}>
                        <LDSInput label="Serving Size" showOnPanel={true} showCheckbox={ false } id="servingSize" fullWidth value={nfp.nfp1 ?? ''} onChange={(event) => { const { target } = event; handleFieldChange(target.id, target.value, nfp.nfp1, setNfp1, setDirty)}} />
                      </Grid>
                      <Grid size={12}>
                        <HorizontalRule thickness={8} />
                      </Grid>
                      <Grid size={6}>
                        <LDSInput label="Calories" showOnPanel={true} showCheckbox={ false } id="calories" fullWidth value={nfp.nfp1 ?? ''} onChange={(event) => { const { target } = event; handleFieldChange(target.id, target.value, nfp.nfp1, setNfp1, setDirty)}} />
                      </Grid>
                      <Grid size={12}>
                        <HorizontalRule thickness={4} />
                      </Grid>
                      {nutrients.map(nutrient => (
                        <Grid size={6}>
                          <LDSInput showOnPanel={nutrient.showOnPanel} label={nutrient.label} showCheckbox={ false } id={nutrient.id} fullWidth value={nfp.nfp1 ?? ''} onChange={(event) => { const { target } = event; handleFieldChange(target.id, target.value, nfp.nfp1, setNfp1, setDirty)}} />
                        </Grid>
                      ))}
                      <Grid size={12}>
                        <HorizontalRule thickness={4} />
                      </Grid>
                      {vitamins.map(vitamin => (
                        <Grid size={6}>
                          <LDSInput label={vitamin.label} showCitation={vitamin.showCitation} id={vitamin.id} fullWidth value={nfp.nfp1 ?? ''} onChange={(event) => { const { target } = event;  console.log('event: ', event); handleFieldChange(target.id, target.value, nfp.nfp1, setNfp1, setDirty)}} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>}
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                  {lds && <Grid container spacing={2}>
                    <Grid container size={12} sx={{padding: '.5em'}}>
                      <Grid size={4}>
                        <Typography>Statement of Identity</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{lds.statementOfIdentity}</Typography>
                      </Grid>

                      <Grid size={4}>
                        <Typography>Net Quantity of Contents</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{lds.netQuantityOfContentsFreeForm}</Typography>
                      </Grid>

                      <Grid size={4}>
                        <Typography>Front-of-Pack Declaration</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{lds.frontOfPackDeclarationFreeForm}</Typography>
                      </Grid>
                    </Grid>

                    <Grid container size={12} sx={{padding: '.5em', borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc'}}>
                      <Grid size={4} >
                        <Typography>Juice Content</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{lds.juiceContent}</Typography>
                        {lds.juiceContentFinalValue  && <Typography>(Juice Content Label Claim): {lds.juiceContentFinalValue}</Typography>}
                      </Grid>
                    
                      <Grid size={4}>
                        <Typography>Ingredient Statement</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{lds.ingredients}</Typography>
                      </Grid>

                      <Grid size={4}>
                        <Typography>Manufacturer&apos;s Information</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{lds.finalManufacturersStatement}</Typography>
                      </Grid>
                    </Grid>

                    <Grid container size={12} sx={{padding: '.5em'}}>
                      <Grid size={4}>
                        <Typography>Country of Origin Statement</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{lds.countryOfOriginStatement}</Typography>
                      </Grid>

                      <Grid size={4}>
                        <Typography>Allergen Statement</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{lds.allergensNotes}</Typography>
                      </Grid>

                      <Grid size={4}>
                        <Typography>BioEngineered Disclosure Statement</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{lds.bioengineeredDisclosureStatement}</Typography>
                      </Grid>
                    </Grid>
                    
                    <Grid container size={12} sx={{padding: '.5em', borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc'}}>
                      <Grid size={4}>
                        <Typography>Consumer Inquiries</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{lds.consumerInquiriesFreeForm}</Typography>
                      </Grid>

                      <Grid size={4}>
                        <Typography>Claims and Qualifying Language</Typography>
                      </Grid>
                      <Grid size={8}>
                        <div> {lds.nutrientContentHealthClaimFreeForm ? ('(Nutrient Content Claim): ' + lds.nutrientContentHealthClaimFreeForm).replace(/(<([^>]+)>)/gi, "").split(/[\r\n]/).map((item, idx) => {
                          return (
                            <span key={`nutrientContentHealthClaimFreeForm-${idx}`}>
                              {he.decode(item)}
                              <br/>
                            </span>
                          )
                        }) : ''} </div>
                        <div> {lds.otherInformationClaimsFreeForm ? ('(Other Claims): ' + lds.otherInformationClaimsFreeForm).replace(/(<([^>]+)>)/gi, "").split(/[\r\n]/).map((item, idx) => {
                          return (
                            <span key={`otherInformationClaimsFreeForm-${idx}`}>
                              {he.decode(item)}
                              <br/>
                            </span>
                          )
                        }) : ''} </div>
                      </Grid>

                      <Grid size={4}>
                        <Typography>Kosher</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{lds.kosherFreeForm}</Typography>
                      </Grid>
                    </Grid>
                    
                    <Grid container size={12} sx={{padding: '.5em'}}>
                      <Grid size={4}>
                        <Typography>Other Label Information (i.e. Blend Statement; PKU Warning Statement; Caffeine info.; Organic info.; Reconstitution instructions; Storage/use instructions;
                          NOTE: Please make sure that the 1-800-telephone number for consumer inquiries is listed on the label.)</Typography>
                      </Grid>
                      <Grid size={8}>
                          {lds.blendStatement && <Typography>(Blend Statement): {lds.blendStatement}</Typography>}
                          {lds.caffeineQuantitativeClaimFreeForm && <Typography>(Caffeine Information): {lds.caffeineQuantitativeClaimFreeForm}</Typography>}
                          {lds.organicInformation && <Typography>(Organic Information): {lds.organicInformation}</Typography>}
                          {lds.reconstitutionInstructions && <Typography>(Reconstitution Instructions): {lds.reconstitutionInstructions}</Typography>}
                          {lds.storageUseInstructions && <Typography>(Storage Use Instructions): {lds.storageUseInstructions}</Typography>}
                          {lds.pkuWarningStatement && <Typography>(PKU Warning Statement): {lds.pkuWarningStatement}</Typography>}
                          {lds.consumerInquiriesFreeForm && <Typography>(Consumer Inquiries Freeform): {lds.consumerInquiriesFreeForm}</Typography>}
                          {lds.bioengineeredDisclosureStatement && <Typography>(BioEngineered Disclosure Statement): {lds.bioengineeredDisclosureStatement}</Typography>}
                          {lds.otherInformation && <Typography>(Blend Statement): {lds.otherInformation}</Typography>}
                      </Grid>

                      <Grid size={4}>
                        <Typography>Label Code</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{lds.labelCode}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  }
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                  Content for Collaborators
                </TabPanel>
                <TabPanel value={activeTab} index={3}>
                  Content for History
                </TabPanel>
              </Panel>
          </Grid>
          <Grid size={6}>
            <div style={{ position: "fixed", top: "106px", left: '50%', transform: 'translateX(170px)',  height:"100vh" }}>
              <Box sx={{width: '100% - 270px'}}>
                {nfp.layout === 'vertical' &&
                <NfpVerticalView 
                  nfp={nfp?.nfp1 ?? {}} 
                  serve={nfp.serve}
                  simplified={nfp.simplified === '1'}
                  abbreviated={nfp.abbreviated === '1'} 
                  significantSourceText={significantSourceText} 
                  nutrientList={nutrientList}
                />}
                {nfp.layout === 'tabular' &&
                <NfpTabularView 
                  nfp={nfp?.nfp1 ?? {}} 
                  serve={nfp.serve}
                  simplified={nfp.simplified === '1'}
                  abbreviated={nfp.abbreviated === '1'} 
                  significantSourceText={significantSourceText} 
                  nutrientList={nutrientList}
                />}
                {nfp.layout === 'linear' &&
                <NfpLinearView 
                  nfp={nfp?.nfp1 ?? {}} 
                  serve={nfp.serve}
                  simplified={nfp.simplified === '1'}
                  abbreviated={nfp.abbreviated === '1'} 
                  significantSourceText={significantSourceText} 
                  nutrientList={nutrientList}
                />}
                {nfp.layout === 'dual' &&
                <NfpDualColumnView 
                  nfp1={nfp?.nfp1 ?? {}} 
                  nfp2={nfp?.nfp2 ?? {}} 
                  serve={nfp.serve}
                  simplified={nfp.simplified === '1'}
                  abbreviated={nfp.abbreviated === '1'} 
                  significantSourceText={significantSourceText} 
                  nutrientList={nutrientList}
                />}
                {nfp.layout === 'standard' &&
                <NfpStandardView 
                  nfp={nfp?.nfp1 ?? {}} 
                  serve={nfp.serve}
                  simplified={nfp.simplified === '1'}
                  abbreviated={nfp.abbreviated === '1'} 
                  significantSourceText={significantSourceText} 
                  nutrientList={nutrientList}
                />}
                {/* {nfp.layout === 'supplemented' &&
                <NfpSupplementedView 
                  nfp={nfp?.nfp1 ?? {}} 
                  serve={nfp.serve}
                  simplified={nfp.simplified === '1'}
                  abbreviated={nfp.abbreviated === '1'} 
                  significantSourceText={significantSourceText} 
                  nutrientList={nutrientList}
                />}                 */}

              </Box>
            </div>
          </Grid>
      </Grid>

      <Dialog open={showCancelModal}>
        <DialogTitle>Unsaved Changes</DialogTitle>

        <DialogContent>
          <p>You have unsaved changes. Are you sure you want to cancel?</p>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => {
            navigate(-1, 
              {
                message: {
                  message: 'Changes were discarded...',
                  type: 'info',
                  duration: 2000
                }
              }
            );
          }}>Discard Changes</Button>
          <Button onClick={() => {
            setShowCancelModal(false)
          }}>Continue Working</Button>
        </DialogActions>
      </Dialog>
    </Page>
    
    
      
    
)}