import { default as React, useEffect, useState } from 'react'
import DecimalFormat from '@sei-atl/decimal-format'
import b64toBlob from 'b64-to-blob'
import { Box, Button, Collapse, Grid2 as Grid, FormControl, InputAdornment, InputLabel, LinearProgress, Link, MenuItem, Paper, Select, Stepper, Step, StepButton, Switch, TextField, Typography, stepConnectorClasses } from '@mui/material'
import { Download as DownloadIcon, Forward as ForwardIcon, Info as InfoIcon, LocalDrink as LocalDrinkIcon, NavigateBefore as NavigateBeforeIcon, NavigateNext as NavigateNextIcon  } from '@mui/icons-material'
import Page from '../layout/Page.js'
import { getRequestConfig } from '../auth/auth.js'
import { ApiGateway } from '../config/config.js'
import ProductsCheckboxList from '../form/ProductsCheckboxList3'
import ListNutritionCalculations from './ListNutritionCalculations'
import CollapsePanel from '../layout/CollapsePanel'
import axios from 'axios'
import { default as bus } from '../utils/EventBus.js'
import Event from '../utils/EventNames.js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { GlobalStyles } from '@mui/system';
import CocaColaLogo from '../img/coca-cola-xs.png'
import { styled, keyframes } from '@mui/system';
import CanadianFlagIcon from '../img/flag-canada'
import AmericanFlagIcon from '../img/flag-usa'
import dayjs from 'dayjs';
import ServingSizeTextField from '../form/ServingSizeTextField.js'

const fade = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const BlinkingBox = styled(Box)`
  animation: ${fade} 1s infinite ease-in-out;
`;

const InfoBlock = (props) => {
  return <Paper elevation={3} sx={{ padding: 2, display: 'flex', alignItems: 'center', backgroundColor: '#f9f9f9', borderLeft: '5px solid #1976d2', marginBottom: 2, ...(props.sx ?? {}) }}>
    <Box>
      <InfoIcon sx={{ color: '#1976d2', marginRight: 1 }} />
      <Typography variant="body1">
        {props.children}
      </Typography>
    </Box>
  </Paper>
}

const sortOnProp = (prop='name') => {
  return (a, b) => {
    if(('' + a[prop]) < ('' + b[prop])) {
      return -1
    }
    if(('' + a[prop]) > ('' + b[prop])) {
      return 1
    }
    return 0;
  }
}

const ProductSelections = (props) => {
  return <Paper sx={props.sx}>
    <Typography sx={{textAlign: 'center', padding: '.5em 0'}}>{props.label}</Typography>
    <Box sx={{overflowY: 'scroll', maxHeight: '25em', width: '35em'}}>
    { Object.keys(props.items).map((key, i) => {
        let products = props.items[key]
        return products.map((product, j) => {
          return <div key={`selectedProduct${i}_${j}`}>
            {product.label}
          </div>
        })
      })
    }
    </Box>
  </Paper>
}

export function HorizontalStepperWithError({setIsLoading}) {
  
  const [activeStep, setActiveStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [completed, setCompleted] = useState({});
  const [countryId, _setCountryId] = useState()
  const [regulationId, setRegulationId] = useState();
  const [allNutrientsShown, setAllNutrientsShown] = useState([])
  const [nutritionCalculations, setNutritionCalculations] = useState([])
  const [roundedNutritionCalculations, setRoundedNutritionCalculations] = useState([])
  const [dispensingHardwareTypes, setDispensingHardwareTypes] = useState([])
  const [productsByHardware, setProductsByHardware] = useState({})
  const [servingSizeUnit, setServingSizeUnit] = useState('fl-oz')
  const [servingSizeLabels, setServingSizeLabels] = useState(new Array(5))
  const [servingSizeQuantities, setServingSizeQuantities] = useState(new Array(5))
  const [servingSizeErrorStates, setServingSizeErrorStates] = useState(Array(servingSizeQuantities.length).fill(false));
  const [sodium, setSodium] = useState('2.96')
  const [iceType, setIceType] = useState('none')
  const [iceFill, setIceFill] = useState('1')
  const [selectedProducts, setSelectedProducts] = useState({})
  const [selectedProductValues, setSelectedProductValues] = useState('')
  
  const [isProductOptionsLoading, setIsProductOptionsLoading] = useState(false)
  const [useRoundedValues, setUseRoundedValues] = useState(false);

  //iceFill.id => displacement factor, label
  const iceDisplacementFactors = {
    1: { displacementFactor: 1, label: 'No Ice'},
    2: { displacementFactor: 0.88, label: '1/4 Hard'},
    3: { displacementFactor: 0.83, label: '1/3 Hard'},
    4: { displacementFactor: 0.75, label: '1/2 Hard'}, 
    5: { displacementFactor: 0.67, label: '2/3 Hard'},
    6: { displacementFactor: 0.63, label: '3/4 Hard'},
    7: { displacementFactor: 0.5, label: 'Full Hard'},
    8: { displacementFactor: 0.83, label: '1/4 Soft'},
    9: { displacementFactor: 0.78, label: '1/3 Soft'},
    10: { displacementFactor: 0.67, label: '1/2 Soft'},
    11: { displacementFactor: 0.56, label: '2/3 Soft'},
    12: { displacementFactor: 0.5, label: '3/4 Soft'},
    13: { displacementFactor: 0.33, label: 'Full Soft'}
  }


  const getProductName = (item) => {
    let name = '';
    if(item && item.formulaName) {
      let idx = item.formulaName.indexOf('|');
      if (idx === -1) {
        idx = item.formulaName.length;
      }
      name = item.formulaName.substring(0, idx);
    }
    return name;
  }

  const handleUseRoundedValuesChange = (event) => {
    setUseRoundedValues(event.target.checked);
  };

  const fetchDispensingHardwareTypes = async () => {
    const requestConfig = await getRequestConfig()
    const response = await axios.get(ApiGateway.dispensingHardwareType, requestConfig)
    // console.log('fetchDispensingHardwareTypes response: ', response)
    if(response?.status === 200 && response.data) {
      setDispensingHardwareTypes(response.data?.data)
    } else {
      bus.emit(Event.ALERT, { text: "There was an issue loading the products, try again later...", style: "info", duration: 2000 })
    }
  }

  useEffect(() => {
    if(!(dispensingHardwareTypes?.length > 0)) {
      (async () => {
        await fetchDispensingHardwareTypes()

      })()
    }
  }, [dispensingHardwareTypes])

  useEffect(() => {
    if(activeStep === 5) {
      (async () => {
        setIsLoading(true)
        await fetchNutritionCalculations()
        setIsLoading(false)
      })()
    }
  }, [activeStep]);

  useEffect(() => {
    (async () => {
      if(countryId) {
        await fetchProductsByDispensingHardwareTypes(countryId)
      }
    })()
  }, [countryId, dispensingHardwareTypes])

  useEffect(() => {
    setUseRoundedValues(countryId === '840')
  }, [countryId])

  useEffect(() => {
    let values = Object.values(selectedProducts).flat()
    let newSelectedProductValues 
    if(values.length > 15) {
      newSelectedProductValues = <>
        {values.slice(0, 10).map(s => s.label).join(', ')}<span style={{display: showMore ? 'none' : 'inline'}}>, and {values.length - 10} more...</span>
        <Button variant="text" component={Link} sx={{margin:'0 2em', padding: 0}} hidden={showMore} onClick={() => { setShowMore(true) }}>Show All</Button>
        <Collapse in={showMore}>
          {values.slice(10).map(s => s.label).join(', ')}
        </Collapse>
      </>
    } else {
      newSelectedProductValues = values.map(s => s.label).join(', ')
    }

    setSelectedProductValues(newSelectedProductValues)
  }, [selectedProducts, showMore])

  const handleProductsChange = (event) => {
    // console.log('handleProductsChange, event: ', event)
    if(event?.target) {
      const { value, dispensingHardwareTypeId } = event.target
      if(value && dispensingHardwareTypeId) {
        const newSelectedProducts = Object.keys(value.checkboxes).reduce((acc, key) => {
          if(value.checkboxes[key] === true) {
            let keyRatio = `${key}_ratio`
            let label = value.selects[keyRatio]?.value ? `${value.selects[keyRatio]?.name} (${value.selects[keyRatio].value})` : value.selects[keyRatio]?.name
            acc.push({id: value.selects[keyRatio]?.id, label, value: value.selects[keyRatio]?.value})
          }
          return acc
        }, []).sort(sortOnProp('label'))
        setSelectedProducts((prevValues) => {
          return {
            ...prevValues,
            [dispensingHardwareTypeId]: newSelectedProducts
          }
        })
      }
    }
  }

  const mapFormulaData = (resultSet = []) => {
    let mapped = {}
    if(resultSet?.length) {
      mapped = resultSet?.reduce((acc, row) => {
        let record = {}
        let formula = {
          id: row.id,
          name: getProductName(row),
          type: 'formula',
          ratios: row.ratios,
          regulation: row.regulation
        }
        if(row.brand) {
          let brandId = `${row.brand.id}_brand`
          let brand = acc[brandId]
          if(brand) {
            //add formula to brand
            brand.children.push(formula)
            record = brand
          } else {
            //add new brand
            record = {
              id: brandId,
              type: 'brand',
              name: row.brand.name,
              children: [formula]
            }
          }
        } else {
          //add formula
          record = formula
        }
        acc[record.id] = record
        return acc
      }, {})
      mapped = Object.values(mapped)
      mapped = mapped.sort(sortOnProp('name'))
    }
    return mapped    
  }
  
  const download = async () => {
    setIsLoading(true)
    let body = {
      countryId,
      nutritionCalculations,
      sizeUom: servingSizeUnit
    }
   
    const requestConfig = await getRequestConfig()
    let response = await axios.post(`${ApiGateway.formulaNutrition}/write-calculations-to-workbook`, body, requestConfig)
    
    if (response?.data) {

      let contentType = response.headers['content-type'];
      let blob = b64toBlob(response.data, contentType);
      let fileName = 'nutrition-calculations.xlsx';
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // If IE, you must uses a different method.
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        let url = URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.style = 'display:none';
        document.body.appendChild(a);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
    setIsLoading(false)
  }

  const fetchNutritionCalculations = async () => {
    const requestConfig = await getRequestConfig()
    let p = Object.values(selectedProducts).flat() ?? []

    let products = p.reduce((acc, selectedProduct) => {
      if(!selectedProduct.id?.endsWith('_brand')) {
        let product = {id: selectedProduct.id}
        if(selectedProduct.value) {
          product.ratio = selectedProduct.value
        }
        acc.push(product)
      }
      return acc
    }, [])

    let sizes = servingSizeLabels?.reduce((acc, label, index) => {
      let value = servingSizeQuantities[index]
      if(label !== undefined && label !== null && label.trim().length > 0
        && value !== undefined && value !== null && ('' + value).trim().length > 0)
      acc.push({label, value})
      return acc
    }, [])

    let body = {
      products,
      sodium,
      sizeUom: servingSizeUnit,
      sizes,
      iceFill:{
        label: iceDisplacementFactors[iceFill]?.label, 
        displacementFactor: iceDisplacementFactors[iceFill]?.displacementFactor
      },
      regulationId
    }

    async function batch(body, requestConfig, options ={}) {
      options = { batchSize: 15, showProgress: false, ...options }
      // Define the ApiGateway endpoint
      const apiEndpoint = `${ApiGateway.formulaNutrition}/calculate-nutrition`;
  
      // Split the products into batches
      const total = body.products.length;
      let allNutrientsShown = [];
      let calculations = [];
      let index=0
      if(options.showProgress) {
        setProgress(0.1)
      } else {
        setProgress(100)
      }
      for (let i = 0; i < total; i += options.batchSize) {
        const batchBody = { ...body, products: body.products.slice(i, i + options.batchSize) };
        try {
          const response = await axios.post(apiEndpoint, batchBody, requestConfig);
          // Merge the results
          allNutrientsShown = allNutrientsShown.concat(response.data.allNutrientsShown);
          calculations = calculations.concat(response.data.calculations);
        } catch (error) {
            console.error('Error fetching nutrition calculations:', error);
        }
        if(options.showProgress) {
          setProgress(((index + 1) / Math.ceil(total/options.batchSize)) * 100);  
        }
        index++
      }

      let allNutrientsShownSet = new Set(allNutrientsShown)
      setCompleted({
        ...completed,
        [activeStep]: true,
      });

      // Returning the merged results
      return {
        allNutrientsShown: [...allNutrientsShownSet],
        calculations
      };
    }
    
    const response = await batch(body, requestConfig, { showProgress: products.length > 15 })
    
    if(response) {
      let {allNutrientsShown, calculations} = response
      setAllNutrientsShown(allNutrientsShown)

      // console.log('calculations: ', calculations)

      setNutritionCalculations(calculations)
      let rounded = [...calculations].reduce((acc, row) => {
        
        let newRow = {}
        Object.keys(row).sort().forEach(key => {
          if(key.startsWith("~")) {
            newRow[key.substring(1)] = row[key]
          } else {
            newRow[key] = row[key]
          }
        })
        acc.push(newRow)
        return acc
      }, [])
      setRoundedNutritionCalculations(rounded)
    }
  }

  const fetchProductsByDispensingHardwareTypes = async(countryId) => {
    if(dispensingHardwareTypes?.length) {
      setIsProductOptionsLoading(true)
      const requestConfig = await getRequestConfig()
      let promises = []
      for(let i = 0; i < dispensingHardwareTypes.length; i++) {
        let hardware = dispensingHardwareTypes[i]
        // promises.push({ hardwareId: hardware.id, promise: axios.get(`${ApiGateway.formulaNutrition}/dispensing-hardware-types/${hardware.id}`, requestConfig) });
        promises.push({ hardwareId: hardware.id, promise: axios.get(`${ApiGateway.formulaNutrition}/dispensing-hardware-types/${hardware.id}/country/${countryId}`, requestConfig) });
      }
      let results = await Promise.all(promises.map(p => p.promise))
      
      let p = results.reduce((acc, result, idx) => {
        // console.log(`result ${idx}: `, result, idx)
        let d = mapFormulaData(result.data?.data)
        acc[promises[idx].hardwareId] = d;
        return acc
      }, {}) 
      // console.log('fetchProductsByDispensingHardwareTypes results: ', p)
      setProductsByHardware(p)
      setIsProductOptionsLoading(false)
    }
  }

  const handleServingSizeBlur = (e, index) => {
    let value = e.target.value;
    value = ('' + value).trim()
    const isValid = value.length === 0  || /^\d+(\.\d+)?$/.test(value); // Regular expression to check if value is non-negative number with optional decimals
    const updatedErrors = [...servingSizeErrorStates];
    updatedErrors[index] = !isValid;
    setServingSizeErrorStates(updatedErrors);
  };

  const handleServingSizeLabelChange = (event) => {
    const { index, value } = event.target;
    setServingSizeLabels((prevState) => {
      const newState = [...prevState];
      newState[index] = value;
      return newState;
    });
  }
  
  const handleServingSizeQuantityChange = (event) => {
    const { index, value } = event.target;
    setServingSizeQuantities((prevState) => {
      const newState = [...prevState];
      newState[index] = value;
      return newState;
    });
  }

  const servingSizes = new Array(5).fill('')
  const countryRegulations = {
    '840': 2,
    '124': 4
  }
  const setCountryId = (newCountryId) => {
    const newRegulationId = countryRegulations[newCountryId]
    if(newRegulationId) {
      setRegulationId(newRegulationId)
      setServingSizeUnit(newCountryId === '124' ? 'ml' : newCountryId === '840' ? 'fl-oz' : '')
      _setCountryId(newCountryId)
    } else {
      bus.emit(Event.ALERT, { text: "There was an issue loading the products, try again later...", style: "info", duration: 2000 })
    }
  }


  const getDisclaimer = (countryId) => {
    let disclaimer = <></>
    if(countryId === '124') {
      disclaimer = <>
        <Typography sx={{fontStyle:'italic', padding: '.5em 0'}}>The information provided in this document was compiled from an online tool made available by The Coca-Cola Company. This online tool is intended to provide nutrition information to Customers for foodservice items. The nutrient values provided are based on vendor data, laboratory analyses, and scientific literature. The Coca-Cola Company certifies that the nutrient values are accurate and complete as of the date this data is pulled.</Typography>
        <Typography sx={{fontStyle:'italic', padding: '.5em 0'}}>The default information provided is unrounded. It is the customer’s responsibility to adhere to regulatory requirements for their jurisdiction.  Please note that rounded values provided are based on Ontario Menu Labeling Regulations.</Typography>
        <Typography sx={{fontStyle:'italic', padding: '.5em 0'}}>Adults and youth (ages 13 and older) need an average of 2,000 calories a day, and children (ages 4 to 12) need an average of 1,500 calories a day. However, individual needs vary.</Typography>
        </>
    } else if(countryId === '840') {
      disclaimer = <>
        <Typography sx={{fontStyle:'italic', padding: '.5em 0'}}>The information provided in this document was compiled from an online tool made available by The Coca-Cola Company. This online tool is intended to assist Customers in complying with FDA Menu Labeling Regulations. The nutrient values provided are based on USDA data, vendor data, laboratory analyses, and scientific literature. The Coca-Cola Company certifies that the nutrient values are accurate and complete as of the date this data is pulled.<br/></Typography>
        <Typography sx={{fontStyle:'italic', padding: '.5em 0'}}>Ice Displacement Factor is the ratio of fluid ounces not displaced by ice based on the ice assumptions selected. For example, selecting hard ice that is filling 1/3 of the cup will use a displacement factor of 0.83. A 10 fluid ounce cup with 0.83 ice displacement will show nutrient values for 8.3 fluid ounces.<br/></Typography>
        <Typography sx={{fontStyle:'italic', padding: '.5em 0'}}>The default sodium value of 2.96 mg per 1 fluid ounces in the water used in calculations represents the highest level of sodium in water used in our bottling facilities. The USDA National Nutrient Database for Standard Reference Release 26 has a value of 1 mg sodium per fluid ounces of municipal water.</Typography>
        <Typography sx={{fontStyle:'italic', padding: '.5em 0'}}>2,000 calories a day is used for general nutrition advice, but calorie needs vary. Figures expressed in terms of (% DV) refer to the Percent Daily Value based on a 2000 calorie diet.<br/></Typography>
      </>
    }
    return disclaimer
  }
  

  const steps = [{
    label: 'Welcome!',
    content: <>
      <Typography sx={{padding: '.5em 0'}}>Use this tool to calculate nutritional information by brand, cup size and ice fill.  
        This tool also allows you to specify the syrup ratios, water-supply sodium levels, and download the results!</Typography>
      <Typography sx={{padding: '.5em 0 1em'}}>To get started, please select your country:</Typography>
      
      <FormControl fullWidth size="small">
        <InputLabel id="country-label">Country</InputLabel>
        <Select
          labelId="country-label"
          id="country"
          label = "Country"
          value={countryId ?? ''}
          onChange={(e) => { setCountryId(e.target.value) }}
          sx={{ width: '400px'}}
        >
          <MenuItem value="124">Canada</MenuItem>
          <MenuItem value="840">United States</MenuItem>
        </Select>
      </FormControl>
      {countryId && <InfoBlock sx={{marginTop: '1em'}}>Now that you have selected your country, you are ready to navigate to the next step using the "Next" button above!</InfoBlock>}

    </>,
    isComplete: () => countryId !== undefined && countryId !== null && countryId.trim() !== '' 
  }, 
  {
    label: 'Select the Products',
    content: <Page isLoading={isProductOptionsLoading}>
      <Typography sx={{fontStyle: 'italic', padding: '.5em 0 1em'}}>Select the desired product(s) for which you want nutrition information.</Typography>
      <Grid container>
        <Grid size={7}>
          <ul >
            { dispensingHardwareTypes.map((option, i) => {
              return (
                <CollapsePanel 
                  key={`dispensingHW_${i}`} 
                  title={option.name.replace("&reg;", "")}
                  defaultExpanded={ false }
                >
                  {productsByHardware[option.id] && <ProductsCheckboxList key={`productCheckboxList_${i}`} dispensingHardwareTypeId={option.id} items={productsByHardware[option.id]} onChange={handleProductsChange}/>}
                </CollapsePanel>
              )
            })}
          </ul>
        </Grid>
        <Grid size={5}>
          { Object.keys(selectedProducts).reduce((total, key) => { return total += selectedProducts[key].length }, 0) > 0 && <ProductSelections items={selectedProducts} sx={{margin: '0 2em', padding: '.5em 1em', position: 'fixed' }} label="All Selections" /> }
        </Grid>
      </Grid>
    </Page>,
    isComplete: () => { return Object.values(selectedProducts).flat().length > 0 }
  },
  {
    label: 'Define the Serving Sizes',
    content: <>
        <Typography sx={{ fontStyle: 'italic', padding: '.5em 0 2em'}}>Use this section to define up to five cup sizes. At least one size must be entered.  Sizes entered will be used to generate nutrition scaled to those sizes.</Typography>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item size={6}>
              Cup Label (e.g. "Small" <LocalDrinkIcon sx={{fontSize: 'small'}}/>, "Medium" <LocalDrinkIcon sx={{fontSize: 'medium'}}/> or "Large" <LocalDrinkIcon sx={{fontSize: 'large'}}/>)
            </Grid>
            <Grid item size={6}>
              Size (volume) <Select size="small" value={servingSizeUnit} onChange={(event) => { setServingSizeUnit(event.target.value)}}>
                <MenuItem value="fl-oz">fl oz</MenuItem>
                <MenuItem value="g">g</MenuItem>
                <MenuItem value="gal">gal</MenuItem>
                <MenuItem value="kg">kg</MenuItem>
                <MenuItem value="l">L</MenuItem>
                <MenuItem value="lb">lb</MenuItem>
                <MenuItem value="mg">mg</MenuItem>
                <MenuItem value="ml">mL</MenuItem>
                <MenuItem value="oz">oz</MenuItem>
                
              </Select>
            </Grid>
            {servingSizes.map((_, index) => (
              
              <React.Fragment key={`row_${index}`}>
                <Grid item size={6} key={`label_${index}`}>
                  <TextField
                    fullWidth
                    id={`servingSizeLabel_${index}`}
                    placeholder={index === 0 ? 'e.g. Small' : `Cup Label ${index + 1}`}
                    value={servingSizeLabels[index]}
                    onChange={(e) => { e.target.index = index; handleServingSizeLabelChange(e) }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item size={6} key={`qty_${index}`}>


                  <ServingSizeTextField
                    fullWidth
                    key={`servingSizeQuantity_${index}`}
                    id={`servingSizeQuantity_${index}`}
                    placeholder={index === 0 ? `e.g. ${countryId === '124' ? '100' : '12'}` : `Size ${index + 1}`}
                    value={servingSizeQuantities[index]}
                    servingSizeUnit = { servingSizeUnit }
                    onChange={(e) => { e.target.index = index; handleServingSizeQuantityChange(e); }}
                    variant="outlined"
                    onBlur={(e) => handleServingSizeBlur(e, index)}
                    error={servingSizeErrorStates[index]}
                    helperText={servingSizeErrorStates[index] ? 'Please enter a valid numeric value (positive numbers only).' : ''}
                  />

                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
    </>,
    isComplete: () => (servingSizeLabels?.[0]?.length > 0 ) && (servingSizeQuantities?.[0]?.length > 0) && (servingSizeErrorStates === undefined || servingSizeErrorStates.filter(a => a === true).length === 0)
  }]
  if(countryId === '840') {
    steps.push({
      label: 'Water & Ice',
      content: <>
        <Typography sx={{fontStyle:'italic'}}>Select the sodium levels in your water supply, as well as the type and amount of ice.</Typography>
        <br/>
        <InfoBlock>Sodium: Enter value only if known. Otherwise, leave value as pre-populated.</InfoBlock>
        <br/>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item size={3} sx={{textAlign: 'right', padding: '.33em 1em'}}>
              Sodium Content in your water supply:
            </Grid>
            <Grid item size={9}>
              <TextField
                size="small"
                id="sodium"
                value={sodium}
                onChange={(e) => { setSodium(e.target.value) }}
                variant="outlined"
                sx={{ width: '7em' }}
                slotProps={{
                  input: {
                    endAdornment: <InputAdornment position="end">mg</InputAdornment>,
                  },
                }}
              /> 
            </Grid>
          </Grid>
        </Box>
        <br/>
        <br/>
        <Box sx={{ flexGrow: 1 }}>
          <InfoBlock><Typography sx={{fontWeight: 700}}>Ice Fill Setting per FDA Menu Labeling Guidance:</Typography>
            <ul>
              <li>Nutrition calculator default is "No Ice"</li>
              <li>Self-serve beverages sold from a fountain or beverage dispenser should use "No Ice" setting.</li>
              <li>Crew-served beverages with a standard ice fill may select the ice fill setting for their standard fill.</li>
          </ul>
          <Typography sx={{fontSize: '9pt'}}>* If your crew serves beverages with a standard, consistent measurement of ice, then use "Hard Ice" for ice cubes, or "Soft Ice" for shavings or small pellets.</Typography>
          </InfoBlock>
          <Grid container spacing={2}>
            <Grid item size={3} sx={{textAlign: 'right', padding: '.33em 1em'}}>
              Ice Type: 
            </Grid>
            <Grid item size={9}>
              <Select size="small" value={iceType} onChange={(event) => { setIceFill(event.target.value === 'none' ? '1' : undefined); setIceType(event.target.value)}}>
                <MenuItem value="hard">Hard Ice</MenuItem>
                <MenuItem value="soft">Soft Ice</MenuItem>              
                <MenuItem value="none">No Ice</MenuItem>
              </Select>
            </Grid>
          </Grid>
          {(iceType === 'soft' || iceType === 'hard') &&
          <>
            <br/>
            <br/>
            <Grid container spacing={2}>
              <Grid item size={3} sx={{textAlign: 'right', padding: '.33em 1em'}}>
                Ice Fill Ratio:
              </Grid>
              <Grid item size={9}>
                {iceType === 'hard' && <Select size="small" value={iceFill} onChange={(event) => { setIceFill(event.target.value)}}>
                  <MenuItem value="2">1/4</MenuItem>              
                  <MenuItem value="3">1/3</MenuItem>              
                  <MenuItem value="4">1/2</MenuItem>
                  <MenuItem value="5">2/3</MenuItem>
                  <MenuItem value="6">3/4</MenuItem>
                  <MenuItem value="7">Full</MenuItem>
                </Select>}
                {iceType === 'soft' && <Select size="small" value={iceFill} onChange={(event) => { setIceFill(event.target.value)}}>
                  <MenuItem value="8">1/4</MenuItem>              
                  <MenuItem value="9">1/3</MenuItem>              
                  <MenuItem value="10">1/2</MenuItem>
                  <MenuItem value="11">2/3</MenuItem>
                  <MenuItem value="12">3/4</MenuItem>
                  <MenuItem value="13">Full</MenuItem>
                </Select>}
              </Grid>
            </Grid>
          </>}
          <br/>
          <br/>
        </Box>
      </>,
      isComplete: () => (iceFill?.length > 0 ) && (sodium?.length > 0)
    })
  }
  steps.push({
    label: 'Review',
    content: <>
      <Typography sx={{fontStyle:'italic', padding: '.5em 0'}}>Let's review your selections!</Typography>
      <Box sx={{
        padding: 2,
        backgroundColor: '#EEE',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        boxShadow: '2px 4px 6px rgba(0, 0, 0, 0.3)'
      }}>
        <Grid container>
          <Grid item size={3} sx={{textAlign: 'right', paddingRight: '1em'}}>
            <Typography>Country:</Typography>
          </Grid>
          <Grid item size={9}>
            <Typography>{ countryId === '124' ? <><CanadianFlagIcon/> Canada</> : <><AmericanFlagIcon /> United States</>}</Typography>
          </Grid>
          
          <Grid item size={3} sx={{textAlign: 'right', paddingRight: '1em'}}>
            <Typography>Selected Products:</Typography>
          </Grid>
          <Grid item size={9}>
            {selectedProductValues}
          </Grid>        

          <Grid item size={3} sx={{textAlign: 'right', paddingRight: '1em'}}>
            <Typography>Serving Sizes:</Typography>
          </Grid>
          <Grid item size={9}>
            {servingSizeLabels?.reduce((acc, label, index) => {
              if(label) {
                acc.push(`${label} (${servingSizeQuantities[index]} ${('' + servingSizeUnit).replaceAll('-', ' ')})`)
              }
              return acc
            }, []).join(', ')}
          </Grid>

          <Grid item size={3} sx={{textAlign: 'right', paddingRight: '1em'}}>
            <Typography>Sodium:</Typography>
          </Grid>
          <Grid item size={9}>
            {sodium} mg
          </Grid>

          <Grid item size={3} sx={{textAlign: 'right', paddingRight: '1em'}}>
            <Typography>Ice Type:</Typography>
          </Grid>
          <Grid item size={9}>
            {iceType === 'hard' ? 'Hard Ice' : iceType === 'soft' ? 'Soft Ice' : 'No Ice'}
          </Grid>

          {+iceFill !== 1 &&
            <>
              <Grid item size={3} sx={{textAlign: 'right', paddingRight: '1em'}}>
                <Typography>Ice Fill Ratio:</Typography>
              </Grid>
              <Grid item size={9}>
                {['1/4','1/3','1/2','2/3','3/4','Full'][(+iceFill - 2) % 6]}
              </Grid>
            </>
          }
        </Grid>
      </Box>
      <Typography sx={{ padding: '2em 0'}}>If everything looks correct, click next to calculate the nutrition facts using these settings.  Otherwise, click the Back button or directly on the step in the workflow above to correct your selections.</Typography>
    </>,
    isComplete: () => true
  })
  steps.push({
    label: 'Calculate',
    content: <>
      <Box className="shadow" sx={{backgroundColor: '#efefef', borderRadius: '.5em', padding: '1em', margin: '1em 1em 2em 1em'}}>
        <Typography sx={{fontWeight: 700}}>Disclaimer:</Typography>
        {getDisclaimer(countryId)}
        <Typography sx={{display: 'flex', justifyContent: 'flex-end'}}>{dayjs().format('MM/DD/YYYY')}</Typography>
        
      </Box>
      <Typography>Sodium Variable Input: {sodium} mg</Typography>
      <Typography>Ice Displacement Factor: {iceDisplacementFactors[iceFill]?.displacementFactor}</Typography>
      <Typography>Ice Fill: {iceDisplacementFactors[iceFill]?.label}</Typography>
      <Box sx={{display:'flex', width: '100%', justifyContent: 'flex-end'}}>
        <Typography sx={{position:'relative', top: '5px'}}>{useRoundedValues ? 'Rounded' : 'Unrounded'}</Typography><Switch checked={useRoundedValues} onChange={handleUseRoundedValuesChange} />
        <Button sx={{margin: '0 0 .5em 2em'}} variant="contained" onClick={download}><DownloadIcon/> Download</Button>
      </Box>
      {(progress < 100) && <Box sx={{zIndex:99999, position: 'absolute', top:'400px', left: '50%', transform: 'translate(-50%,-50%)', width: '70%'  }}>
        <LinearProgress sx={{height: '20px', borderRadius: '4px' }} variant="determinate" value={progress} />
        <Typography sx={{ color: '#FFF', fontSize: '1.2rem', position: 'absolute', left: '50%', top: '8px', transform: 'translate(-50%,-50%)'}}>{Math.round(progress)}%</Typography>
      </Box>}
      <ListNutritionCalculations allNutrientsShown={allNutrientsShown} numberFormat={useRoundedValues ? undefined : new DecimalFormat('0.#####')} data={useRoundedValues ? roundedNutritionCalculations : nutritionCalculations}/>
    </>,
    isComplete: () => true
  });

  const handleNext = () => {
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((currentActiveStep) => currentActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    setCompleted({
      ...completed,
      [activeStep]: true,
    });
    handleNext();
  };

  return (
    <>
      <section style={{ padding: '1em 1em .5em', position: 'fixed', width: 'calc(100% - 270px - .5em)', backgroundColor: 'rgb(244, 246, 249, .9)', border: '1px solid #999', borderRadius: '0 0 .25em .25em', borderTop: 0, zIndex: 9000 }}>
        <h1 style={{textAlign: 'center', paddingBottom: '1em'}}>The <img  src={ CocaColaLogo } alt="Coca Cola"/> Nutrition Calculator</h1>
        <Stepper activeStep={activeStep}>
          {steps.map((step, index) => {
            const isStepClickable = completed[index] || index <= activeStep;
            let sx = {
              cursor: isStepClickable ? 'pointer' : 'default',
              '& .MuiStepLabel-root': {
                cursor: isStepClickable ? 'pointer' : 'default',
                color: isStepClickable ? 'inherit' : 'text.disabled',
              },
              '& .MuiStepLabel-label': {
                cursor: isStepClickable ? 'pointer' : 'default',
                color: isStepClickable ? 'inherit' : 'text.disabled',
              },
              '&.MuiStepButton-root': activeStep === index ? {
                border: '1px solid #999',
                borderRadius: '.5em',
                padding: '.5em 1em',
                backgroundColor: '#FFF',
              } : {},
            };
            return <Step key={step.label} completed={completed[index]}>
              <StepButton sx={sx} onClick={handleStep(index)} disabled={!(completed[index] || index <= activeStep)}>{step.label}</StepButton>
            </Step>
          })}
        </Stepper>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button variant="contained" hidden={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
            <NavigateBeforeIcon /> Back 
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          {activeStep === 0 && countryId && <BlinkingBox>
            <ForwardIcon sx={{color: '#1976d2'}} fontSize="large" />
          </BlinkingBox>}
          <Button variant="contained" onClick={handleComplete} hidden={activeStep === steps.length-1} disabled={!steps[activeStep].isComplete()} sx={{ mr: 1 }}>
            Next <NavigateNextIcon />
          </Button>
        </Box>
      </section>
    
      <Box sx={{ padding: '9.5em 1em 1em', width: '100%' }}>
        <div style={{padding: '1em'}}>

          <Paper sx={{padding: '1em'}}>
            <h2>{steps[activeStep].label}</h2>
            {steps[activeStep].content}
          </Paper>
        </div>
        {/* <Button onClick={() => { console.log('selectedProducts state: ', selectedProducts)}}>Check Product State</Button> */}
      </Box>
    </>
  );
}

const globalStyles = (
  <GlobalStyles
    // styles={{
    //   ".FormControl-Label": {
    //     padding: '0em !important'
    //   },
   
    //   ".MuiSelect-select": {
    //     padding: '4px  24px 4px 8px !important',
    //     fontSize: '12px'
    //   },
    // }}
  />
)

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#FFF', // Change the text color of the selected tab
          backgroundColor: '#d73925', // Change the background color of the selected tab
          border: '1px solid #FFF',
          borderRadius: '4px 4px 0 0',
          borderBottomWidth: 4,
          borderBottomColor: '#FFF',
          '&.Mui-selected': {
            color: '#FFF', // Change the text color of the selected tab
            backgroundColor: '#d73925', // Change the background color of the selected tab
            border: '1px solid #FFF',
            borderRadius: '4px 4px 0 0',
            borderBottomColor: '#FF0000',
            padding: '8px',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#FFF',
          height: 0
        }
      }
    }
  },
});


export default function NutritionCalculatorFormPage(props = {}) {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <ThemeProvider theme={theme}>
      {globalStyles}
      <Page {...props} isLoading={isLoading}>
        <HorizontalStepperWithError setIsLoading={setIsLoading}/>
      </Page>
    </ThemeProvider>
  )
}