import { default as React, useState } from 'react'
import { Restaurant as RestaurantIcon } from '@mui/icons-material'
import ListGenesisFormulas from './ListGenesisFormulas'
import ListViewCommandBar from '../commandBar/ListViewCommandBar'
import Page from '../layout/Page'
import { Paper } from '@mui/material';


export default function ListGenesisFormulasPage (props = {}) {
  const [isLoading, setIsLoading] = useState(true)
  const breadcrumb = <span><RestaurantIcon/> Genesis Recipies</span>

  const [showFilters, setShowFilters] = useState(false)
  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  return (
    <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading}
      commandBar={
        <ListViewCommandBar 
          showFilters={true}
          toggleFilters={toggleFilters} 
          showAdd={false}              
        />
      }>
      <Paper>
        <ListGenesisFormulas showFilters={showFilters} setIsLoading={setIsLoading} />
      </Paper>
    </Page>
  )
}