import React, { useState, useCallback, useMemo, memo } from 'react';
import { FormGroup, FormControl, Grid2 as Grid, TextField, Typography } from '@mui/material';
import Checkbox from './Checkbox2';
import Select from './Select2';

const initializeStates = (items = [], dispensingHardwareTypeId) => {
  const acc = { checkboxes: {}, selects: {} };
  if(items?.length) {
    items.forEach(item => {
      acc.checkboxes[`product_${dispensingHardwareTypeId}_${item.id}`] = false;
      acc.selects[`product_${dispensingHardwareTypeId}_${item.id}_ratio`] = {
        disabled: true,
        id: item.id,
        value: item.ratios?.[0] ?? '',
        ratios: item.ratios ?? [],
        name: item.name
      };
      if (item.children) {
        const childAcc = initializeStates(item.children, dispensingHardwareTypeId);
        acc.checkboxes = { ...acc.checkboxes, ...childAcc.checkboxes };
        acc.selects = { ...acc.selects, ...childAcc.selects };
      }
    });
    acc.checkboxes[`product_${dispensingHardwareTypeId}_selectAll`] = false;
  }
  return acc;
};

const ProductsCheckboxList = (props) => {
  const { items, dispensingHardwareTypeId, onChange } = props;

  const initialStates = initializeStates(items, dispensingHardwareTypeId);
  const [states, setStates] = useState(initialStates);
  const [ratioOverride, setRatioOverride] = useState("");
  const [selectedRatio, setSelectedRatio] = useState('low');

  const handleOnChange = useCallback((event, item) => {
    const { id, checked } = event.target;
    setStates(prevStates => {
      const newStates = { ...prevStates };
      newStates.checkboxes[id] = checked;

      let parentSelect = newStates.selects[`${id}_ratio`]
      if (parentSelect) {
        parentSelect.disabled = !checked;
        if(parentSelect.ratios.length > 0) {
          parentSelect.value = parentSelect.ratios[getRatioIndex(parentSelect, selectedRatio)] ?? ''
        }        
      }

      if (item.children) {
        item.children.forEach(child => {
          const childId = `product_${dispensingHardwareTypeId}_${child.id}`;
          newStates.checkboxes[childId] = checked;
          let childSelect = newStates.selects[`${childId}_ratio`]
          if(childSelect) {
            childSelect.disabled = !checked;
            if(childSelect.ratios.length > 0) {
              childSelect.value = childSelect.ratios[getRatioIndex(childSelect, selectedRatio)] ?? ''
            }
          }

          if (child.children) {
            newStates.checkboxes = { ...newStates.checkboxes, ...initializeStates(child.children, dispensingHardwareTypeId).checkboxes };
            newStates.selects = { ...newStates.selects, ...initializeStates(child.children, dispensingHardwareTypeId).selects };
          }
        });
      }
      if(onChange) {
        onChange({target: {value: newStates, dispensingHardwareTypeId}})
      }
      return newStates;
    });
  }, [dispensingHardwareTypeId, selectedRatio]);

  const handleOnRatioOverrideChange = (event) => {
    const { value } = event.target;
    setRatioOverride(value)
    let hasOverride = ('' + value).trim() !== ''
    handleGlobalRatioChange({ target : { value: hasOverride? value : selectedRatio, hasOverride}})
    
  }


  const handleOnRatioChange = useCallback((event) => {
    const { id, value } = event.target;
    setStates(prevStates => {
      const newSelects = { ...prevStates.selects };
      newSelects[id].value = value;
      const newStates = { ...prevStates, selects: newSelects };
      
      if(onChange) {
        onChange({target: {value: newStates, dispensingHardwareTypeId}})
      }
      return newStates
    });
  }, []);

  const handleGlobalRatioChange = useCallback((event) => {
    let { value, hasOverride = isRatioOverridden() } = event.target
    if(!hasOverride) {
      setSelectedRatio(value);
    }
    
    setStates(prevStates => {
      const newSelects = { ...prevStates.selects };
      Object.keys(newSelects).forEach(key => {
        if(hasOverride) {
          newSelects[key].value = value
        } else {
          const index = value === 'low' ? 0 : value === 'med' ? 1 :2;
          if (!newSelects[key].disabled) {
            newSelects[key].value = (newSelects[key]?.ratios[Math.min(index, newSelects[key].ratios.length - 1)] ?? '');
          }
        }
       });
      const newStates = { ...prevStates, selects: newSelects };
      if(onChange) {
        onChange({target: {value: newStates, dispensingHardwareTypeId}})
      }
      return newStates
    });
  }, [ratioOverride])

  const handleSelectAllChange = useCallback((event) => {
    // console.log('entered handleSelectAllChange, event: ', event)
    const { checked } = event.target;

    setStates(prevStates => {
      const newStates = { ...prevStates };
      Object.keys(newStates.checkboxes).forEach(key => {
        newStates.checkboxes[key] = checked;
      });

      Object.keys(newStates.selects).forEach(key => {
        newStates.selects[key].disabled = !checked;
      });
      if(onChange) {
        let outboundEvent = {target: {value: newStates, dispensingHardwareTypeId}}
        // console.log('outboundEvent: ', outboundEvent)
        onChange(outboundEvent)
      }
      return newStates;
    });
  }, [dispensingHardwareTypeId]);

  const getRatioIndex = (el, selectedRatioValue) => { 
    const numRatios = el.ratios?.length ?? 0
    // console.log('selectedRatio: ', selectedRatioValue)
    switch(selectedRatioValue) {
      case 'high':
        return numRatios - 1;
      case 'med':
        return numRatios > 1 ? 1 : 0
      case 'low':
        return 0
    }
  }

  const isRatioOverridden = () => ('' + ratioOverride).trim().length > 0
  return (
    <>
    {items && items.length > 0 &&
    
    <FormControl component="fieldset" sx={{ width: '100%' }}>
      {/* <Button onClick={() => { console.log('states: ', states) }}>log state</Button> */}
      <FormGroup>
        <Grid container spacing={0.5} key={dispensingHardwareTypeId}>
          <Grid item size={9}>
            Provide a ratio override if you use a custom syrup ratio:<br/>
            {isRatioOverridden() && <Typography sx={{fontSize:'9pt'}}>(Delete the value to revert to individual ratios)</Typography>}
          </Grid>
          <Grid item size={3}>
            <FormControl>
              <TextField
                id="ratioOverride"
                size="small"
                value={ ratioOverride }
                placeholder="Ratio Override"
                onChange={ handleOnRatioOverrideChange }
              />
            </FormControl>
          </Grid>
          <Grid item size={9}>
            <Checkbox
              id={`product_${dispensingHardwareTypeId}_selectAll`}
              label="Select All"
              checked={states.checkboxes[`product_${dispensingHardwareTypeId}_selectAll`] || false}
              onChange={handleSelectAllChange}
            />
          </Grid>
          <Grid item size={3}>
            <>
              <FormControl fullWidth>
                <Select
                  onChange={handleGlobalRatioChange}
                  size="small"
                  defaultValue=""
                  disabled={isRatioOverridden() || !states.checkboxes || !Object.keys(states.checkboxes).some(key => states.checkboxes[key])}
                  placeholder="Ratios"
                  value={selectedRatio}
                >
                  <option value="low">Low</option>
                  <option value="med">Med</option>
                  <option value="high">High</option>
                </Select>
              </FormControl>
            </>
          </Grid>
          {items.map(item => (
            <React.Fragment key={item.id}>
              <Grid item size={9}>
                <Checkbox
                  id={`product_${dispensingHardwareTypeId}_${item.id}`}
                  label={item.name}
                  checked={states.checkboxes[`product_${dispensingHardwareTypeId}_${item.id}`] || false}
                  onChange={(event) => handleOnChange(event, item)}
                />
              </Grid>
              <Grid item size={3}>
                {item.ratios?.length > 0 && (
                  <FormControl fullWidth>
                    <Select
                      id={`product_${dispensingHardwareTypeId}_${item.id}_ratio`}
                      value={isRatioOverridden() ? ratioOverride : (states.selects[`product_${dispensingHardwareTypeId}_${item.id}_ratio`]?.value)}
                      placeholder="Ratios"
                      disabled={isRatioOverridden() || (states.selects[`product_${dispensingHardwareTypeId}_${item.id}_ratio`]?.disabled ?? true)}
                      onChange={handleOnRatioChange}
                    >
                      {isRatioOverridden() ? <option value={ratioOverride}>{ratioOverride}</option> :
                      item.ratios.map(ratio => (
                        <option key={ratio} value={ratio}>{ratio}</option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              {item.children?.length && item.children.map(child => (
                <React.Fragment key={child.id}>
                  <Grid item size={1} sx={{ textAlign: 'right', paddingRight: '2em' }}>-</Grid>
                  <Grid item size={8}>
                    <Checkbox
                      id={`product_${dispensingHardwareTypeId}_${child.id}`}
                      label={child.name}
                      checked={states.checkboxes[`product_${dispensingHardwareTypeId}_${child.id}`] || false}
                      onChange={(event) => handleOnChange(event, child)}
                    />
                  </Grid>
                  <Grid item size={3}>
                    {child.ratios?.length > 0 && (
                      <FormControl fullWidth>
                        <Select
                          id={`product_${dispensingHardwareTypeId}_${child.id}_ratio`}
                          value={isRatioOverridden() ? ratioOverride : (states.selects[`product_${dispensingHardwareTypeId}_${child.id}_ratio`]?.value)}
                          placeholder="Ratios"
                          disabled={isRatioOverridden() || (states.selects[`product_${dispensingHardwareTypeId}_${child.id}_ratio`]?.disabled ?? true)}
                          onChange={handleOnRatioChange}
                        >
                          {isRatioOverridden() ? <option value={ratioOverride}>{ratioOverride}</option> :
                          child.ratios.map(ratio => (
                            <option key={ratio} value={ratio}>{ratio}</option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </Grid>
      </FormGroup>
    </FormControl>}
    </>
  );
};

// const MemoizedCheckbox = memo(Checkbox);
// const MemoizedSelect = memo(Select);

export default ProductsCheckboxList;