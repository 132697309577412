import { useContext } from 'react'
import Menu from './Menu'
import { Article as ArticleIcon, Calculate as CalculateIcon, People as PeopleIcon, Science as ScienceIcon, Restaurant as RestaurantIcon } from '@mui/icons-material'
import MenuItem from './MenuItem'
import MenuDivider from './MenuDivider'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import UserContext from '../auth/UserContext'

export default function SidebarMenu(props = {}) {
  window.scrollTo(0, 0)
  const roleDetails = useContext(RoleContext)
  const userDetails = useContext(UserContext)

  return (
    <Menu>
      <MenuItem href="/products" title="Products" icon={<ScienceIcon />} />
      <MenuItem href="/label-declaration-sheets" title="Label Declaration Sheets" icon={<ArticleIcon />} />
      <MenuItem href="/nutrition-calculator" title="Nutrition Calculator" icon={<CalculateIcon />} />
      <MenuItem href="/genesis" title="Genesis" icon={<RestaurantIcon />} />
      {roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
        <>
          <MenuDivider />
          <MenuItem href="/users/list/" title="User Management" icon={<PeopleIcon />} />
        </>
      }
    </Menu>
  )
}
