export default {
    av: {
      textAlign: 'left',
      fontSize: '12px',
      fontFamily: 'inherit',
      verticalAlign: 'top',
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      height: '1.1em'
    },
    bold: {
      fontWeight: 900
    },
    calories: {
      height: '4em',
      width: '100%',
      verticalAlign: 'bottom',
      position: 'relative'
    },
    caloriesDiv: {
      textAlign: 'right',
      fontWeight: 'bold'
    },
    caloriesH2: {
      position: 'absolute',
      bottom: 0,
      textAlign: 'right'
    },
    clear: {
      clear: 'both'
    },
    dv: {
      textAlign: 'right', 
      height: '1.1em'
    },
    nutritionFactPanel: {
      backgroundColor: '#ffffff',
      paddingTop: '2px',
      paddingRight: '6px',
      paddingBottom: '2px',
      paddingLeft: '6px', 
      marginTop: 0,
      marginRight: 'auto',
      marginBottom: 0,
      marginLeft: 'auto',
      minWidth: '342px',
      maxWidth: '100%',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
      color: '#000000',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#000000',
      fontSize: '13px',
      letterSpacing: '.75px',
      lineHeight: '13.2px',
      position: 'relative'
    },
    colXs1: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colXs2: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colXs3: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colXs4: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colXs5: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left',
      width: '41.67%'
    },
    colXs6: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left',
      width: '50%'
    },
    colXs7: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left',
      width: '58.33%'
    },
    colXs8: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colXs9: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colXs10: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colXs11: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colXs12: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colSm1: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colSm2: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colSm3: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colSm4: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colSm5: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colSm6: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left',
      width: '50%'
    },
    colSm7: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colSm8: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colSm9: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colSm10: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colSm11: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colSm12: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colMd1: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    }, 
    colMd2: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colMd3: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colMd4: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colMd5: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colMd6: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left',
      width: '50%'
    },
    colMd7: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colMd8: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colMd9: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colMd10: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    }, 
    colMd11: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colMd12: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colLg1: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    }, 
    colLg2: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    }, 
    colLg3: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colLg4: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colLg5: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colLg6: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left',
      width: '50%'
    },
    colLg7: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colLg8: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colLg9: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },
    colLg10: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    }, 
    colLg11: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    }, 
    colLg12: {
      position: 'relative',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      float: 'left'
    },  
    column: {
      paddingTop: '2px',
      paddingRight: '4px',
      paddingBottom: '2px',
      paddingLeft: '4px',
      verticalAlign: 'top'
    },
    columns: {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'space-between'
    },
    fs1: {
      fontSize: '40px',
      fontFamily: 'inherit',
      verticalAlign: 'top', 
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0
    },
    fs2: {
      fontSize: '28px',
      fontFamily: 'inherit',
      verticalAlign: 'top', 
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0
    },
    fs3: {
      fontSize: '20px',
      fontFamily: 'inherit',
      verticalAlign: 'top', 
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0
    },
    fs4: {
      fontSize: '14px',
      fontFamily: 'inherit',
      verticalAlign: 'top', 
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0
    },
    fs5: {
      fontSize: '12px',
      fontFamily: 'inherit',
      verticalAlign: 'top', 
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0
    },
    fs6: {
      fontSize: '8px',
      fontFamily: 'inherit',
      verticalAlign: 'top', 
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0
    },          
    h1: {
      
      fontSize: '40px',
      fontWeight: 900,
      lineHeight: 1.1,
      fontFamily: 'inherit',
      verticalAlign: 'top',
      color: '#000000',
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      letterSpacing: 'normal',
      textAlign: 'left',
      width: '100%'
    },
    h2: {
      fontSize: '28px',
      fontWeight: 900,
      fontFamily: 'inherit',
      lineHeight: 1.1,
      verticalAlign: 'top',
      color: '#000000',
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      letterSpacing: 'normal',
      textAlign: 'left',
      width: '100%'
    },
    h3: {
      fontSize: '20px',
      fontWeight: 900,
      fontFamily: 'inherit',
      lineHeight: 1.1,
      verticalAlign: 'top',
      color: '#000000',
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      letterSpacing: 'normal',
      textAlign: 'left',
      width: '100%'
    },
    h4: {
      fontSize: '14px',
      fontWeight: 900,
      fontFamily: 'inherit',
      lineHeight: 1.1,
      verticalAlign: 'top',
      color: '#000000',
      marginTop: '0.5em',
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      letterSpacing: 'normal',
      textAlign: 'left',
      width: '100%'
    },
    h5: {
      fontSize: '12px',
      fontWeight: 900,
      fontFamily: 'inherit',
      lineHeight: 1.1,
      verticalAlign: 'top',
      color: '#000000',
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      letterSpacing: 'normal',
      textAlign: 'left',
      width: '100%'
    },
    h6: {
      fontSize: '8px',
      fontWeight: 900,
      fontFamily: 'inherit',
      lineHeight: 1.1,
      verticalAlign: 'top',
      color: '#000000',
      marginTop: '2px',
      marginRight: 0,
      marginBottom: '2px',
      marginLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      letterSpacing: 'normal',
      textAlign: 'left',
      width: '100%'
    },
    h7: {
      fontSize: '7px',
      fontWeight: 900,
      fontFamily: 'inherit',
      lineHeight: 1.1,
      verticalAlign: 'top',
      color: '#000000',
      marginTop: '2px',
      marginRight: 0,
      marginBottom: '1px',
      marginLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      letterSpacing: 'normal',
      textAlign: 'left',
      width: '100%'
    },
    hr: {
      backgroundColor: '#000000',
      width: '100%',
      marginTop: '2px',
      marginRight: '0px',
      marginBottom: '2px',
      marginLeft: '0px',
      paddingTop: '0px',
      paddingRight: '0px',
      paddingBottom: '0px',
      paddingLeft: '0px',
      border: 'none'
    },
    hr1: {
      height: '1px'
    },
    hr2: {
      height: '2px'
    },
    hr3: {
      height: '3px'
    },
    hr4: {
      height: '4px'
    },
    hr5: {
      height: '5px'
    },
    hr6: {
      height: '6px'
    },
    indent1: {
      paddingLeft: '10px'
    },
    indent1Child: {
      fontWeight: 'normal'
    },
    m0: {
      marginTop: '0px',
      marginRight: '0px',
      marginBottom: '0px',
      marginLeft: '0px'
    },
    mb0: {
      marginBottom: '0px'
    },  
    mt0: {
      marginTop: '0px'
    },
    p0: {
      paddingTop: '0px',
      paddingRight: '0px',
      paddingBottom: '0px',
      paddingLeft: '0px'
    },
    row: {
      height: '13.1875px',
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
      '&::before': {
        display: 'table',
        content: `" "`,
        boxSizing: 'border-box'
      },
      '&::after': {
        display: 'table',
        content: `" "`,
        clear: 'both',
        boxSizing: 'border-box'
      }
    }
  }