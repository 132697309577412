import { default as React, useState } from 'react'
import { Science as ScienceIcon } from '@mui/icons-material'
import ListProducts from './ListProducts'
import ListViewCommandBar from '../commandBar/ListViewCommandBar'
import Page from '../layout/Page'
import { Paper } from '@mui/material';


export default function ListProductsPage (props = {}) {
  const [isLoading, setIsLoading] = useState(true)

  const breadcrumb = <span><ScienceIcon/> Products</span>

  const [showFilters, setShowFilters] = useState(false)
  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  return (
    <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading}
      commandBar={
        <ListViewCommandBar showFilters={true} toggleFilters={toggleFilters} showAdd={false} />
      }>
      <Paper>
        <ListProducts showFilters={showFilters} setIsLoading={setIsLoading} />
      </Paper>
    </Page>
  )
}