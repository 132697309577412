export const checkRequiredError = (value) => {
    //console.log(value)
    return (value === "" || (Array.isArray(value) && value.length === 0) || value===null)
  }
  
export const checkStrictRequiredError = (value, required) => {
  if (value === undefined) return true
  if (typeof value === 'string' && value === '') return true
  if (typeof value === 'number') return false // if it's a number it can be 0 and it's not undefined
    //return value === [] || value === null || value === undefined || value === '' || !value.length
    return !value
  }

export const checkEmptyArrayError = (value) => {
  return (!value || !value.length)
}
  
export const checkNumberError = (value) => {
    if (checkRequiredError(value))
      return "This field is required"
    else if (value < 0)
      return "Must be a non-negative number"
    return false
  }
  
export const checkYearError = (value, minYear=1900, maxYear=2200) => {
  if (checkRequiredError(value))
    return "This field is required"
  else if (value < minYear || value > maxYear)
    return "Must be a valid year (YYYY)"
  return false
}

export const checkPhoneError = (phone) => {
    if ((!phone || phone === "+1"))
      return false //phone number not required
    const re = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
    //if (matchIsValidTel(phone)) {
    if ( re.test(phone.trim())) {
      return false
    }
    return true
  }
  
export const checkDateError = (value) => {
    if (value==null){
      //console.log(value)
      return true
    }
    else if (value.$d){
      //console.log(value.$d)
      if (value.$d === "Invalid Date")
        return true
      return false
    }
    return true
  }
  
export const checkEmailError = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === undefined || email === ""){
      //console.log("empty field")
      return false
    }
    else if ( re.test(email) ) {
      //console.log("valid email")
      return false
    }
    else {
      //console.log("invalid email")
      return true
    }
  }
  

export const handleTextChange = (event, object, setter, setDirty=false) => {
    const value = event.target.value
    const id = event.target.id
    handleFieldChange(id, value, object, setter, setDirty)
  }

export const handleMaybeFieldChange = (id, event, object, setter, setDirty=false) => {
  const value = event.target.value
  console.log('event', event, 'object', object, 'id', id)
  if (value  !==  object[id]) {
    console.log(value, object[id])
    handleFieldChange(id, value, object, setter, setDirty)
  }
}

export const handleSelectChange = (id, event, object, setter, setDirty=false) => {
    const value = event.target.value
    handleFieldChange(id, value, object, setter, setDirty)
  }

  // const handleDateChange = (id, value, errorSetter) => {
  //   manufacturerProfile[id] = value
  //   setManufacturerProfile({...manufacturerProfile}, {[[id]]: value})
  //   if (errorSetter)
  //     setError(value, errorSetter, checkDateError)
  // }

  // const handlePhoneChange = (event, id) => {
  //   manufacturerProfile[id] = event
  //   setManufacturerProfile({...manufacturerProfile}, {[[id]]: event})
  // }

export const handleFieldChange = (id, value, object, setter, setDirty=false) => {
    const _object = { ...object }
    _object[id] = value
    setter(_object)
    //setter({...object}, {[[id]]: value})
    if (setDirty) { setDirty() }
  }

export const validateFieldChange = (id, value, object, setter, validator ) => {
  const _object = { ...object }
  _object[id] = value
  setter(_object)
  validator(_object)
}

export const handleAutoCompleteChange = (id, value, object, setter, setDirty=false) => {
    handleFieldChange(id, value, object, setter, setDirty)
  }