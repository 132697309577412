import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import React, { useRef, useState } from 'react'

export default function CollapsePanel(props = {}) {
    const myRef = useRef(null)
    const [expanded, setExpanded] = useState(props.defaultExpanded ?? true)
    const [initialized, setInitialized] = useState(props.defaultExpanded ?? true)
    const [icon, setIcon] = useState((props.defaultExpanded ?? true) ? <ExpandLessIcon/> : <ExpandMoreIcon/>)

    const toggle = () => {
        setInitialized(true)
        setIcon(expanded ? <ExpandMoreIcon/> : <ExpandLessIcon/>)
        setExpanded(!expanded)
    }
 
    let title = <Typography>{props.title}</Typography>

    return (
        <Card sx = {{width: '100%', margin: '1em 0', boxShadow: '2px 4px 6px rgba(0, 0, 0, 0.3)', borderRadius: '4px', border: '1px solid #ccc'}}>
            <CardHeader ref={myRef} title={title} onClick={toggle} action={icon} sx={{ cursor: 'pointer'}}/>
            <Collapse in={expanded} timeout="auto" unmountOnExit={false}>
            {initialized &&
                <CardContent className="content">
                    {props.children}
                </CardContent>
            }
            </Collapse>
        </Card>
    )
}