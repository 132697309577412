import { useState, useRef } from "react";
import { Button } from "@mui/material";
import { Grid2 as Grid } from "@mui/material";
import { ApiGateway } from '../config/config'
import { getToken } from '../auth/auth'
import axios from 'axios'

export default function UploadFile(props = {}) {
  const [_filename, setFilename] = useState("No file chosen");
  const [_csvData, setCsvData] = useState("");

  const fileInputRef = useRef(null);

  const reset = () => {
    setFilename("No file chosen")
    fileInputRef.current.value = ""
    setCsvData("")
  }

  const handleFileSelection = (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setFilename(name);

    const reader = new FileReader();
    reader.onload = (evt) => {
      if (!evt?.target?.result) {
        return;
      }
      const { result } = evt.target;
      setCsvData(result)
      onUpload(name, result)
    };
    reader.readAsBinaryString(file);

  };

  const onUpload = async (filename, csvData) => {
    const basePath = `${ApiGateway.user}/parse-csv`
    const { accessToken, idToken } = await getToken()
    try {
      props.startLoading()
      const res = await axios.post(basePath, {
        filename,
        csvData,
        accessToken
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        }
      })
      props.setResults(res.data)
    } catch (error) {
      console.error(error)
    } finally {
      props.stopLoading()
    }
    reset()
  }

  const onChooseFile = () => {
    fileInputRef.current.click()
  }

  return (
      <Grid item xs={12} md={12}>
        <Button variant="outlined" onClick={onChooseFile}>Upload User File</Button>
        <input ref={fileInputRef} style={{ display: 'none' }} type="file" accept={".csv"} onChange={handleFileSelection} />
      </Grid>
  );
}