import { styled } from '@mui/system';
import {Box, Tab as MuiTab, Tabs as MuiTabs} from '@mui/material'

export const Tabs = styled(MuiTabs)({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

// Styled Tab
export const Tab = styled((props) => <MuiTab disableRipple {...props} />)(
  {
    textTransform: 'none',
    fontWeight: 400,
    fontSize: '15px',
    marginRight: '8px',
    color: 'rgba(0, 0, 0, 0.7)',
    '&.MuiTab-root': {
      color: '#999',
      backgroundColor: '#EFEFEF',
      margin: 0,
    },
    '&.MuiTab-root:first-of-type': {
      marginLeft: '0 !important'
    },
    '&.Mui-selected': {
      color: '#000', // Text color
      margin: '0 3px',
      border: '1px solid #ccc',
      borderBottom: 'none', // Prevent border overlap
      backgroundColor: '#fff',
      borderRadius: '4px 4px 0px 0px',
      position: 'relative', // Ensuring overlap
      zIndex: 10, // Higher z-index than the tab panel
      top: '1px', // To overlap the parent border
    },
  },
);

// TabPanel Component
export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{
        border: '1px solid #ccc',
        borderRadius: '0px 4px 4px 4px', // Keep the top border for the tab panel
        bgcolor: '#fff',
        color: '#333',
        position: 'relative', // Ensure correct stacking
        zIndex: 5, // Lower z-index than the selected tab
        marginTop: '-1px',  // Ensure no gap between tab and panel
      }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}