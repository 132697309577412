import React from "react";
import css from '../NfpStyles'
import { Box, Divider, Grid2 as Grid, Typography } from '@mui/material'
import { isEmpty } from '../../../utils/utils'

let css2 = {
  nutritionFactPanelDualColumn: {
    minWidth: '342px',
    maxWidth: '400px'
  },
  caloriesH3: {
    position: 'absolute', 
    bottom: 0,
    textAlign: 'left'
  },
  column1: {
    flexBasis: '46%',
    paddingLeft: 0
  },
  column2: { 
    flexBasis: '27%', 
    borderLeft: '1px solid #000000',
    borderRight: '1px solid #000000'
  },
  column3: {
    flexBasis: '27%',
    paddingRight: 0
  }
}

export default function NfpDualColumnView({nfp1 = {}, nfp2 = {}, simplified, serve, abbreviated, nutrientList, significantSourceText}) {
  let i = 1
  const showSaturatedFat = parseFloat(nfp1.saturatedFatPercent) >= 2
  const showTransFat = parseFloat(nfp1.transFatPercent) >= 2
  const showCholesterol = parseFloat(nfp1.cholesterolPercent) >= 2
  const showDietaryFiber = parseFloat(nfp1.dietaryFiberPercent) >= 2
  const showTotalSugars = parseFloat(nfp1.totalSugarsPercent) >= 2
  const showAddedSugars = parseFloat(nfp1.addedSugarsPercent) >= 2
  const showVitaminD = parseFloat(nfp1.vitaminDMcgPercent) >= 2
  const showCalcium = parseFloat(nfp1.calciumPercent) >= 2
  const showIron = parseFloat(nfp1.ironPercent) >= 2
  const showPotassium = parseFloat(nfp1.potassiumPercent) >= 2

  return (
    <Box id="nutrition-fact-panel-dual-column" sx={{ ...css.nutritionFactPanel, ...css2.nutritionFactPanelDualColumn }}>
      <Typography variant="h1" sx={css.h1}>Nutrition Facts</Typography>
      <Divider sx={{...css.hr, ...css.hr1, ...css.mt0}}/>
      {serve === 'multi' &&
        <Box component="span">{nfp1.servingsPerContainer} {'' + nfp1.servingsPerContainer === '1' ? 'serving' : 'servings'} per container</Box>
      }
      <Grid container>
        <Grid item size={6}><h4 style={css.h4}>{abbreviated ? 'Serv.' : 'Serving'} Size</h4></Grid>
        <Grid item size={6}><h4 style={Object.assign({}, css.h4, css.dv)}>{nfp1.servingSize}</h4></Grid>
      </Grid>
      <Divider sx={{...css.hr, ...css.hr6, ...css.mb0}}/>
      <Grid container>
        <Grid item size={6}>
          <Box sx={{marginRight: '.25em'}}>
            <Box style={css.calories}>
            <Typography variant="h5" sx={{ ...css.h3, ...css2.caloriesH3 }}>Calories</Typography>
            </Box>
            <Divider sx={{...css.hr, ...css.hr4}}/>
            <Typography variant="h6" sx={{...css.h6, ...css.dv}}>&nbsp;</Typography>
            <Divider sx={{...css.hr, ...css.hr1}}/>
             <Typography variant="h5" sx={css.h5}>Total Fat</Typography>
            <Divider sx={{...css.hr, ...css.hr1}}/>
            {!simplified && 
            <>
              <Box sx={css.indent1}>
                {showSaturatedFat && <>
                   <Typography variant="h5" sx={{ ...css.h5, ...css.indent1Child }}>Saturated Fat</Typography>
                  <Divider sx={{...css.hr, ...css.hr1}}/>
                </>}
                {showTransFat && <>
                   <Typography variant="h5" sx={{ ...css.h5, ...css.indent1Child }}><i>Trans</i> Fat</Typography>
                  <Divider sx={{...css.hr, ...css.hr1}}/>
                </>}
              </Box>
              {showCholesterol && 
                <>
                  <Typography variant="h5" sx={css.h5}>Cholesterol</Typography>
                  <Divider sx={{...css.hr, ...css.hr1}}/>
                </>
              }
            </>}
             <Typography variant="h5" sx={css.h5}>Sodium</Typography>
            <Divider sx={{...css.hr, ...css.hr1}}/>
             <Typography variant="h5" sx={css.h5}>Total {abbreviated ? 'Carb.' : 'Carbohydrate'}</Typography>
            <Divider sx={{...css.hr, ...css.hr1}}/>
            <Box sx={css.indent1}>
              {showDietaryFiber &&  <>
                 <Typography variant="h5" sx={{...css.h5, ...css.indent1Child}}>Dietary Fiber</Typography>
                {showTotalSugars && <Divider sx={{...css.hr, ...css.hr1}}/>}
              </>}
              {showTotalSugars && <Typography variant="h5" sx={{...css.h5, ...css.indent1Child}}>Total Sugars</Typography>}
              {((showDietaryFiber || showTotalSugars) && showAddedSugars) && <Divider sx={{...css.hr, ...css.hr1}}/>}
              {showAddedSugars &&  <Typography variant="h5" sx={{...css.h5, ...css.indent1Child}}>Added Sugars</Typography>}
            </Box>
            {(showDietaryFiber || showTotalSugars || showAddedSugars) && <Divider sx={{...css.hr, ...css.hr1}}/>}
             <Typography variant="h5" sx={css.h5}>Protein</Typography>
            <Divider sx={{...css.hr, ...css.hr6}}/>
            {nutrientList.map((nutrient, idx, arr) => {
              return <Box key={`nutrient-name${idx}`}>
                 <Typography variant="h5" sx={{...css.h5, fontWeight: 'normal'}}>{nutrient.outputName}</Typography>
                 <Divider sx={(idx < arr.length - 1) ? { ...css.hr, ...css.hr1 } : { ...css.hr, ...css.hr4 }}/>
              </Box>
            })}  
          </Box>
        </Grid>
        <Grid item size={3}>
          <Box sx={{padding: '0 .25em', borderLeft: '1px solid #333', borderRight: '1px solid #333'}}>
            <Box sx={css.calories}>
            <Box sx={{ ...css.fs6, ...css.caloriesDiv }}>Per Serving</Box>
            <Typography variant="h2" sx={{...css.h2, ...css.caloriesH2 }}>{nfp1.calories}</Typography>
            </Box>
            <Divider sx={{...css.hr, ...css.hr4}}/>
            <Typography variant="h6" sx={{...css.h6, ...css.dv}}>%DV*</Typography>
            <Divider sx={{...css.hr, ...css.hr1}}/>
            <Grid container>
              <Grid item size={6}><Typography sx={css.av}>{nfp1.totalFat}g</Typography></Grid>
              <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>{nfp1.totalFatPercent}%</Typography></Grid>
            </Grid>
            <Divider sx={{...css.hr, ...css.hr1}}/>
            {!simplified && 
            <>
              {showSaturatedFat && <>
                <Grid container>
                  <Grid item size={6}><Typography sx={css.av}>{nfp1.saturatedFat}g</Typography></Grid>
                  <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>{nfp1.saturatedFatPercent}%</Typography></Grid>
                </Grid>
                <Divider sx={{...css.hr, ...css.hr1}}/>
              </>}
              {showTransFat && <>
                <Grid container>
                  <Grid item size={6}><Typography sx={css.av}>{nfp1.transFat}g</Typography></Grid>
                  <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>{nfp1.transFatPercent}%</Typography></Grid>
                </Grid>
                <Divider sx={{...css.hr, ...css.hr1}}/>
              </>}
              {showCholesterol &&
                <>
                  <Grid container>
                    <Grid item size={6}><Typography sx={css.av}>{nfp1.cholesterol}g</Typography></Grid>
                    <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>{nfp1.cholesterolPercent}%</Typography></Grid>
                  </Grid>
                  <Divider sx={{...css.hr, ...css.hr1}}/>
                </>}
            </>}
            
            <Grid container>
              <Grid item size={6}><Typography sx={css.av}>{nfp1.sodium}mg</Typography></Grid>
              <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>{nfp1.sodiumPercent}%</Typography></Grid>
            </Grid>
            <Divider sx={{...css.hr, ...css.hr1}}/>
            <Grid container>
              <Grid item size={6}><Typography sx={css.av}>{nfp1.totalCarb}g</Typography></Grid>
              <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>{nfp1.totalCarbPercent}%</Typography></Grid>
            </Grid>
            <Divider sx={{...css.hr, ...css.hr1}}/>
            {showDietaryFiber && 
            <>
              <Grid container>
                <Grid item size={6}><Typography sx={css.av}>{nfp1.dietaryFiber ? `${nfp1.dietaryFiber}g` : ''}</Typography></Grid>
                <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>{nfp1.dietaryFiberPercent ? `${nfp1.dietaryFiberPercent}%` : ''}</Typography></Grid>
              </Grid>
              {showTotalSugars && <Divider sx={{...css.hr, ...css.hr1}}/>}
            </>}
            {showTotalSugars && <Grid container>
              <Grid item size={6}><Typography sx={css.av}>{nfp1.totalSugars ? `${nfp1.totalSugars}g` : ''}</Typography></Grid>
              <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>&nbsp;</Typography></Grid>
            </Grid>}
            {(((showDietaryFiber) || showTotalSugars) && showAddedSugars) && <Divider sx={{...css.hr, ...css.hr1}}/>}
            {showAddedSugars && <Grid container>
              <Grid item size={6}><Typography sx={css.av}>{nfp1.addedSugars ? `${nfp1.addedSugars}g` : ''}</Typography></Grid>
              <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>{nfp1.addedSugarsPercent ? `${nfp1.addedSugarsPercent}%` : ''}</Typography></Grid>
            </Grid>}
            {((showDietaryFiber) || showTotalSugars || showAddedSugars) && <Divider sx={{...css.hr, ...css.hr1}}/>}
            <Grid container>
              <Grid item size={6}><Typography sx={css.av}>{nfp1.protein}g</Typography></Grid>
              <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>&nbsp;</Typography></Grid>
            </Grid>
            <Divider sx={{...css.hr, ...css.hr6}}/>   
            {nutrientList && nutrientList.length > 0 && <>
              <Grid container>
                {nutrientList.map((nutrient, idx, arr) => {
                  const item = (
                    <React.Fragment key={`nutrient1_${i}`}>
                      <Grid item size={6}>
                        <Box component="span" sx={{ ...css.av, ...css2.av, ...css2.columnNutrientAv }}>{nutrient.amount ? nutrient.amount + nutrient.uom : ' '}</Box>
                      </Grid>
                      <Grid item size={6} sx={{textAlign: 'right', fontWeight: 800}}>
                        <Box component="span" sx={{ ...css.dv, ...css2.dv, ...css2.columnNutrientDv }}>{nutrient.percent ? nutrient.percent + '%' : ' '}</Box>
                      </Grid>
                      {idx < arr.length - 1 && <Grid item size={12}><Divider sx={{ ...css.hr, ...css.hr1}} /></Grid>}
                    </React.Fragment>
                  )
                  i = i + 1
                  return item
                })} 
              </Grid>                                                          
              <Divider sx={{ ...css.hr, ...css.hr4 }} />
            </>}  
          </Box>
        </Grid>
        <Grid item size={3}>
          <Box sx={{marginLeft: '.25em'}}>
            <Box sx={css.calories}>
              <Box sx={{ ...css.fs6, ...css.caloriesDiv }}>Per Container</Box>
              <Typography variant="h2" sx={{...css.h2, ...css.caloriesH2 }}>{nfp2.calories}</Typography>
            </Box>
            <Divider sx={{...css.hr, ...css.hr4}}/>
            <Typography variant="h6" sx={{...css.h6, ...css.dv}}>% Daily Value</Typography>
            <Divider sx={{...css.hr, ...css.hr1}}/>
            <Grid container>
              <Grid item size={6}><Typography sx={css.av}>{nfp2.totalFat}g</Typography></Grid>
              <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>{nfp2.totalFatPercent}%</Typography></Grid>
            </Grid>
            <Divider sx={{...css.hr, ...css.hr1}}/>
            {!simplified && 
            <>
              {showSaturatedFat && <>
                <Grid container>
                  <Grid item size={6}><Typography sx={css.av}>{nfp2.saturatedFat}g</Typography></Grid>
                  <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>{nfp2.saturatedFatPercent}%</Typography></Grid>
                </Grid>
                <Divider sx={{...css.hr, ...css.hr1}}/>
              </>}
              {showTransFat && <>
                <Grid container>
                  <Grid item size={6}><Typography sx={css.av}>{nfp2.transFat}g</Typography></Grid>
                  <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>{nfp2.transFatPercent}%</Typography></Grid>
                </Grid>
                <Divider sx={{...css.hr, ...css.hr1}}/>
              </>}
              {showCholesterol && <>
                <Grid container>
                  <Grid item size={6}><Typography sx={css.av}>{nfp2.cholesterol}g</Typography></Grid>
                  <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>{nfp2.cholesterolPercent}%</Typography></Grid>
                </Grid>
                <Divider sx={{...css.hr, ...css.hr1}}/>          
              </>}
            </>}              
            <Grid container>
              <Grid item size={6}><Typography sx={css.av}>{nfp2.sodium}mg</Typography></Grid>
              <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>{nfp2.sodiumPercent}%</Typography></Grid>
            </Grid>
            <Divider sx={{...css.hr, ...css.hr1}}/>
            <Grid container>
              <Grid item size={6}><Typography sx={css.av}>{nfp2.totalCarb}g</Typography></Grid>
              <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>{nfp2.totalCarbPercent}%</Typography></Grid>
            </Grid>
            <Divider sx={{...css.hr, ...css.hr1}}/>
            {showDietaryFiber && 
            <>
              <Grid container>
                <Grid item size={6}><Typography sx={css.av}>{nfp2.dietaryFiber ? `${nfp2.dietaryFiber}g` : ''}</Typography></Grid>
                <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>{nfp2.dietaryFiberPercent ? `${nfp2.dietaryFiberPercent}%` : ''}</Typography></Grid>
              </Grid>
              {showTotalSugars && <Divider sx={{...css.hr, ...css.hr1}}/>}
            </>}
            {showTotalSugars && <Grid container>
              <Grid item size={6}><Typography sx={css.av}>{nfp2.totalSugars ? `${nfp2.totalSugars}g` : ''}</Typography></Grid>
              <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>&nbsp;</Typography></Grid>
            </Grid>}
            {(((showDietaryFiber) || showTotalSugars) && showAddedSugars) && <Divider sx={{...css.hr, ...css.hr1}}/>}
            {showAddedSugars &&<Grid container>
              <Grid item size={6}><Typography sx={css.av}>{nfp2.addedSugars ? `${nfp2.addedSugars}g` : ''}</Typography></Grid>
              <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>{nfp2.addedSugarsPercent ? `${nfp2.addedSugarsPercent}%` : ''}</Typography></Grid>
            </Grid>}
            {((showDietaryFiber) || showTotalSugars || showAddedSugars) && <Divider sx={{...css.hr, ...css.hr1}}/>}
            <Grid container>
              <Grid item size={6}><Typography sx={css.av}>{nfp2.protein}g</Typography></Grid>
              <Grid item size={6}><Typography variant="h5" sx={{...css.h5, ...css.dv}}>&nbsp;</Typography></Grid>
            </Grid>
            <Divider sx={{...css.hr, ...css.hr6}}/>

            {nutrientList && nutrientList.length > 0 && <>
              <Grid container>
                {nutrientList.map((nutrient, idx, arr) => {
                  const item = (
                    <React.Fragment key={`nutrient1_${i}`}>
                      <Grid item size={6}>
                        <Box component="span" sx={{ ...css.av }}>{nutrient.amount2 ? nutrient.amount2 + nutrient.uom : ' '}</Box>
                      </Grid>
                      <Grid item size={6} sx={{textAlign: 'right', fontWeight: 800}}>
                        <Box component="span" sx={{ ...css.dv }}>{nutrient.percent2 ? nutrient.percent2 + '%' : ' '}</Box>
                      </Grid>
                      {idx < arr.length - 1 && <Grid item size={12}><Divider sx={{ ...css.hr, ...css.hr1}} /></Grid>}
                    </React.Fragment>
                  )
                  i = i + 1
                  return item
                })} 
              </Grid>                                                          
              <Divider sx={{ ...css.hr, ...css.hr4 }} />
            </>}  
          </Box>
        </Grid>
      </Grid>
      {simplified && significantSourceText && 
        <Box component="span" sx={{...css.fs6, ...css2.significantSourceText}}>{significantSourceText}</Box>
      }
    </Box>
  )
}

