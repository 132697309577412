import React, { useState } from 'react'
import { TextField as MuiTextField } from '@mui/material'
import Validate from '../validator/Validate'
import { LabelImportantTwoTone } from '@mui/icons-material'



export const TextField = ({ id, value, label, required, disabled=false, readOnly=false, email, size = 'small', sx, variant, ...props }) => {

    const [isFocused, setIsFocused] = useState(false)
    const [isValid, setIsValid] = useState(true)
    const [errorMessages, setErrorMessages] = useState([])

    const validate = () => {
        if(required) {
            let validation = Validate.required(id, value)
            if(!validation.isValid) {
                isValid = false
                errorMessages.push(validation.message)  
            }
        }
    
        if(email) {
            let validation = Validate.email(id, value)
            if(!validation.isValid) {
                isValid = false
                errorMessages.push(validation.message)  
            }
        }
    }

    const handleFocus = (event) => {
        setIsFocused(true);
        if(props.onFocus) {
            props.onFocus(event)
        }
    };

    const handleBlur = (event) => {
        setIsFocused(false);
        if(props.onBlur) {
            props.onBlur(event)
        }
    };

    const shouldShrink = (Boolean(value) || isFocused) && !readOnly;

    return <MuiTextField 
        size={size} 
        require={required} 
        email={email} 
        disabled={disabled || readOnly}
        error={!isValid} 
        slotProps={{
            inputLabel: { shrink: shouldShrink },
            inputProps: { readOnly: readOnly }
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        sx={{
            ...(readOnly && {
                '& .MuiInput-root': {
                    '&:before': {
                        borderBottom: '1px solid transparent',
                    },
                },
                '& .MuiInputBase-root': {
                    color: 'inherit',
                    backgroundColor: 'inherit',
                },
                '& .MuiInputBase-root .Mui-disabled': {
                    color: 'inherit',
                    opacity: 1,
                    '-webkit-text-fill-color': 'inherit'
                },
                '& .Mui-disabled': {
                    color: 'inherit',
                },
                '& .MuiInputLabel-root': {
                    color: 'inherit',
                },
                '& .MuiInputLabel-root.Mui-disabled': {
                    top: '-1.3em',
                    color: 'inherit',
                },
            }),
            ...sx
          }}
        value={value} 
        helperText={isValid ? '' : errorMessages.join(', ')} 
        label={label}
        variant={readOnly ? 'standard' : "outlined"}
        {...props} 
    />
     
}