import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { Visibility as VisibilityIcon } from '@mui/icons-material'
import { Grid2 as Grid, Paper, Button } from '@mui/material'
import axios from 'axios'
import { getRequestConfig } from '../auth/auth.js'
import dayjs from 'dayjs';
import { ApiGateway } from '../config/config'
import { getQuerystringParameters } from '../utils/TableUtils.js'

export default function ListGenesisFormulas({setIsLoading, showFilters=false }) {
  let navigate = useNavigate()
  const [data, setData] = useState([])
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState(
    []
  );
  const [isError, setIsError] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const listColumns = useMemo(() => [
    {
      header: 'Primary Key',
      accessorKey: 'primaryKey',
      size: 250
    }, {
      header: 'Item Name',
      accessorKey: 'description',
      size: 250
    }, {
      header: 'User Code',
      accessorKey: 'userCode',
      size: 250
    }, {
      header: 'Supplier',
      accessorKey: 'supplier.supplierName',
      size: 120,
      Cell: (data) => {
        const { row } = data
        return row.original?.supplier?.supplierName ?? ''
      },
      filterFn: 'equalsString',
      filterSelectOptions: ['Genesis', 'Picasso', '<None>'],
      filterVariant: 'select'
    }, {
      header: 'Group',
      accessorKey: 'group.groupKey',
      size: 150,
      Cell: (data) => {
        const { row } = data
        return row?.original?.group?.name ?? ''
      },
      filterFn: 'equals',
      filterSelectOptions: [{label: 'Retail', value: 1358656146}, { label: 'Food Service', value: 794365709}],
      filterVariant: 'select'
    }, {
      header: 'Modify Date',
      accessorKey: 'modifyDate',
      size: 110,
      enableColumnFilter: false,
      Cell: (data) => {
        const row = data.row
        let modifyDate = row.original.modifyDate 
        if(modifyDate){
          return <span>{dayjs(modifyDate).format('MM/DD/YYYY')}</span>
        } 
        return <span></span>
      }
    }, {
      header: ' ',
      headerClassName: 'action-icon',
      className: 'action-icon',
      size: 60,
      enableSorting: false,
      enableColumnFilter: false,
      Cell: (rowInfo) => {
        let handleClick = () => {
          navigate(`/genesis/recipes/${rowInfo.row?.original?.primaryKey}`)
        };
        return <Button onClick={handleClick} size="small"><VisibilityIcon/></Button>
      }
    }
  ], [])

  const fetchData = async() => {
    if (!data.length) {
      setIsLoading(true)
    } else {
      setIsRefetching(true)
    }

    const requestConfig = await getRequestConfig()
    // console.log('requestConfig: ', requestConfig)
    const basePath = ApiGateway.genesis
    const q = getQuerystringParameters({ pagination, sorting, columnFilters, globalFilter })
    // console.log('q: ', q)
    const response = await axios.get(`${basePath}/base-food-names?${q}`, requestConfig)

    if(response?.data?.statusCode === 200) {
      let body = JSON.parse(response.data.body)
      // console.log('body: ', body)
      setData(body.data ?? [])
      setRowCount(body.count ?? 0)
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  }

  const table = useMaterialReactTable({
    // layoutMode: 'grid',
    columnFilterModeOptions: [
      'fuzzy',
      'greaterThan',
      'lessThan'
    ],
    columns: listColumns,
    data: data,
    paginationDisplayMode: 'pages',
    enableColumnActions: false,
    enableColumnFilters: showFilters,
    initialState: {
      columnVisibility: { primaryKey: false },
      showColumnFilters: true
    },
    renderTopToolbar: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    // renderTopToolbarCustomActions: () => (
    //   <Tooltip arrow title="Refresh Data">
    //     <IconButton onClick={() => fetchData()}>
    //       <RefreshIcon />
    //     </IconButton>
    //   </Tooltip>
    // ),
    muiTableContainerProps: {
      sx: { minHeight: 300 }
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '.82rem',
        padding: '4px !important'
      }
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  useEffect(() => {
    fetchData()
  }, [
    columnFilters, 
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize, 
    sorting, 
  ])

  return (
    <Paper>
      <Grid container rowSpacing={2} >
        <Grid size={12}>            
          <MaterialReactTable table={table} />
        </Grid>
      </Grid>
    </Paper>
  )
}