import { default as React, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { LoadingButton } from '@mui/lab';
import HelpIcon from '@mui/icons-material/Help'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import EditOutlined from '@mui/icons-material/EditOutlined'

export default function FormViewCommandBar (props = {
  loading: false,
  showEdit: false,
  onEdit: undefined,
  showCancel: false,
  onCancel: undefined,
  showClose: false,
  onClose: undefined,
  showSave: false,
  enableSave: false,
  onSave: undefined,
  leftJsx: undefined,
  actionButtons: {
    label: '',
    onClick: undefined,
    endIcon: undefined,
    enabled: false,
    show: false
  }
}) {
  const navigate = useNavigate()
  const [saveButtonLabel, _setSaveButtonLabel] = useState('Save')

  const toggleHelp = () => {
    navigate(props.helpPage, {state: props.helpPageState || {}})
  }

  return (
    <div className="tis-commandbar tis-commandbar-formview">
      {props.leftJsx || undefined}
      {props.helpPage && <LoadingButton loading={props.loading} onClick={toggleHelp}><HelpIcon/></LoadingButton>}
      {props.actionButtons && props.actionButtons.length &&
        props.actionButtons.reduce((p, b) => {
          if (b.show) {
            p.push(
              <LoadingButton loading={props.loading} variant="contained" onClick={b.onClick} endIcon={b.endIcon || undefined} disabled={!b.enabled}>{b.label}</LoadingButton>
            )
          }
          return p
        },[])
      }
      {props.showEdit && <LoadingButton loading={props.loading} variant="outlined" onClick={props.onEdit} endIcon={<EditOutlined />}>Edit</LoadingButton>}
      {(props.showSave === undefined || props.showSave) && 
        <LoadingButton loading={props.loading} variant="contained" onClick={props.onSave} endIcon={<SaveIcon />} disabled={!props.enableSave }>{saveButtonLabel}</LoadingButton>
      }
      {(props.showCancel === undefined || props.showCancel) && <LoadingButton loading={props.loading} variant="outlined" onClick={props.onCancel} endIcon={<CancelIcon />}>Cancel</LoadingButton>}
      {(props.showClose === undefined || props.showClose) && <LoadingButton loading={props.loading} variant="outlined" onClick={props.onClose} endIcon={<CancelIcon />}>Close</LoadingButton>}
      {props.showBack && <LoadingButton loading={props.loading} variant="outlined" onClick={props.onBack} >Back</LoadingButton>}
    </div>
  )
}
