import React, { useState } from 'react';
import { Box, Grid2 as Grid, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import DaggerIcon from '../img/DaggerIcon';

const getNextState = (currentState, showCitation) => {
  if (showCitation) {
    switch (currentState) {
      case 'visible':
        return 'hidden';
      case 'hidden':
        return 'citation';
      case 'citation':
        return 'visible';
      default:
        return 'visible';
    }
  } else {
    return currentState === 'visible' ? 'hidden' : 'visible';
  }
};

const LDSInput = (props) => {
  const { showOnPanel, showCitation, id, label, value, onChange, ...otherProps } = props;
  const [currentState, setCurrentState] = useState(value[`${id}Check`] || showOnPanel ? 'visible' : 'hidden'); // initial state

  const handleIconClick = () => {
    const nextState = getNextState(currentState, showCitation);
    setCurrentState(nextState);
    if (onChange) {
      const synthEvent = {
        target: {
          id: `${id}Check`,
          value: nextState
        }
      };
      console.log('LDSInput.onChange, firing event: ', synthEvent)
      onChange(synthEvent);
    }
  };

  const renderIcon = () => {
    switch (currentState) {
      case 'visible':
        return <Visibility onClick={handleIconClick} />;
      case 'hidden':
        return <VisibilityOff onClick={handleIconClick} />;
      case 'citation':
        return <DaggerIcon onClick={handleIconClick} />;
      default:
        return <Visibility onClick={handleIconClick} />;
    }
  };

  return (
    <Grid container>
      <Grid item size={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Typography sx={{ marginRight: '1em' }}>{label}:</Typography>
      </Grid>
      <Grid item size={7}>
        <Box
          className="lds-checkbox shadow"
          id={`${id}Check`}
          sx={{
            width: '60%',
            minHeight: '43px',
            border: '1px solid #999',
            borderRadius: '4px',
            margin: '0 1em',
            padding: '.5em 1em',
            backgroundColor: currentState === 'hidden' ? '#F3F3F3' : '#DDFFEE',
            position: 'relative',
          }}
        >
          <Typography sx={{ display: 'inline' }}>{value[id]}</Typography>
          <Box
            sx={{ position: 'absolute', right: '1em', top: '.4em', cursor: 'pointer' }}
          >
            {renderIcon()}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LDSInput;