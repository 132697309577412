import { default as React, useState } from 'react'
import { Article as ArticleIcon } from '@mui/icons-material'
import ListActiveLds from './ListActiveLds'
import ListViewCommandBar from '../commandBar/ListViewCommandBar'
import Page from '../layout/Page'
import { Paper } from '@mui/material';


export default function ListActiveLdsPage (props = {}) {
  const [isLoading, setIsLoading] = useState(true)
  const breadcrumb = <span><ArticleIcon/> Label Declaration Sheets (LDS)</span>

  const [showFilters, setShowFilters] = useState(false)
  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  return (
    <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading}
      commandBar={
        <ListViewCommandBar showFilters={true} toggleFilters={toggleFilters} showAdd={false} />
      }>
      <Paper>
        <ListActiveLds showFilters={showFilters} setIsLoading={setIsLoading} />
      </Paper>
    </Page>
  )
}