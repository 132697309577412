import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

const ServingSizeTextField = (props = {}) => {
  const [errorState, setErrorState] = useState(false);

  const handleBlur = (e) => {
    const value = e.target.value;
    const isValid = /^\d+(\.\d+)?$/.test(value);
    setErrorState(!isValid);
    if(props.onBlur) {
        props.onBlur(e)
    }
  };

  return (
    <TextField
        onBlur={(e) => handleBlur(e)}
        error={errorState}
        helperText={errorState ? 'Please enter a valid numeric value (positive numbers only).' : ''}
        slotProps={{
          input: {
            endAdornment: (
              <span style={{ width: '2em', whiteSpace: 'nowrap', color: '#999' }}>
                {('' + props.servingSizeUnit).replaceAll('-',' ')}
              </span>
            ),
          },
        }}
        {...props}
    />
  );
};

export default ServingSizeTextField;