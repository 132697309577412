export const getQuerystringParameters = ({ pagination, sorting, columnFilters, _globalFilter }) => {
  let q = []    
  q.push(`page=${pagination.pageIndex}`)
  q.push(`size=${pagination.pageSize}`)
  if(sorting?.length) {
    // console.log('sorting: ', sorting)
    q.push(`sorting=${sorting.reduce((q, param) => {
      q.push(`${param.id}:${param.desc ? 'desc' : 'asc'}`)
      return q
    }, []).join('@@@')}`)
  }
  if(columnFilters?.length) {
    console.log('columnFilters: ', columnFilters)
    q.push(`filtering=${columnFilters.reduce((q, param) => {
      q.push(`${param.id}:${param.value}`)
      return q
    }, []).join('@@@')}`)
  }
  return q.join('&')
}