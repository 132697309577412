import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import React, { useRef } from 'react'

export default function Panel({title, children}) {
    const myRef = useRef(null)
    title = title ? <Typography>{title}</Typography> : undefined

    return (
        <Card sx = {{width: '100%', margin: '1em 0', boxShadow: '2px 4px 6px rgba(0, 0, 0, 0.3)', borderRadius: '4px', border: '1px solid #ccc'}}>
            {title && <CardHeader ref={myRef} title={title} sx={{ backgroundColor: '#EFEFEF'  }}/>}
            <CardContent className="content">
                {children}
            </CardContent>
        </Card>
    )
}