export const sraDomain = 'https://sra-api.dev.tis.coke.com'
export const domain = 'https://api.dev.tis.coke.com'
export const azureClientId = '36dfcc9b-d282-4020-8d05-9998a907298a'
export const azureTenantId = 'eb36d020-2c35-44de-bc7a-554cc979b26d'
export const apiKey = '3raCXCKojA7H7CcinqaZuYJGlp6qJ3z5THbLkz6c'
export const stage = 'dev'

export const featureFlags = {
  authorization: true
}
