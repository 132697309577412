import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { Edit as EditIcon, Visibility as VisibilityIcon } from '@mui/icons-material'
import { Grid2 as Grid, Paper, Button } from '@mui/material'
import axios from 'axios'
import { getAuthenticator, getRequestConfig } from '../auth/auth'
import dayjs from 'dayjs';
import { ApiGateway } from '../config/config'
import { getQuerystringParameters } from '../utils/TableUtils'

export default function ListActiveLds({ setIsLoading, showFilters=false }) {
  let navigate = useNavigate()
  const [data, setData] = useState([])
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState(
    []
  );
  
  const [account] = useState(getAuthenticator().getCurrentAccount())
  const [isError, setIsError] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);//ldsUpdatedAt:desc
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const listColumns = useMemo(() => [
    {
      header: 'Product Name',
      accessorKey: 'name'
    }, {
      header: 'Zone',
      accessorKey: 'zone',
      filterFn: 'equalsString',
      filterSelectOptions: ['Canada', 'Mexico', 'PR', 'USA'],
      filterVariant: 'select',
    }, {
      header: 'Packaging',
      accessorKey: 'packaging',      
    }, {
      header: 'BBN',
      accessorKey: 'bbn',
    }, {
      header: 'Type',
      accessorKey: 'parentFormulaType',
      Cell: (data) => {
        const { row } = data
        let t = ('' + row?.original?.parentFormulaType).toLowerCase()
        return t === 'fs' ? 'Food Service' : t === 'rtl' ? 'Retail' : row?.original?.parentFormulaType
      },
      filterFn: 'equals',
      filterSelectOptions: [{label: 'All', value: ''}, { label: 'Food Service', value: 'FS'}, { label: 'Retail', value: 'RTL'}],
      filterVariant: 'select'
    }, {
      header: 'Status',
      accessorKey: 'ldsStatus',
      filterFn: 'equalsString',
      filterSelectOptions: [{label: 'All', value: ''}, { label: 'Draft', value: 'draft'}, { label: 'Under Review', value: 'review'}, { label: 'Approved', value: 'approved'}],
      filterVariant: 'select',
    }, {
      header: 'Version',
      accessorKey: 'ldsVersion',
      enableSorting: false,
      enableColumnFilters: false,
    }, {
      header: 'Last Modified',
      accessorKey: 'ldsUpdatedAt',
      enableColumnFilter: false,
      Cell: (data) => {
        const row = data.row
        let modifyDate = row.original.ldsUpdatedAt 
        if(modifyDate){
          return <span>{dayjs(modifyDate).format('MM/DD/YYYY')}</span>
        } 
        return <span></span>
      }
    }, {
      header: ' ',
      headerClassName: 'action-icon',
      className: 'action-icon',
      size: 60,
      enableSorting: false,
      enableColumnFilter: false,
      Cell: (rowInfo) => {
        let handleClick = () => {
          navigate(`/label-declaration-sheets/products/${rowInfo.row?.original?.id}/entry`)
        };
        return <Button onClick={handleClick} size="small"><EditIcon/></Button>
      }
    }
  ], [])


  const fetchData = async() => {
    if (!data.length) {
      setIsLoading(true)
    } else {
      setIsRefetching(true)
    }

    const requestConfig = await getRequestConfig()
    const basePath = ApiGateway.labelDeclarationSheets
    const q = getQuerystringParameters({ pagination, sorting, columnFilters, globalFilter })
    
    const response = await axios.get(`${basePath}/products/search?koId=${account?.idTokenClaims?.koId}&${q}`, requestConfig)
    console.log('response.status: ', response.status)
    console.log('response.data?.data: ', response.data?.data)
    if(response?.status === 200) {
      setData(response.data?.data ?? [])
      // setRowCount(body.count ?? 0)
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  }

  const table = useMaterialReactTable({
    // layoutMode: 'grid',
    columnFilterModeOptions: [
      'fuzzy',
      'greaterThan',
      'lessThan'
    ],
    columns: listColumns,
    data: data,
    paginationDisplayMode: 'pages',
    enableColumnActions: false,
    enableColumnFilters: showFilters,
    initialState: {
      columnVisibility: { primaryKey: false },
      showColumnFilters: true
    },
    renderTopToolbar: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    // renderTopToolbarCustomActions: () => (
    //   <Tooltip arrow title="Refresh Data">
    //     <IconButton onClick={() => fetchData()}>
    //       <RefreshIcon />
    //     </IconButton>
    //   </Tooltip>
    // ),
    muiTableContainerProps: {
      sx: { minHeight: 300 }
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '.82rem',
        padding: '4px !important'
      }
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  useEffect(() => {
    fetchData()
  }, [
    columnFilters, 
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize, 
    sorting, 
  ])

  return (
    <Paper>
      <Grid container rowSpacing={2} >
        <Grid size={12}>            
          <MaterialReactTable table={table} />
        </Grid>
      </Grid>
    </Paper>
  )
}