import { useMemo, useState } from 'react'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Grid2 as Grid, Paper } from '@mui/material'

export default function ListNutritionCalculations(props) {
  
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  
  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          head: {
            height: '150px', // Adjust the height based on your requirements
            textAlign: 'right',
            verticalAlign: 'bottom',
            borderRight: '1px solid rgb(224, 224, 224)',
            backgroundColor: '#CCC !important',
            color: '#333 !important',
            // maxWidth: '40px'
          },
          root: {
            textAlign: 'right',
            borderRight: '1px solid rgb(224, 224, 224)',
            padding: '2px',
            boxSizing: 'border-box', // Necessary to calculate width including padding
            textOverflow: 'ellipsis', // Ensure text overflow is handled
            overflow: 'hidden', // Hide overflow content
            whiteSpace: 'nowrap', // Prevent content from wrapping
            maxWidth: 'inherit', // Ensure maxWidth set on columns is respected
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {

            '&:nth-of-type(even)': {
              backgroundColor: '#f2f2f2 !important',  // Apply a light grey background to even rows
            },
            '&:hover': {
              backgroundColor: '#b3e5fc !important', // Set the hover color
            },
          },
        },
      },
    },
  });

  const listColumns = [
    {
      header: 'Dispensing HW',
      accessorKey: 'Dispensing Hardware',
      size:150,
      maxSize: 150
    },
    {
      header: 'Product Name',
      accessorKey: 'Product Name',
      maxSize: 180
    }, {
      header: 'SAP Beverage ID',
      accessorKey: 'SAP Beverage ID',
      enableSorting: false, 
      maxSize: 25
    }, {
      header: 'Size',
      accessorKey: 'Size',
      maxSize: 25
    }, {
      header: '(fl oz)',
      accessorKey: '(fl-oz)',
      maxSize: 25,
    }, {
      header: 'BIB Ratio',
      accessorKey: 'BIB Ratio',
      maxSize: 25,
    }]
    
    props.allNutrientsShown.forEach(nutrient => {
      let colDef = {
        header: nutrient,
        accessorKey: nutrient,
        maxSize: 25,
      }
      colDef.Cell = (data) => {
        const { row } = data
        let raw = row.original?.[nutrient] ?? 0
        const value = props.numberFormat ? props.numberFormat.format(raw) : raw
        return <span title={row.original?.[nutrient]}>{value}</span>
      }
      
      listColumns.push(colDef)
    })

  const renderVerticalHeader = (label) => (
    <Box
      component="div"
      sx={{
        transform: 'rotate(-90deg)',
        whiteSpace: 'nowrap',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding:0,
        overflow:'visible',
        width: '40px',
        height: '155px', // Adjust the height based on your requirements
      }}
    >
      {label}
    </Box>
  );

  const extendedColumns = listColumns.map((column) => ({
    ...column,
    Header: () => renderVerticalHeader(column.header),
  }));

  const table = useMaterialReactTable({
    columns: extendedColumns,
    data: props.data,
    paginationDisplayMode: 'pages',
    enableColumnOrdering: false, // Disable column reordering
    enableFullScreenToggle: false, // Disable full screen option
    enableDensityToggle: false,
    enableHiding: false,
    // enableColumnActions: false,
    // initialState: {
    //   showColumnFilters: false,
    // },
    // renderTopToolbar: false,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    muiTableBodyCellProps: {
      sx: {
        fontSize: '.82rem',
        padding: '4px !important'
      }
    },
    state: {
      isLoading,
      showAlertBanner: isError,
    },
  });
  
  return (
    <Paper>
      <Grid container rowSpacing={2} >
        <Grid size={12}>      
          <ThemeProvider theme={theme}>      
            <MaterialReactTable 
              table={table} 
            />
          </ThemeProvider>
        </Grid>
      </Grid>
    </Paper>
  )
}