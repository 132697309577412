import React from "react";
import css from '../NfpStyles'
import { Box } from '@mui/material'
import { isEmpty } from '../../../utils/utils'

let css2 = {
  h1: {
    fontSize: '24px',
    fontWeight: 900,
    lineHeight: 1.25,
    fontFamily: 'inherit',
    verticalAlign: 'bottom',
    color: '#000000',
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    letterSpacing: 'normal',
    textAlign: 'left',
    display: 'inline'
  },
  h2: {
    fontSize: '16px',
    fontWeight: 900,
    fontFamily: 'inherit',
    lineHeight: 1.25,
    verticalAlign: 'bottom',
    color: '#000000',
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: '12px',
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    letterSpacing: 'normal',
    textAlign: 'left',
    display: 'inline'
  },
  label: {
    fontSize: '14px',
    fontWeight: 900,
    fontFamily: 'inherit',
    lineHeight: 1.25,
    verticalAlign: 'bottom',
    color: '#000000',
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    letterSpacing: 'normal',
    textAlign: 'left',
    display: 'inline'
  },
  value: {
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'inherit',
    lineHeight: 1.25,
    verticalAlign: 'bottom',
    color: '#000000',
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: '4px',
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    letterSpacing: 'normal',
    textAlign: 'left',
    display: 'inline'
  },
  div: {
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'inherit',
    lineHeight: 1.25,
    verticalAlign: 'bottom',
    color: '#000000',
    marginTop: 4,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    letterSpacing: 'normal',
    textAlign: 'left'
  },  
  nutritionFactPanelLinear: {
    minWidth: '510px',
    maxWidth: '510px'
  }
}

export default function NfpLinearView({nfp = {}, simplified, serve, abbreviated, significantSourceText}) {

  return (
    <div id="nutrition-fact-panel-linear" style={Object.assign({}, css.nutritionFactPanel, css2.nutritionFactPanelLinear)}>
      <h1 style={css2.h1}>Nutrition Facts</h1><h2 style={css2.h2}>{abbreviated ? 'Serv.' : 'Serving'} Size: {nfp.servingSize},</h2><br/>
      <br/>
      {serve === 'multi' &&
        <Box component="span">{nfp.servingsPerContainer} Serving(s) Per Container </Box>
      }
      <Box component="span" sx={css2.h1}>Calories {nfp.calories},&nbsp;</Box>
      <Box component="span" sx={css2.label}>Total Fat</Box><Box component="span" sx={css2.value}>{nfp.totalFat}g&nbsp;({nfp.totalFatPercent}%),&nbsp;</Box>
      <Box component="span" sx={css2.label}>Sodium</Box><Box component="span" sx={css2.value}>{nfp.sodium}g&nbsp;({nfp.sodiumPercent}%),&nbsp;</Box>
      <Box component="span" sx={css2.label}>Total {abbreviated ? 'Carb.' : 'Carbohydrate'}</Box><Box component="span" sx={css2.value}>{nfp.totalCarb}g&nbsp;({nfp.totalCarbPercent}%),&nbsp;</Box>
      <Box component="span" sx={css2.label}>Total Sugars</Box><Box component="span" sx={css2.value}>{nfp.totalSugars}g&nbsp;({nfp.totalSugarsPercent}%),&nbsp;</Box>
      
      {!isEmpty(nfp.addedSugars) && <Box component="span"><Box component="span" sx={css2.value}>Includes {nfp.addedSugars}g Added Sugars</Box><Box component="span" sx={css2.value}>{nfp.addedSugarsPercent}%,&nbsp;</Box></Box>}
      <Box component="span" sx={css2.label}>Protein</Box><Box component="span" sx={css2.value}>{nfp.protein}g&nbsp;({nfp.proteinPercent}%)</Box><br/>
      {simplified && significantSourceText && <Box component="span" sx={css2.div}>{significantSourceText}</Box>}
    </div>
  )
}
