import { AzureAuthenticator } from 'tis-azure-auth'
import { AzureAdClient, ApiGateway } from '../config/config'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import EventBus from '../utils/EventBus'

let Authenticator = undefined
export const getAuthenticator = () => {
  if ( !Authenticator ) {
    Authenticator = new AzureAuthenticator({azureClientId: AzureAdClient.info.clientId, azureTenantId: AzureAdClient.info.tenantId})
  }
  return Authenticator
}

let token = undefined
export const getToken = async (_cacheOnly = false) => {
  if (_cacheOnly) return token
  
  if (token) {
    // to reduce traffic ensure token is a good distance from expiration but not too fresh
    const expirationTime = token.idTokenClaims?.exp ? new Date(token.idTokenClaims.exp * 1000).getTime() : 0
    const currentTime = new Date().getTime()
    if ((expirationTime - currentTime) / 1000 / 60 < 30) {
      token = undefined
    }
  }
  if (!token) {
    const auth = getAuthenticator()
    try {
      token = await auth.authenticate()
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        console.error(`InteractionRequiredAuthError caught and clearing session for retry`)
        sessionStorage.clear()
        token = await getToken()
      } else throw (e)
    }
  }
  EventBus.emit('token', token)
  return token
}

export const getRequestConfig = async () => {
  const token = await getToken()
  return {
    headers: {        
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.idToken}`,
      'x-api-key': ApiGateway.apiKey
    }
  }
}

export function getHostUrl() {
  return `${[window.location.protocol,'//',
  window.location.hostname,
  window.location.hostname === 'localhost' ? [':',window.location.port].join('') : ''].join('')}`
}

export function logout() {
  getAuthenticator().logout()
}
