import React, { useState } from 'react';
import styled from 'styled-components';

// Define the styled components
const CheckboxWrapper = styled.label`
  position: relative;
  display: flex;
  float: left;
  clear: both;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  line-height: 22px;
  user-select: none;
`;

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const Checkmark = styled.span`
  position: relative;
  height: 22px;
  width: 22px;
  background-color: #f5f5f5;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.54);
  transition: background-color 0.2s, border-color 0.2s;

  ${CheckboxInput}:checked ~ & {
    background-color: #1976d2; /* MUI primary color */
    border-color: #1976d2;
  }

  ${CheckboxInput}:checked ~ &:after {
    display: block;
  }

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 7px;
    top: 3px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  ${CheckboxWrapper}:hover & {
    background-color: rgba(25, 118, 210, 0.04);
    border-color: rgba(25, 118, 210, 0.7);
  }

  ${CheckboxInput}:checked:hover ~ & {
    background-color: #1565c0; /* Darker MUI primary color */
    border-color: #1565c0;
  }
`;

const CheckboxLabel = styled.span`
  margin-left: 16px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
`;

const Checkbox = ({ id, label, disabled, checked = false, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <CheckboxWrapper>
      <CheckboxInput
        id={id}
        className="mui-checkbox-input"
        type="checkbox"
        checked={checked ?? isChecked}
        disabled={disabled}
        onChange={handleChange}
      />
      <Checkmark />
      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxWrapper>
  );
};

export default Checkbox;