import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { CheckboxMultiSelect } from '../form/CheckboxMultiSelect'
import { useTheme } from '@mui/material/styles';
import { getRequestConfig } from '../auth/auth.js'
import { default as bus } from '../utils/EventBus.js'
import Event from '../utils/EventNames.js';
import axios from 'axios'
import dayjs from 'dayjs';
import Page from '../layout/Page.js'

import { TextField } from '../layout/TextField.js'
import { HorizontalRule } from '../layout/HorizontalRule.js'
import { handleFieldChange } from '../form/Form.js'
import { Science as ScienceIcon } from '@mui/icons-material'
import { Button, Grid2 as Grid, Paper, Typography } from '@mui/material'
import { ApiGateway } from '../config/config.js';

export default function ProductFormPage (props) {
    const navigate = useNavigate()
    const [locale, setLocale] = useState('en-US');
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState();
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const { id } = useParams()

    useEffect(() => {
        (async() => {
            setIsLoading(true)
            const requestConfig = await getRequestConfig()
            const basePath = ApiGateway.formulaNutrition
            const response = await axios.get(`${basePath}/${id}`, requestConfig)
            if(response?.status === 200) {
                console.log('response.data: ', response.data)
                let r = response.data
                let p = {
                  id: r.id,
                  formulaName: r.formulaName ?? '',
                  formulaShortName: r.formulaShortName ?? '',
                  userCode: r.userCode ?? '',
                  regulationName: r.regulation?.label ?? '',
                  dualColumn: r.dualColumn ? 'Yes' : 'No',
                  parType: r.parType ?? '',
                  type: r.type === 'FS' ? 'Food Service' : r.type === 'RTL' ? 'Retail' : '',
                  bbn: r.bbn ?? '',
                  ratios: r.ratios ?? [],
                  sapBeverageId: r.sapBeverageId ?? '',
                  beverageDensity: r.beverageDensity ?? '',
                  processCodings: r.processCodings ?? [],
                  brandName: r.brand?.name,
                  ingredients: r.ingredients?.[locale] ?? 'N/A',
                  juiceContent: r.juiceContent?.[locale] ?? 'N/A',
                  allergenNotes: r.allergenNotes?.[locale] ?? 'N/A',
                  statementOfIdentity: r.statementOfIdentity?.[locale] ?? 'N/A',
                  glutenStatement: r.glutenStatement?.[locale] ?? 'N/A',
                  kosherStatement: r.kosherStatement?.[locale] ?? 'N/A',
                  pkuWarningStatement: r.kosherStatement?.[locale] ?? 'N/A',
                  caffeineInformation: r.caffeineInformation?.[locale] ?? 'N/A',
                  fortifiedWith: r.fortifiedWith?.[locale] ?? 'N/A',
                }



                setProduct(p)
            }
            setIsLoading(false)
        })()
    }, [id])

  const getBreadcrumb = () => {
    let breadcrumb = <span><ScienceIcon /> Product Form</span>;
    return breadcrumb;
  }

  const getCommandBar = () => {
    let commandBar;
    commandBar = <>
      {!isReadOnly && (
        <>
          <Button size="small" variant="contained" sx={{marginRight:'.5em'}} disabled={(!isDirty || isSaving) && product?.id != null} onClick={async (event) => {
            await save(event)
          }}>{product?.id ? 'Save' : 'Create'}</Button>
          
          <Button size="small" variant="contained" sx={{marginRight:'.5em'}} onClick={() => {
            if (isDirty) {
              // this.setState({ showCancelModal: true });
            } else {
              navigate(-1)
            }
          }}>Cancel</Button>
        </>  
        )
      }</>

    return commandBar;
  }


  const save = async(event) => {
    setIsSaving(true)
    
//     event.preventDefault()
//     let validationResult = this.validate()
//     if (validationResult.isValid) {
//       this.setState({
//         isLoading: true
//       });
//       try {
//         let lds = this.state.lds
//         if(lds.layout !== 'dual') {
//           delete lds.nfp2
//         }
//         lds.collaborators = this.state.collaborators
//         let url = this.serviceUrl + '/'
//         let method = 'POST'
        
//         if (lds.id) {
//           url += lds.id
//           method = 'PUT'
//         } else {
//           lds.createdBy = Auth.session && Auth.session.principal ? Auth.session.principal.id : 'Guest'
//         }
//         lds.updatedBy = Auth.session && Auth.session.principal ? Auth.session.principal.id : 'Guest'
//         let saveRequest = {
//           uri: url,
//           method: method,
//           body: lds
//         }
//         // console.log('saveRequest: ', saveRequest)
//         let response = await Auth.rp(saveRequest)  
      
//         await this.setState({
//           isReadOnly: this.state.isReadOnly || lds.status === 'approved'
//         })
//         if (response && response.affectedRows > 0) {
//           // console.log('save response: ', response)
//           lds = response.data
          
//           lds.nfp2 = lds.nfp2 || {}
//           let promises = [/*this.loadNfp2(),*/ this.loadLdsInfo(), this.loadCollaborators(), this.loadHistory()]
//           let responses = await Promise.all(promises)
  
//           let newState = responses.reduce((newState, response) => {
//             newState = Object.assign(newState, response)
//             return newState
//           }, {})
//           newState = Object.assign(newState, {
//             lds: lds,
//             isSaving: false,
//             isDirty: false,
//             isLoading: false
//           })
          
//           await this.setState(newState, () => {
//             this.getLdsPreview()
//           })
//           // console.log('this.state: ', this.state)
//           await this.init(this.state)
//           bus.emit(Event.ALERT, {
//             message: 'Saved Successfully...',
//             style: 'success',
//             duration: 2000
//           })
//         } else {
//           this.setState({
//             isSaving: false,
//             isLoading: false
//           })
//           bus.emit(Event.ALERT, {
//             message: 'There was a problem saving the lds record...',
//             style: 'danger'
//           })
//         }
//       } catch(error) {
//         bus.emit(Event.ALERT, {
//           message: `Issues Found... Could not save! ${error}`,
//           style: 'danger'
//         })
        setIsSaving(false)
//         console.log('ERROR: ', error)
//       }
//     } else {
//       this.setState({isDirty: false, isSaving: false})
//       let messages = Object.keys(validationResult.validationMessages).map(key=> {
//         return validationResult.validationMessages[key]
//       })
//       let len = messages.length
//       if(len > 3) {
//         messages = messages.slice(0, 3)
//         messages.push(`and ${len - 3} other(s)`)
//       }
      
//       let text = messages.join(', ')
//       bus.emit(Event.ALERT, {
//         message: text,
//         style: 'warning'
//       })
//     }
  }

return (
    <Page { ...props} breadcrumb = {getBreadcrumb()} commandBar={getCommandBar()} isLoading={isLoading}>
      {/* <Button onClick={()=> {console.log('product: ', product)}}>State</Button> */}
      {product &&
      <Paper sx={{padding: '.5em 1em', width: '75%'}}>
        <Grid container spacing={2}>
          <Grid size={6}>
            <TextField fullWidth readOnly value={product.formulaName} id='formulaName' label="Name:" />
          </Grid>
          <Grid size={6}>
            <TextField fullWidth readOnly value={product.formulaShortName} id='formulaShortName' label="Short Name:" />
          </Grid>

          <Grid size={6}>
            <TextField fullWidth readOnly value={product.userCode} id='userCode' label="User Code:" />
          </Grid>
          <Grid size={6}>
            <TextField fullWidth readOnly value={product.regulation?.label} id='regulationName' label="Nutrition Facts Regulation:" />            
          </Grid>                 
          
          
          <Grid size={6}>
            <TextField fullWidth readOnly value={product.dualColumn} id='dualColumn' label="Dual Column:" />
          </Grid>
          <Grid size={6}>
            <TextField fullWidth readOnly value={product.bbn} id='bbn' label="BBN:" />
          </Grid>                 
          
          <Grid size={6}>
            <TextField fullWidth readOnly value={product.type} id='dualColumn' label="Type:" />
          </Grid>
          <Grid size={6}>
          <Typography></Typography>
          </Grid>                 
          
          <Grid size={12}></Grid>

          <Grid size={6}>
            <CheckboxMultiSelect fullWidth value={product.ratios} id='ratios' label="Ratios" onChange={(event) => handleFieldChange('ratios', event.target.value, product, setProduct)}/>    
          </Grid>
          <Grid size={6}>
            <TextField fullWidth value={product.sapBeverageId} id='sapBeverageId' label="SAP Beverage ID" onChange={(event) => handleFieldChange('sapBeverageId', event.target.value, product, setProduct)}/>    
          </Grid>                 
          
          <Grid size={6}>
            <TextField fullWidth value={product.beverageDensity} id='beverageDensity' label="Beverage Density" onChange={(event) => handleFieldChange('beverageDensity', event.target.value, product, setProduct)}/>    
          </Grid>
          <Grid size={6}>
            <CheckboxMultiSelect fullWidth value={product.processCodings} id='processCodings' label="ProcessCodings" onChange={(event) => handleFieldChange('processCodings', event.target.value, product, setProduct)}/>    
          </Grid>                 

          <Grid size={6}>
            <TextField fullWidth value={product.brand?.name} id='brandName' label="Freestyle Parent" onChange={(event) => handleFieldChange('brandName', event.target.value, product, setProduct)}/>    
          </Grid>
          <Grid size={6}></Grid>
          
          <Grid size={12}>
            <HorizontalRule thickness={1} color="#999" />
          </Grid>
          
          <Grid size={6}>
            <TextField fullWidth multiline rows={6} value={product.ingredients} id='ingredients' label="Ingredients" onChange={(event) => handleFieldChange('ingredients', event.target.value, product, setProduct)}/>    
          </Grid>
          <Grid size={6}>
            <TextField fullWidth multiline rows={6} value={product.juiceContent} id='juiceContent' label="Juice Content Label Claim" onChange={(event) => handleFieldChange('juiceContent', event.target.value, product, setProduct)}/>    
          </Grid>                 
          
          <Grid size={6}>
            <TextField fullWidth multiline rows={6} value={product.allergenNotes} id='allergenNotes' label="Allergen Notes" onChange={(event) => handleFieldChange('allergenNotes', event.target.value, product, setProduct)}/>    
          </Grid>
          <Grid size={6}>
            <TextField fullWidth multiline rows={6} value={product.statementOfIdentity} id='statementOfIdentity' label="Statement Of Identity" onChange={(event) => handleFieldChange('statementOfIdentity', event.target.value, product, setProduct)}/>    
          </Grid>                 
          
          <Grid size={6}>
            <TextField fullWidth multiline rows={6} value={product.glutenStatement} id='glutenStatement' label="Gluten Statement" onChange={(event) => handleFieldChange('glutenStatement', event.target.value, product, setProduct)}/>    
          </Grid>
          <Grid size={6}>
            <TextField fullWidth multiline rows={6} value={product.kosherStatement} id='kosherStatement' label="Kosher Statement" onChange={(event) => handleFieldChange('kosherStatement', event.target.value, product, setProduct)}/>    
          </Grid>                 
          
          <Grid size={6}>
            <TextField fullWidth multiline rows={6} value={product.pkuWarningStatement} id='pkuWarningStatement' label="PKU Warning Statement" onChange={(event) => handleFieldChange('pkuWarningStatement', event.target.value, product, setProduct)}/>    
          </Grid>
          <Grid size={6}>
            <TextField fullWidth multiline rows={6} value={product.caffeineInformation} id='caffeineInformation' label="Caffeine Information" onChange={(event) => handleFieldChange('caffeineInformation', event.target.value, product, setProduct)}/>    
          </Grid>                 
          
          <Grid size={6}>
            <TextField fullWidth multiline rows={6} value={product.fortifiedWith} id='fortifiedWith' label="Fortified With" onChange={(event) => handleFieldChange('fortifiedWith', event.target.value, product, setProduct)}/>    
          </Grid>

        </Grid>
      </Paper>}
    </Page>
)}