import React from 'react';
import { Box } from '@mui/material';

export const HorizontalRule = ({ color = 'black', thickness = '1px', margin = '.25em 0', ...props }) => {
  return (
    <Box
      component="hr"
      sx={{
        border: 'none',
        borderBottom: `${thickness}px solid ${color}`,
        margin: margin,
      }}
      {...props}
    />
  );
};