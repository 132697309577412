import React from "react";
import css from '../NfpStyles'
import { Box, Divider, Grid2 as Grid, Typography } from '@mui/material'
import { isEmpty } from '../../../utils/utils'

let css2 = {
  nutritionFactPanelTabular: {
    minWidth: '580px',
    maxWidth: '650px'
  },
  av: {
    marginLeft: '1em',
    minHeight: '13px',
    verticalAlign: 'top'
  },
  calories: {
    height: '100%'
  },
  caloriesH3: {
    bottom: '25%',
    position: 'absolute',
    textAlign: 'right'
  },
  caloriesH4: {
    lineHeight: '1.75em',
    paddingTop: '1em'
  },
  colXs5: {
    position: 'relative',
    minHeight: '1px',
    paddingRight: '15px',
    paddingLeft: '15px',
    float: 'left',
    width: '35.67%'
  },
  colXs6: {
    position: 'relative',
    minHeight: '1px',
    paddingRight: '15px',
    paddingLeft: '15px',
    float: 'left',
    width: '48%'
  },
  colXs7: {
    position: 'relative',
    minHeight: '1px',
    paddingRight: '15px',
    paddingLeft: '15px',
    float: 'left',
    width: '52.33%'
  },
  column1: {
    display: 'inline-block',
    paddingTop: 0,
    paddingRight: '1em',
    paddingBottom: 0,
    paddingLeft: '1em',
    verticalAlign: 'top'
  },
  column2: {
    display: 'inline-block'
  }, 
  columnNutrientAv: {
    marginLeft: '0.5em',
    fontSize: '10px'
  },
  columnNutrientDv: {
    marginLeft: '0.5em',
    fontSize: '10px',
    float: 'none'
  },
  columnNutrientLeft: {
    paddingTop: 0,
    paddingRight: '0.5em',
    paddingBottom: 0,
    paddingLeft: 0,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    textAlign: 'left'
  },
  columnNutrientRight: {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    textAlign: 'right'
  },
  dv: {
    float: 'right',
    minHeight: '13px',
    verticalAlign: 'top'
  },
  h5: {
    fontSize: '12px',
    fontFamily: 'inherit',
    lineHeight: 1.1,
    verticalAlign: 'top',
    color: '#000000',
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    letterSpacing: 'normal',
    textAlign: 'left',
    width: '100%',
    display: 'inline',
    verticalAlign: 'top'
  },  
  labels: { 
    height: '1.4em',
    display: 'flex',
    alignContent: 'space-between',
    alignItems: 'flex-end'
  },
  labelsH6: {
    verticalAlign: 'bottom',
    fontSize: '9px'
  },
  labelsH6Last: {
    textAlign: 'right'
  },
  nutrientName: {
    whiteSpace: 'nowrap',
    fontSize: '10px'
  },
  nutritionFacts: {
    paddingBottom: '.5em'
  },
  row: {
    height: null
  },
  servingSize: {
    marginTop: '10%',
    marginRight: 0,
    marginBottom: '10%',
    marginLeft: 0
  },
  significantSourceText: {
    display: 'block'
  }
}

export default function NfpTabularView({nfp = {}, simplified, serve, abbreviated, nutrientList, significantSourceText}) {
  let i = 1
  const showSaturatedFat = parseFloat(nfp.saturatedFatPercent) >= 2
  const showTransFat = parseFloat(nfp.transFatPercent) >= 2
  const showCholesterol = parseFloat(nfp.cholesterolPercent) >= 2
  const showDietaryFiber = parseFloat(nfp.dietaryFiberPercent) >= 2
  const showTotalSugars = parseFloat(nfp.totalSugarsPercent) >= 2
  const showAddedSugars = parseFloat(nfp.addedSugarsPercent) >= 2
  const showVitaminD = parseFloat(nfp.vitaminDMcgPercent) >= 2
  const showCalcium = parseFloat(nfp.calciumPercent) >= 2
  const showIron = parseFloat(nfp.ironPercent) >= 2
  const showPotassium = parseFloat(nfp.potassiumPercent) >= 2
  return (
    <Box id="nutrition-fact-panel-tabular" sx={{ ...css.nutritionFactPanel, ...css2.nutritionFactPanelTabular}}>
      <Box sx={{...css.row, ...css2.row}}>
        <Box sx={Object.assign({}, css2.colXs5, css.p0, css.m0, css.column, css2.column1)}>
          <h2 style={Object.assign({}, css.h2, css2.nutritionFacts)}>Nutrition Facts</h2>
          <hr style={Object.assign({}, css.hr, css.hr1, css.mt0)}/>
          <Box sx={Object.assign({}, css.row, css2.row, css2.servingSize)}>
            {serve === 'multi' &&
              <span>{nfp.servingsPerContainer} {'' + nfp.servingsPerContainer === '1' ? 'serving' : 'servings'} per container</span>
            }
            <Box sx={Object.assign({}, css.colXs12, css.p0, css.m0)}><h4 style={css.h4}>{abbreviated ? 'Serv.' : 'Serving'} Size</h4></Box>
            <Box sx={Object.assign({}, css.colXs12, css.p0, css.m0)}><h4 style={css.h4}>{nfp.servingSize}</h4></Box>
            <Box sx={css.clear}></Box>
          </Box>
          <hr style={Object.assign({}, css.hr, css.hr1, css.mt0)}/>
          <Box sx={{...css.row, ...css2.row}}>
            <Box sx={css.calories}>
              <h4 style={Object.assign({}, css.h4, css2.caloriesH4)}>Calories</h4>
              <h5 style={Object.assign({}, css2.h5, css.bold)}>Per Serving</h5>
              <h3 style={Object.assign({}, css.h3, css2.caloriesH3)}>{nfp.calories}</h3>
            </Box>
            <Box sx={css.clear}></Box>
          </Box>
        </Box>
        <Box sx={Object.assign({}, css2.colXs7, css.p0, css.m0, css.column, css2.column2)}>
          <Box sx={Object.assign({}, css.row, css2.row, css2.labels)}>
            <h6 style={Object.assign({}, css.h6, css2.labelsH6)}>Amount/Serving</h6>
            <h6 style={Object.assign({}, css.h6, css2.labelsH6, css2.labelsH6Last)}>% Daily Value</h6>
            <Box sx={css.clear}></Box>
          </Box>
          <hr style={Object.assign({}, css.hr, css.hr1)}/>
          <h5 style={Object.assign({}, css2.h5, css.bold)}>Total Fat</h5><span style={Object.assign({}, css.av, css2.av)}>{nfp.totalFat ? `${nfp.totalFat}g` : ''}</span><span style={Object.assign({}, css.dv, css2.dv)}>{nfp.totalFatPercent ? `${nfp.totalFatPercent}%` : ''}</span>
          <hr style={Object.assign({}, css.hr, css.hr1)}/>
          {!simplified && 
          <Box>
            <Box sx={css.indent1}>
            {showSaturatedFat && <Box>
                <h5 style={Object.assign({}, css2.h5, css.indent1Child)}>Saturated Fat</h5><span style={Object.assign({}, css.av, css2.av)}>{nfp.saturatedFat ? `${nfp.saturatedFat}g` : ''}</span><span style={Object.assign({}, css.dv, css2.dv)}>{nfp.saturatedFatPercent ? `${nfp.saturatedFatPercent}%` : ''}</span>
                <hr style={Object.assign({}, css.hr, css.hr1)}/>
              </Box>}
              {showTransFat && <Box>
                <h5 style={Object.assign({}, css2.h5, css.indent1Child)}><i>Trans</i> Fat</h5><span style={Object.assign({}, css.av, css2.av)}>{nfp.transFat ? `${nfp.transFat}g` : ''}</span><span style={Object.assign({}, css.dv, css2.dv)}>{nfp.transFatPercent ? `${nfp.transFatPercent}%` : ''}</span>
                <hr style={Object.assign({}, css.hr, css.hr1)}/>
              </Box>}
            </Box>
            
            {showCholesterol && 
            <>
              <h5 style={Object.assign({}, css2.h5, css.bold)}>Cholesterol</h5>
              <span style={Object.assign({}, css.av, css2.av)}>{nfp.cholesterol ? `${nfp.cholesterol}g` : ''}</span>
              <span style={Object.assign({}, css.dv, css2.dv)}>{nfp.cholesterolPercent ? `${nfp.cholesterolPercent}%` : ''}</span>
              <hr style={Object.assign({}, css.hr, css.hr1)}/>
            </>}
          </Box>}
          <h5 style={Object.assign({}, css2.h5, css.bold)}>Sodium</h5><span style={Object.assign({}, css.av, css2.av)}>{nfp.sodium ? `${nfp.sodium}mg` : ''}</span><span style={Object.assign({}, css.dv, css2.dv)}>{nfp.sodiumPercent ? `${nfp.sodiumPercent}%` : ''}</span>
          <hr style={Object.assign({}, css.hr, css.hr1)}/>
          <h5 style={Object.assign({}, css2.h5, css.bold)}>Total {abbreviated ? 'Carb.' : 'Carbohydrate'}</h5><span style={Object.assign({}, css.av, css2.av)}>{nfp.totalCarb ? `${nfp.totalCarb}g` : ''}</span><span style={Object.assign({}, css.dv, css2.dv)}>{nfp.totalCarbPercent ? `${nfp.totalCarbPercent}%` : ''}</span>
          <hr style={Object.assign({}, css.hr, css.hr1)}/>
          <Box sx={css.indent1}>
            {showDietaryFiber && <Box>
              <h5 style={Object.assign({}, css2.h5, css.indent1Child)}>Dietary Fiber</h5><span style={Object.assign({}, css.av, css2.av)}>{nfp.dietaryFiber ? `${nfp.dietaryFiber}g` : ''}</span><span style={Object.assign({}, css.dv, css2.dv)}>{nfp.dietaryFiberPercent ? `${nfp.dietaryFiberPercent}%` : ''}</span>
            </Box>}
            {showDietaryFiber && showTotalSugars && <hr style={Object.assign({}, css.hr, css.hr1)}/>}
            {showTotalSugars && <Box><h5 style={Object.assign({}, css2.h5, css.indent1Child)}>Total Sugars</h5><span style={Object.assign({}, css.av, css2.av)}>{nfp.totalSugars ? `${nfp.totalSugars}g` : ''}</span></Box> }
            {((showDietaryFiber || showTotalSugars) && showAddedSugars) && <hr style={Object.assign({}, css.hr, css.hr1)}/> }
            {showAddedSugars && <Box><h5 style={Object.assign({}, css2.h5, css.indent1Child)}>Added Sugars</h5><span style={Object.assign({}, css.av, css2.av)}>{nfp.addedSugars ? `${nfp.addedSugars}g` : ''}</span><span style={Object.assign({}, css.dv, css2.dv)}>{nfp.addedSugarsPercent ? `${nfp.addedSugarsPercent}%` : ''}</span></Box>}
          </Box>
          {(showDietaryFiber || showTotalSugars || showAddedSugars) && <hr style={Object.assign({}, css.hr, css.hr1)}/> }
          <h5 style={Object.assign({}, css2.h5, css.bold)}>Protein</h5><span style={Object.assign({}, css.av, css2.av)}>{nfp.protein ? `${nfp.protein}g` : ''}</span>
          <hr style={Object.assign({}, css.hr, css.hr6)}/>
          {nutrientList && nutrientList.length > 0 && <>
              <Grid container>
                {nutrientList.map((nutrient, idx, arr) => {
                  if(nutrient.outputName && ((nutrient.amount && nutrient.uom) || nutrient.percent)) {
                    const item = (
                      <React.Fragment key={`nutrient${i}`}>
                        <Grid item size={6} sx={{ textAlign: i % 2 === 0 ? 'right' : 'left', position: 'relative' }}>
                          { i % 2 === 0 && <Box sx={{position:'absolute', left:0}}>•</Box> }
                          <Box component="span" sx={css2.nutrientName}>{nutrient.outputName}</Box>
                          <Box component="span" sx={{ ...css.av, ...css2.av, ...css2.columnNutrientAv }}>{nutrient.amount ? nutrient.amount + nutrient.uom : ' '}</Box>
                          <Box component="span" sx={{ ...css.dv, ...css2.dv, ...css2.columnNutrientDv }}>{nutrient.percent ? nutrient.percent + '%' : ' '}</Box>
                        </Grid>
                        { i % 2 === 0 && (idx < arr.length - 1) ? <Grid item size={12}><Divider sx={{ ...css.hr, ...css.hr1}} /></Grid> : ''}
                      </React.Fragment>
                    )
                    i = i + 1
                    return item
                  } else {
                    return
                  }
                })} 
              </Grid>                                                          
              <Divider sx={{ ...css.hr, ...css.hr4 }} />
            </>
          } 
        
          {/*<span style={css.fs6}>*The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a daily diet. 2,000 calories a day is used for general nutrition advice.</span>*/}
          {simplified && significantSourceText && 
            <Box component="span" sx={Object.assign({}, css.fs6, css2.significantSourceText)}>{significantSourceText}</Box>
          }
        </Box>
        <Box sx={css.clear}></Box>
      </Box>  
    </Box> 
  )
}
