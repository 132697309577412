import React from "react"
import css from '../NfpStyles'
import { Box, Divider, Grid2 as Grid, Typography } from '@mui/material'
import { isEmpty } from '../../../utils/utils'

let css2 = {
  nutritionFactPanelVertical: {
    maxWidth: '342px'
  },
  av: {
    marginLeft: '1em',
    minHeight: '13px',
    verticalAlign: 'top'
  },
  bold: {
    fontWeight: 900
  },
  calories: {
    height: '3em'
  },
  caloriesH2: {
    position: 'absolute',
    bottom: 0,
    textAlign: 'right'
  },
  caloriesH3: {
    position: 'absolute', 
    bottom: 0,
    textAlign: 'left'
  },
  colXs6: {
    position: 'relative',
    minHeight: '1px',
    paddingRight: '15px',
    paddingLeft: '15px',
    float: 'left',
    width: '48%'
  },
  column1: {
    flexBasis: '100%',
    paddingLeft: 0
  },
  columnNutrientAv: {
    marginLeft: '0.5em',
    fontSize: '11px'
  },
  columnNutrientDv: {
    marginLeft: '0.5em',
    fontSize: '11px',
    float: 'none'
  },
  indent1: {
    padding: '.5em 0 0 10px'
  },
  right: {
    textAlign: 'right'
  },
  dv: {
    float: 'right',
    minHeight: '13px',
    verticalAlign: 'bottom'
  },
  h1: {
    fontSize: '14pt',
    lineHeight: 1.1,
    verticalAlign: 'bottom',
  },
  h2: {
    fontSize: '13pt',
    lineHeight: 1.1,
    verticalAlign: 'bottom',
  },
  h3: {
    fontSize: '12pt',
    lineHeight: 1.1,
    verticalAlign: 'bottom',
  },
  h4: {
    fontSize: '11pt',
    lineHeight: .9,
    marginTop: '.15em',
    verticalAlign: 'bottom',
  },
  h5: {
    fontSize: '10pt',
    lineHeight: .9,
    verticalAlign: 'bottom',
  },
  h6: {
    fontSize: '7.5pt',
    verticalAlign: 'bottom',
  },
  h7: {
    fontSize: '7pt',
    lineHeight: .5,
    verticalAlign: 'bottom',
  },

  m003: {
    marginTop: '0px',
    marginRight: '0px',
    marginBottom: '3px',
    marginLeft: '0px'
  },  
  nutrientName: {
    whiteSpace: 'nowrap',
    fontSize: '11px'
  },
  row: {
    marginTop: '2px'
  },
  servingSize: {
    lineHeight: 1.3
  },
  significantSourceText: {
    marginTop: '4px',
    display: 'block'
  }
}

export default function NfpVerticalView (props) {

  const nfp = props.nfp || {}
  let i = 1
  const showDietaryFiber = !props.simplified && (!isEmpty(nfp.dietaryFiber) || !isEmpty(nfp.dietaryFiberPercent))
  const showTotalSugars = !props.simplified && !isEmpty(nfp.totalSugars)
  const showAddedSugars = !props.simplified && (!isEmpty(nfp.addedSugars) || !isEmpty(nfp.addedSugarsPercent))
  const showSaturatedFat = !isEmpty(nfp.saturatedFat) || !isEmpty(nfp.saturatedFatPercent)
  const showTransFat = !isEmpty(nfp.transFat) || !isEmpty(nfp.transFatPercent)
  return (
    <Box id="nutrition-fact-panel-vertical" sx={{ ...css.nutritionFactPanel, ...css2.nutritionFactPanelVertical}}>
      <Typography variant="h1" sx={{...css.h1, ...css2.h1}}>Nutrition Facts</Typography>
      <Typography variant="h1" sx={{...css.h1, ...css2.h1, marginBottom: '.25em'}}>Valeur nutritive</Typography>
      <Typography variant="h4" sx={{...css.h4, ...css2.h4, fontWeight: 'normal', display: 'inline-block', width: 'auto'}}>Per HM (MM)</Typography>
      <Typography variant="h3" sx={{...css.h5, ...css2.h5, fontWeight: 'normal', display: 'inline-block', width: 'auto', marginLeft: '1.5em'}}>pour MD (MM)</Typography>
      
      <Divider sx={{...css.hr, ...css.hr2}} />
      {/* {props.serve === 'multi' &&
        <Box component="span">{nfp.servingsPerContainer} {'' + nfp.servingsPerContainer === '1' ? 'serving' : 'servings'} per container</Box>
      } */}
      {/* <Box sx={{ ...css.p0, ...css.m0}}>
      <Grid container>
        <Grid item size={6}>
          <Typography variant="h4" sx={{ ...css.h4, ...css2.servingSize}}>{props.abbreviated ? 'Serv.' : 'Serving'} Size</Typography>
        </Grid>
        <Grid item size={6}>
          <Typography variant="h4" sx={{ ...css.h4, ...css.dv }}>{nfp.servingSize}</Typography>
        </Grid>
      </Grid>
      </Box> */}
      <Grid container>
        <Grid item size={6}>
          <Typography variant="h3" sx={{ ...css.h3 }}>Calories {nfp.calories}</Typography>
          <Divider sx={{ ...css.hr, ...css.hr5 }}/>  
        </Grid>
        <Grid item size={6} sx={css2.right}>
          <Typography variant="h7" sx={{ ...css.h7, ...css2.h7 }}>% Daily Value*</Typography><br/>
          <Typography variant="h7" sx={{ ...css.h7, ...css2.h7 }}>% valeur quotidienne*</Typography>
        </Grid>
        <Grid item size={10}>
          <Typography variant="h4" sx={{ ...css.h4, ...css2.h4 }}>Fat / Lipides {nfp.totalFat ? `${nfp.totalFat} g` : ''}</Typography>
        </Grid>
        <Grid item size={2}>
          <Typography variant="h6" sx={{ ...css.h6, ...css2.h6, ...css2.right, margin: 0, padding: 0 }}>{nfp.totalFatPercent ? `${nfp.totalFatPercent} %` : ''}</Typography>
        </Grid>
        <Grid item size={12}>
          <Box sx={css2.indent1 }>
            {showSaturatedFat && <>
              <Typography variant="h5" sx={{ ...css2.h5, ...css.indent1Child, display:'inline' }}>Saturated / saturés</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.saturatedFat ? `${nfp.saturatedFat} g` : ''}</Box><Box component="span" sx={{ ...css.dv, ...css2.dv }}>{nfp.saturatedFatPercent ? `${nfp.saturatedFatPercent} %` : ''}</Box>
            </>}
            {showTransFat && <>
              <Typography variant="h5" sx={{ ...css2.h5, ...css.indent1Child, display:'inline' }}>+ Trans / trans</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.transFat ? `${nfp.transFat} g` : ''}</Box><Box component="span" sx={{ ...css.dv, ...css2.dv}}>{nfp.transFatPercent ? `${nfp.transFatPercent} %` : ''}</Box>
            </>}
          </Box>
          <Divider sx={{ ...css.hr, ...css.hr1 }}/>
        </Grid>
        <Grid item size={12}>
          <Typography variant="h4" sx={{ ...css.h4, ...css2.h4 }}>Carbohydrate / Glucides {nfp.totalCarb ? `${nfp.totalCarb} g` : ''}</Typography>
        </Grid>
        {/* <Grid item size={2}>
          <Typography variant="h6" sx={{ ...css.h6, ...css2.h6, ...css2.right, margin: 0, padding: 0 }}>{nfp.totalCarbPercent ? `${nfp.totalCarbPercent} %` : ''}</Typography>
        </Grid> */}
        <Grid item size={12}>
          <Box sx={css2.indent1}>
            {showSaturatedFat && <>
              <Typography variant="h5" sx={{ ...css2.h5, ...css.indent1Child, display:'inline' }}>Fibre / Fibres</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.dietaryFiber ? `${nfp.dietaryFiber} g` : ''}</Box><Box component="span" sx={{...css.dv, ...css2.dv}}>{nfp.dietaryFiberPercent ? `${nfp.dietaryFiberPercent} %` : ''}</Box>
            </>}
            {showTransFat && <>
              <Typography variant="h5" sx={{ ...css2.h5, ...css.indent1Child, display:'inline' }}>Sugars / Sucres</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.totalSugars ? `${nfp.totalSugars} g` : ''}</Box><Box component="span" sx={{ ...css.dv, ...css2.dv}}>{nfp.totalSugarsPercent ? `${nfp.totalSugarsPercent} %` : ''}</Box>
            </>}
          </Box>
          <Divider sx={{ ...css.hr, ...css.hr1 }}/>
        </Grid>
        <Grid item size={10}>
          <Typography variant="h4" sx={{ ...css.h4, ...css2.h4 }}>Protein / Protéines {nfp.protein ? `${nfp.protein} g` : ''}</Typography>
        </Grid>
        <Divider sx={{ ...css.hr, ...css.hr1 }}/>
        <Grid item size={10}>
          <Typography variant="h4" sx={{ ...css.h4, ...css2.h4 }}>Cholesterol / Cholestérol {nfp.cholesterol ? `${nfp.cholesterol} mg` : ''}</Typography>
        </Grid>
        <Divider sx={{ ...css.hr, ...css.hr1 }}/>
        <Grid item size={12}>
          <Typography variant="h4" sx={{ ...css.h4, ...css2.h4, display: 'inline' }}>Sodium</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.sodium ? `${nfp.sodium} mg` : ''}</Box><Box component="span" sx={{...css.dv, ...css2.dv}}>{nfp.sodiumPercent ? `${nfp.sodiumPercent} %` : ''}</Box>
        </Grid>
        <Divider sx={{ ...css.hr, ...css.hr4 }}/>
        <Grid item size={12} sx={ css2.row }>
          <Typography variant="h4" sx={{ ...css.h4, ...css2.h4, fontWeight: 'normal', display: 'inline' }}>Potassium</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.potassium ? `${nfp.potassium} mg` : ''}</Box><Box component="span" sx={{...css.dv, ...css2.dv}}>{nfp.potassiumPercent ? `${nfp.potassiumPercent} %` : ''}</Box>
        </Grid>
        <Divider sx={{ ...css.hr, ...css.hr1 }}/>
        <Grid item size={12} sx={ css2.row }>
          <Typography variant="h4" sx={{ ...css.h4, ...css2.h4, fontWeight: 'normal', display: 'inline' }}>Calcium</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.calcium ? `${nfp.calcium} mg` : ''}</Box><Box component="span" sx={{...css.dv, ...css2.dv}}>{nfp.calciumPercent ? `${nfp.calciumPercent} %` : ''}</Box>
        </Grid>
        <Divider sx={{ ...css.hr, ...css.hr1 }}/>
        <Grid item size={12} sx={ css2.row }>
          <Typography variant="h4" sx={{ ...css.h4, ...css2.h4, fontWeight: 'normal', display: 'inline' }}>Iron / Fer</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.iron ? `${nfp.iron} mg` : ''}</Box><Box component="span" sx={{...css.dv, ...css2.dv}}>{nfp.ironPercent ? `${nfp.ironPercent} %` : ''}</Box>
        </Grid>
        
        {props.nutrientList && props.nutrientList.length > 0 && <>
              {props.nutrientList.map((nutrient, idx, arr) => {
                if(nutrient.outputName && ((nutrient.amount && nutrient.uom) || nutrient.percent)) {
                  const item = (
                    <>
                      <Divider sx={{ ...css.hr, ...css.hr1 }}/>
                      <Grid item size={12} sx={ css2.row }>
                        <Typography variant="h4" sx={{ ...css.h4, ...css2.h4, fontWeight: 'normal', display: 'inline' }}>{nutrient.outputName}</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nutrient.amount ? `${nutrient.amount} ${nutrient.uom}` : ' '}</Box><Box component="span" sx={{...css.dv, ...css2.dv}}>{nutrient.percent ? `${nutrient.percent} %` : ' '}</Box>
                      </Grid>
                    </>
                  )
                  return item
                } else {
                  return
                }
              })}               
            </>
          }  
          <Divider sx={{ ...css.hr, ...css.hr4 }}/>
          {/*
            </Box>
            <Typography variant="h5" sx={{ ...css2.h5, ...css.bold}}>Cholesterol</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.cholesterol ? `${nfp.cholesterol}g` : ''}</Box><Box component="span" sx={Object.assign({}, css.dv, css2.dv)}>{nfp.cholesterolPercent ? `${nfp.cholesterolPercent}%` : ''}</Box>
            <Divider sx={{ ...css.hr, ...css.hr1}} />
          </>}
          <Typography variant="h5" sx={{ ...css2.h5, ...css.bold}}>Sodium</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.sodium ? `${nfp.sodium}mg` : ''}</Box><Box component="span" sx={Object.assign({}, css.dv, css2.dv)}>{nfp.sodiumPercent ? `${nfp.sodiumPercent}%` : ''}</Box>
          <Divider sx={{ ...css.hr, ...css.hr1}} />
          <Typography variant="h5" sx={{ ...css2.h5, ...css.bold}}>Total {props.abbreviated ? 'Carb.' : 'Carbohydrate'}</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.totalCarb ? `${nfp.totalCarb}g` : ''}</Box><Box component="span" sx={Object.assign({}, css.dv, css2.dv)}>{nfp.totalCarbPercent ? `${nfp.totalCarbPercent}%` : ''}</Box>
          <Divider sx={{ ...css.hr, ...css.hr1}} />
          <Box sx={css.indent1}>
            {showDietaryFiber && <>
              <Typography variant="h5" sx={{ ...css2.h5, ...css.indent1Child }}>Dietary Fiber</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.dietaryFiber ? `${nfp.dietaryFiber}g` : ''}</Box><Box component="span" sx={Object.assign({}, css.dv, css2.dv)}>{nfp.dietaryFiberPercent ? `${nfp.dietaryFiberPercent}%` : ''}</Box>
            </>}
            {showDietaryFiber && showTotalSugars && <Divider sx={{ ...css.hr, ...css.hr1}} />}
            {showTotalSugars && <><Typography variant="h5" sx={{ ...css2.h5, ...css.indent1Child}}>Total Sugars</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.totalSugars ? `${nfp.totalSugars}g` : ''}</Box></> }
            {((showDietaryFiber || showTotalSugars) && showAddedSugars) && <Divider sx={{ ...css.hr, ...css.hr1}} /> }
            {showAddedSugars && <><Typography variant="h5" sx={{ ...css2.h5, ...css.indent1Child }}>Added Sugars</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.addedSugars ? `${nfp.addedSugars}g` : ''}</Box><Box component="span" sx={Object.assign({}, css.dv, css2.dv)}>{nfp.addedSugarsPercent ? `${nfp.addedSugarsPercent}%` : ''}</Box></>}
          </Box>
          {(showDietaryFiber || showTotalSugars || showAddedSugars) && <Divider sx={{ ...css.hr, ...css.hr1}} /> }
          <Typography variant="h5" sx={{ ...css2.h5, ...css.bold}}>Protein</Typography><Box component="span" sx={{ ...css.av, ...css2.av }}>{nfp.protein ? `${nfp.protein}g` : ''}</Box>
          <Divider sx={{ ...css.hr, ...css.hr6 }} />
          {props.nutrientList && props.nutrientList.length > 0 && <>
              <Grid container>
                {props.nutrientList.map((nutrient, idx, arr) => {
                  if(nutrient.outputName && ((nutrient.amount && nutrient.uom) || nutrient.percent)) {
                    const item = (
                      <React.Fragment key={`nutrient${i}`}>
                        <Grid item size={6} sx={{ textAlign: i % 2 === 0 ? 'right' : 'left', position: 'relative' }}>
                          { i % 2 === 0 && <Box sx={{position:'absolute', left:0}}>•</Box> }
                          <Box component="span" sx={css2.nutrientName}>{nutrient.outputName}</Box>
                          <Box component="span" sx={{ ...css.av, ...css2.av, ...css2.columnNutrientAv }}>{nutrient.amount ? nutrient.amount + nutrient.uom : ' '}</Box>
                          <Box component="span" sx={{ ...css.dv, ...css2.dv, ...css2.columnNutrientDv }}>{nutrient.percent ? nutrient.percent + '%' : ' '}</Box>
                        </Grid>
                        { i % 2 === 0 && (idx < arr.length - 1) ? <Grid item size={12}><Divider sx={{ ...css.hr, ...css.hr1}} /></Grid> : ''}
                      </React.Fragment>
                    )
                    i = i + 1
                    return item
                  } else {
                    return
                  }
                })} 
              </Grid>                                                          
              <Divider sx={{ ...css.hr, ...css.hr4 }} />
            </>
          }          
        
      
      {props.significantSourceText && 
        <Box component="span" sx={Object.assign({}, css.fs6, css2.significantSourceText)}>{props.significantSourceText}</Box>
      } */}
        <Grid item size={12}>
          <Box component="span">* 5% or less is <Box component="span" sx={{fontWeight: 'bold'}}>a little</Box>, 15% or more is <Box component="span" sx={{fontWeight: 'bold'}}>a lot</Box></Box><br/>
          <Box component="span">* 5% ou moins c'est <Box component="span" sx={{fontWeight: 'bold'}}>peu</Box>, 15% ou plus c'est <Box component="span" sx={{fontWeight: 'bold'}}>beaucoup</Box></Box>  
        </Grid>
      </Grid>
    </Box>
  )
}

